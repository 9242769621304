import React from "react"
import SchedulePage from ".."
import ScheduleHeader from "../Header"
import OurService from "../OurService"
import { ScheduleLocation } from "../Locations"
import Search from "../../HomePage/Search"
import "./Reserve.scss"

import { ReserveMeta, DENReserve, MSPReserve, ReserveService, ReserveFAQ } from "./metadata"
import ModuleTitle from "../../../atoms/SchedulePages/ModuleTitle"
import Seo from "../../../molecules/Seo"

const ReservePage = ({ history }) => {
  const reserveDenver = () => {
    window.open("https://www.flydenver.com/security/den-reserve/", "_blank", "noreferrer")
  }

  const reserveMSP = () => {
    window.open("https://www.mspairport.com/airport/msp-reserve", "_blank", "noreferrer")
  }

  return (
    <SchedulePage>
      <Seo title={ReserveMeta.seo.title} description={ReserveMeta.seo.description} />
      {/* <div className="schedule-search-form">
        <Search history={history} remoteCallSearchComponent={true} />
      </div> */}
      <div className="schedule-page">
        <ScheduleHeader metadata={ReserveMeta.header} />

        <ModuleTitle title={"DEN Reserve"} />
        <ScheduleLocation data={DENReserve} onButtonClick={reserveDenver} />

        <ModuleTitle title={"MSP Reserve"} />
        <ScheduleLocation data={MSPReserve} onButtonClick={reserveMSP} />

        <ModuleTitle title={"FAQ's"} />
        <FAQ data={ReserveFAQ} />
        <OurService service={ReserveService} />
      </div>
    </SchedulePage>
  )
}

const FAQ = ({ data }) => {
  return <div className="schedule-reserve-faq">{data}</div>
}

export default ReservePage
