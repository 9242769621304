import * as React from "react"
import { useState } from "react"
import * as styles from "./ACHeader.module.scss"

const AirCanadaHeader = () => {
  const [showPhoneNumber, setShowPhonenumber] = useState(false)

  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <div className={styles.logoWrapper}>
          <img src="https://content.landline.com/wp-content/uploads/2025/01/aircanada-logo.png" className={styles.AirCanadaLogo}/>
          <img src="https://content.landline.com/wp-content/uploads/2024/10/landline-logo-blk-small.jpg" className={styles.AirCanadaLogo}/>
        </div>
      </div>
    </div>
  )
}

export default AirCanadaHeader
