import "./TopMenuLinks.scss"

import classNames from "classnames"
// import PropTypes from "prop-types"
import React from "react"

import {
  REFERRAL_LANDING,
  HOW_IT_WORKS,
  PENGUIN_PERKS_PAGE,
  SCHEDULE_AND_FARES,
  CHARTER_LANDLINE,
  TSA_RESERVE
} from "../../../constants/routes"
import config from "../../../utils/config"
import NavLink from "../../atoms/NavLink"

const TopMenuLinks = () => {
  const topMenuLinksClass = classNames("top-menu-links-wrapper")
  return (
    <div className={topMenuLinksClass}>
      <div className="Navbar--links">
        <NavLink to={`${config.LANDLINE_URL}${HOW_IT_WORKS}`}>How It Works</NavLink>
        <NavLink to={`${config.LANDLINE_URL}${SCHEDULE_AND_FARES}`}>Schedule & Fares</NavLink>
		    <NavLink to={`${config.LANDLINE_URL}${CHARTER_LANDLINE}`}>Charters</NavLink>		
        <NavLink to={`${config.LANDLINE_URL}${PENGUIN_PERKS_PAGE}`}>Penguin Perks</NavLink>
        <NavLink to={`${config.LANDLINE_URL}${REFERRAL_LANDING}`}>Refer A Friend</NavLink>
        <NavLink to={`${config.LANDLINE_URL}${TSA_RESERVE}`}>TSA Reserve</NavLink>
      </div>
    </div>
  )
}

TopMenuLinks.propTypes = {}

TopMenuLinks.defaultProps = {}

export default TopMenuLinks
