import React, { useEffect } from "react"
import "./CorporateTravelQuotePage.scss"
import BasicPage from "../BasicPage"
import ScheduleHeader from "../SchedulePages/Header"
import ModuleTitle from "../../atoms/SchedulePages/ModuleTitle"

const metaDataCorporateQuote = {
  header: {
    title: "Relax and Let Landline Drive",
    description:
      "Fill out the information below to request a corporate travel quote from one of our travel managers.",
  },
}

const CorporateTravelQuotePage = () => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      ;(function(document) {
        const script = document.createElement("script")

        script.src = "https://landlineco56170.activehosted.com/f/embed.php?id=9"
        script.async = true
        script.onload = () => {
          console.log("Script loaded and ready")
        }

        document.body.appendChild(script)

        return () => {
          document.body.removeChild(script)
        }
      })(document)
    }
  }, [])

  return (
    <BasicPage>
      <div className="corporate-travel-quote-container">
        <ScheduleHeader metadata={metaDataCorporateQuote.header} />
        <ModuleTitle title="Tell Us About Your Company" />
        <div className="schedule-page">
          <div id="_form_9_" className="_form_9"></div>
        </div>
      </div>
    </BasicPage>
  )
}

export default CorporateTravelQuotePage
