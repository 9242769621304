import * as React from "react"
import * as styles from "./HowItWorksIcon.module.scss"

const HowItWorksIcon = ({ icon, children, iconClassName }) => {
  return (
    <div className={styles.howItWorksIcon}>
      <div className={styles.iconWrapper}>
        <img src={icon} alt="icon" className={styles[iconClassName]} />
      </div>
      <div className={styles.iconDescription}>{children}</div>
    </div>
  )
}

export default HowItWorksIcon
