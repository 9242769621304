import * as React from "react"
import Layout from "./Layout"
import BusLarge from "../../../media/BusLarge.png"
import MotorcoachTable from "./MotorcoachTable"
import { landlineExperienceContent } from "./Motorcoach-data"
import * as styles from "./Motorcoach.module.scss"

const motorcoachRoutes = [
  { from: "Duluth", to: "MSP Airport", href: "/duluth" },
  { from: "Fargo", to: "MSP Airport", href: "/fargo" },
  { from: "Fort Collins/Loveland", to: "DEN (DIA) Airport", href: "/fort-collins" },
]

const MotorcoachPage = () => {
  return (
    <>
      <Layout>
        <div className={styles.motorcoachPage}>
          <div className={styles.motorcoachPage__content}>
            <div className={styles.inner}>
              <div className={styles.hero}>
                <h1 className={styles.motorcoachPage__pageTitle}>Landline</h1>
                <img src={BusLarge} alt="bus" className={styles.motorcoachPage__heroImage} />
              </div>
            </div>

            <div className={styles.motorcoachPage__content__texts}>
              <p
                className={styles.motorcoachPage__text}
              >{`Landline’s motorcoach service provides easy, non-stop travel between`}</p>
              <ul
                className={`${styles.motorcoachPage__text} ${styles.motorcoachPage_airports_list}`}
              >
                {motorcoachRoutes.map((route, index) => (
                  <li key={index}>
                    <a href={route.href}>
                      {route.from} and {route.to}
                    </a>
                  </li>
                ))}
              </ul>
              <p
                className={styles.motorcoachPage__text}
              >{`Say goodbye to driving yourself, airport parking and stressful departures. Let us drive and make your next trip hassle-free and enjoyable!`}</p>
              <p
                className={styles.motorcoachPage__text}
              >{`Our vehicles are uniquely designed to make your commute to and from the airport relaxing. During your trip, enjoy our onboard amenities like complimentary entertainment, fast Wi-Fi and more.`}</p>
              <p className={styles.motorcoachPage__text}>
                Learn more about our daily
                <a href="/pickup-locations"> Schedule.</a>
              </p>
            </div>

            <MotorcoachTable />
          </div>
        </div>
        <div className={styles.motorcoachPage__content__yourLandLineExperience}>
          <div className={styles.yourLandLineExperience__content}>
            <h1 className={styles.yourLandLineExperience__content__sectionTitle}>
              Your Landline Experience
            </h1>
            {landlineExperienceContent.map(item => {
              return (
                <div key={item.id} className={styles.yourLandLineExperience__content__row}>
                  <img src={item.image} alt="kids" />
                  <div className={styles.yourLandLineExperience__texts}>
                    <h2 className={styles.yourLandLineExperience__texts__title}>{item.title}</h2>
                    <p className={`${styles.yourLandLineExperience__text} ${styles.bold}`}>
                      {item.text1}
                    </p>
                    <p className={styles.yourLandLineExperience__text}>{item.text2}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default MotorcoachPage
