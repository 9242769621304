import React from "react"

const DenverMeta = {
    header: {
        title: "Denver Airport Shuttle",
        subtitle: "Fort Collins | Loveland",
        description: "Landline departs prompty at the scheduled time. Your driver will not come inside the terminal to announce their arrival at DEN airport.\nPlease arrive early and have your ID ready to ensure an on-time departure.",
        book: true
    },
    seo: {
        title: "Denver (DIA) Airport Shuttle to Fort Collins | Landline",
        description: "Landline’s premium airport shuttle from Denver DIA to Fort Collins/Loveland is affordable & easy. Enjoy free Wi-Fi. Book now from $32!."
    }
}

const DenverService = <>
    <p>Make your next trip more comfortable and convenient with Landline’s non-stop service to Denver International Airport (DEN).</p>
    <p>Avoid the stress and expense of driving, parking or ridesharing and instead opt for Landline’s comfortable, Wi-Fi enabled vehicles.</p>
    <p>Enjoy modern conveniences like air conditioning, in seat power, reclining leather seats, and get caught up on work or stream your favorite shows while you ride.</p>
    <p>Our friendly staff will help you with loading and unloading your luggage on both ends of your trip ensuring a smooth and efficient trip.</p>
    <p>Landline is ideal for families, college students, leisure and business travelers alike looking for a safe, reliable and an affordable way to/ from DIA.</p>
</>

const DenverLocationService = {
    title: "Landline's Denver Service",
    subtitle: "Pick Up / Drop Off",
    image: "https://content.landline.com/wp-content/uploads/2024/05/denver_pickup.jpg",
    content: <>
        <p>The Landline pick-up/drop off is located on Level 5 of Terminal West at DEN. Follow the signs for United baggage claim. Landline picks up outside door 504 immediately curbside (not on an island).</p>
        <p>For passengers who booked Landline service through <a href="http://www.united.com/en-ues/landline" target="_blank" rel="noopener noreferrer">United Airlines</a>, we pick up at Gate B83. Do not proceed to baggage claim, your luggage will be transferred on your behalf and can be collected upon arrival at the Northern Colorado Regional Airport.</p>
    </>
}

export {
    DenverLocationService,
    DenverMeta,
    DenverService
}