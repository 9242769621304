import React from "react"

const LocationService = ({ service }) => {
  return (
    <div className="schedule-page-location-service">
      <img src={service.image} alt="" />
      <div className="schedule-page-location-service-panel">
        <div>
          <h4>{service.subtitle}</h4>
          {service.content}
        </div>
      </div>
    </div>
  )
}

export default LocationService
