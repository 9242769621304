const ReferralLandingMeta = {
    seo: {
        title: "Refer a Friend & Earn Rewards | Landline",
        description: "Share your favorite airport shuttle experience with your loved ones! Refer a friend get $10 off when they take their first trip on Landline. Start referring today!"
    }
}

const ReferralHowItWorks = {
    headline: "How It Works",
    parties: [
        {
            title: "Referrer/ Existing User",
            steps: [
                "Sign into your Landline Penguin Perks Account ",
                "Go to your profile page (top right corner)",
                "Click “refer a friend” to share your unique referral code",
                "Your Landline account will be credited with $10 automatically once your friend completes their first ride."
            ]
        },
        {
            title: "Referee/ New User",
            steps: [
                "You will receive a unique link from your friend",
                "Using that link sign up for a FREE Penguin Perks account",
                "Once you sign up, you will automatically be credited $10 to your account",
                "Book your first ride and sit back and relax on Landline "
            ]
        }
    ]
}

export {
    ReferralLandingMeta,
    ReferralHowItWorks
}