const FortCollinsData = {
  to: {
    title: "From Fort Collins",
    stops: [
      {
        type: "Depart",
        name: "CSU",
      },
      {
        type: "Depart",
        name: "HTC",
      },
      {
        type: "Depart",
        name: "FNL",
      },
      {
        type: "Arrive",
        name: "DEN",
      },
    ],
    itinerary: [
      ["2:30 AM", "2:50 AM", "3:15 AM", "4:15 AM"],
      ["4:30 AM", "4:50 AM", "5:15 AM", "6:15 AM"],
      ["6:25 AM", "6:45 AM", "7:10 AM", "8:15 AM"],
      ["7:25 AM", "7:45 AM", "8:10 AM", "9:15 AM"],
      ["8:15 AM", "8:35 AM", "9:00 AM", "10:00 AM"],
      ["10:10 AM", "10:30 AM", "11:00 AM", "12:00 PM"],
      ["12:15 PM", "12:35 PM", "1:05 PM", "2:10 PM"],
      ["1:15 PM", "1:35 PM", "2:00 PM", "3:05 PM"],
      ["2:15 PM", "2:35 PM", "3:00 PM", "4:05 PM"],
      ["3:35 PM", "3:55 PM", "4:25 PM", "5:30 PM"],
      ["5:20 PM", "5:40 PM", "5:55 PM", "7:00 PM"],
    ],
  },
  from: {
    title: "To Fort Collins",
    stops: [
      {
        type: "Depart",
        name: "DEN",
      },
      {
        type: "Arrive",
        name: "FNL",
      },
      {
        type: "Arrive",
        name: "HTC",
      },
      {
        type: "Arrive",
        name: "CSU",
      },
    ],
    itinerary: [
      ["4:20 AM", "5:20 AM", "", "5:50 AM"],
      ["9:25 AM", "10:30 AM", "11:05 AM", "11:25 AM"],
      ["10:35 AM", "11:40 AM", "12:00 PM", "12:20 PM"],
      ["11:35 AM", "12:40 PM", "1:10 PM", "1:30 PM"],
      ["1:45 PM", "2:50 PM", "3:10 PM", "3:30 PM"],
      ["3:00 PM", "4:05 PM", "4:30 PM", "4:50 PM"],
      ["4:00 PM", "5:05 PM", "5:25 PM", "5:45 PM"],
      ["5:50 PM", "6:55 PM", "7:15 PM", "7:35 PM"],
      ["7:20 PM", "8:25 PM", "8:45 PM", "9:05 PM"],
      ["8:30 PM", "9:30 PM", "9:50 PM", ""],
      ["10:55 PM", "11:55 PM", "12:15 AM", "12:30 AM"],
    ],
  },
}
export default FortCollinsData
