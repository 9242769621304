import * as React from "react"
import unitedBus from "../../../media/united-bus.png"
import * as styles from "./B2BWhereToFindUs.module.scss"

const B2BWhereToFindUs = ({ image, airports, freeParking }) => {
  return (
    <div className={styles.B2BWhereToFindUs} id="B2BWhereToFindUs">
      <div>
        <img
          src={unitedBus}
          alt="united bus"
          className={styles.B2BWhereToFindUsImage}
        />
      </div>
      <div className={styles.textContent}>
        <h4 className={styles.B2BWhereToFindUsTitle}>Where to find us</h4>
        <div>
          {airports.map(airport => {
            return <p className={styles.address}>{airport}</p>
          })}
        </div>
        {freeParking && <h4 className={styles.freeParking}>Parking is Free</h4>}
      </div>
    </div>
  )
}

export default B2BWhereToFindUs
