import React, { useCallback, useState } from "react"
import ReactDOM from "react-dom"
import { useDispatch } from "react-redux"
import LoginForm from "./LoginForm"
import { PASSENGERS } from "../../../constants/routes"
import redirectActions from "../../../redux/redirect/actions"
import { IoClose } from "react-icons/io5"

import "./LoginDialog.scss"

const Modal = ({ children, onClose }) => {
  const modalRoot = document.getElementById("dialog-portal")

  const handleContentClick = e => e.stopPropagation()

  return ReactDOM.createPortal(
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal" onClick={handleContentClick}>
        <IoClose className="login-dialog-close-btn" onClick={onClose} />
        {children}
      </div>
    </div>,
    modalRoot,
  )
}

const LoginDialog = ({ history, actions }) => {
  const [showAsDialog, setShowAsDialog] = useState(false)

  const dispatch = useDispatch()
  const setRedirectURL = useCallback(url => dispatch(redirectActions.setRedirectURL(url)), [
    dispatch,
  ])

  const openModal = () => {
    setShowAsDialog(true)
    const [body] = document.getElementsByTagName("body")
    body.style.overflow = "hidden"
  }

  const closeModal = () => {
    setShowAsDialog(false)
    const [body] = document.getElementsByTagName("body")
    body.style.overflow = "visible"
  }

  const onBeforeLoginSubmit = () => {
    setRedirectURL(PASSENGERS)
  }

  return (
    <>
      <div className="login-dialog-btns-container">
        <p>
          <button onClick={openModal}>Sign in</button> to your Penguin Perks account to check out
          even faster
        </p>
      </div>
      {showAsDialog && (
        <Modal onClose={closeModal}>
          <LoginForm
            history={history}
            actions={actions}
            onBeforeSubmit={onBeforeLoginSubmit}
            showAsDialog={true}
          />
        </Modal>
      )}
    </>
  )
}

export default LoginDialog
