import React from "react";

const MSPMeta = {
    header: {
        title: "Minneapolis St. Paul (MSP) Airport Shuttle",
        subtitle: "To Duluth",
        description: "Landline departs promptly at the scheduled time. \nYour driver will come inside the terminal to announce the departure from the MSP airport.\nPlease arrive early and have your ID ready to ensure an on-time departure.",
        book: true
    },
    seo: {
        title: "MSP Airport Shuttle to Duluth | Landline",
        description: "Landline’s premium airport shuttle connects Duluth to MSP hassle-free. Enjoy free Wi-Fi, skip parking, and travel in comfort. Book your ride today!",
    }
};

const MSPService = <>
    <p>Make your next trip more comfortable and convenient with Landline’s non-stop service to and from Minneapolis St. Paul International Airport (MSP).</p>
    <p>Avoid the stress and expense of driving, parking or ridesharing, and instead opt for Landline’s comfortable, Wi-Fi enabled vehicles.</p>
    <p>Enjoy modern conveniences like air conditioning, in-seat power, reclining leather seats, onboard restrooms, and get caught up on work or stream your favorite shows while you ride.</p>
    <p>Our friendly staff will help you with loading and unloading your luggage on both ends of your trip ensuring a smooth and efficient trip.</p>
    <p>Landline is ideal for families, college students, leisure and business travelers alike looking for a safe, reliable, and affordable way to/from MSP or the Twin Cities.</p>
</>;

const MSPLocationService = {
    title: "Landline's Minneapolis Service",
    subtitle: "Pick Up / Drop Off Location",
    image: "https://content.landline.com/wp-content/uploads/2024/05/MSP_Service.jpg",
    content: <>
        <p>The Landline picks up and drops off at both Terminal 1 and Terminal 2 at MSP. Have your ID ready to expedite the boarding process.</p>

        <b>Terminal 1:</b>
        <p>Proceed to the <a href="https://maps.mspairport.com/?lang=en&s=eyJvbmxpbmUvZ2V0RGlyZWN0aW9uc0Zyb21UbyI6eyJtdWx0aXBvaW50Um91dGluZyI6dHJ1ZX0sIm9ubGluZS9wb2lWaWV3Ijp7InBvaUlkIjoiNDAwMDQ5OCJ9LCJtYXBSZW5kZXJlciI6eyJ2cCI6eyJsYXQiOjQ0Ljg4MDUwMiwibG5nIjotOTMuMjA0MzQ3LCJ6b29tIjoyMC4xMTE1MTMsImJlYXJpbmciOjAsInBpdGNoIjowfSwib3JkIjoxfX0%3D" target="_blank">Silver Parking Ramp building</a>, which can be found by following signs for “buses” and/or “rental cars”. We pick up on level 1 outside the doors near the Ace Rental Car counter.</p>

        <b>Terminal 2:</b>
        <p>Proceed to level 1 of the <a href="https://maps.mspairport.com/?lang=en&s=eyJvbmxpbmUvZ2V0RGlyZWN0aW9uc0Zyb21UbyI6eyJtdWx0aXBvaW50Um91dGluZyI6dHJ1ZX0sIm9ubGluZS9wb2lWaWV3Ijp7InBvaUlkIjoiMTEyNyJ9LCJtYXBSZW5kZXJlciI6eyJ2cCI6eyJsYXQiOjQ0Ljg3MzE1NywibG5nIjotOTMuMjI3MDEsInpvb20iOjE2Ljk4NDUzMiwiYmVhcmluZyI6MCwicGl0Y2giOjB9LCJvcmQiOjJ9fQ%3D%3D" target="_blank">Purple Parking Ramp building</a>, which can be found across the street from Sun Country baggage claim (follow signs for baggage claim). Your driver will come inside to announce arrival and boarding. Please wait just inside the double doors.</p>

        <b>Sun Country Passengers</b>
        <p>If you booked our shuttle services as part of your <a href="https://suncountry.com/landline" target="_blank">Sun Country itinerary</a>, please note our luggage handling policy has changed. If you are traveling after July 1, 2024, please collect your luggage from the oversized bag carousel before boarding the Landline shuttle.</p>
    </>
};

export {
    MSPLocationService,
    MSPMeta,
    MSPService
};
