const Pickup_Locations = [
  {
    image: "https://content.landline.com/wp-content/uploads/2024/04/CSU-Map.jpg",
    title: "CSU Transit Center (CSU)",
    prices: "Adults: $32 | Kids: FREE (12 & under)",
    link: "https://maps.google.com/maps?ll=40.576033,-105.085223&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=12154003753580978015",
    top: "Pick up/drop off is on the north side of Plum Ave just east of the service drive for Allison Hall.",
    bottom: "There is no public parking available on the CSU campus, however, it is steps away from public transportation and is easily accessible via S Meridian St. or W Plumb St. for family/friend drop off/pick up."
  },
]

export default Pickup_Locations