import * as React from "react"
import B2BHowItWorksIconAndDes from "./B2BHowItWorksIconAndDes"
import * as styles from "./B2BHowItWorks.module.scss"

const B2BHowItWorks = ({ items }) => {
  return (
    <div className={styles.B2BHowItWorks} id="B2BHowItWorks">
      <h2 className={styles.B2BHowItWorksTitle}>How it works</h2>
      <div className={styles.B2BhowItWorksContent}>
        {items.map(item => {
          return (
            <B2BHowItWorksIconAndDes
              key={item.id}
              icon={item.icon}
              description={item.description}
              link={item.link}
            ></B2BHowItWorksIconAndDes>
          )
        })}
      </div>
    </div>
  )
}

export default B2BHowItWorks
