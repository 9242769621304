import EntertainmentImage from "../../../media/entertainmentKids.png"
import NonStop from "../../../media/nonstop.png"
import comfortImage from "../../../media/comfort.png"
import amenitiesImage from "../../../media/amenities.png"

export const landlineExperienceContent = [
  {
    id: 1,
    image: EntertainmentImage,
    title: "Entertainment",
    text1: `We provide an array of technologies to ensure that you trip
    is anything but boring.`,
    text2: `Connect your phone or laptop to our system to access free
    movies, tv shows, audiobooks, games, magazines and more!
    Plus, use our free and fast Wi-Fi to surf the internet or
    catch up on work. Each seat has a charging port to keep your
    devices charged and ready to go.`,
  },
  {
    id: 2,
    image: NonStop,
    title: `Non-Stop`,
    text1: `Skip all the stop and go. Our service provides non-stop travel to your destination.`,
    text2: `Unlike many of our competitors, Landline is committed to fast and affordable transportation.
    Once you board the bus, the next stop is your final destination.`,
  },
  {
    id: 3,
    image: comfortImage,
    title: `Comfort`,
    text1: `Our high-end motorcoaches are configured to make your trip luxurious and comfortable.`,
    text2: `With only three seats per aisle, passengers enjoy wider seats and more personal space.
  Customers also have the option to select solo seating.
  `,
  },
  {
    id: 4,
    image: amenitiesImage,
    title: `Amenities`,
    text1: `Our onboard amenities help make your trip with us worry free.
  `,
    text2: `Each motorcoach is equipped with an onboard restroom and personal A/C controls.
  `,
  },
]
