import React from "react"
import SchedulePage from "../index.jsx"
import ScheduleHeader from "../Header.jsx"
import OurService from "../OurService.jsx"
import LocationService from "../Service.jsx"
import ModuleTitle from "../../../atoms/SchedulePages/ModuleTitle.jsx"
import { PickupLocations, PickupLocation } from "../Locations.jsx"
import { Schedule, ScheduleTable } from "../Schedule.jsx"
import Search from "../../HomePage/Search.jsx"

import Pickup_Locations from "./pickup-locations.js"
import { CSUMeta, CSUService, DropOffData } from "./metadata.js"
import CSUData from "./schedule.js"
import Seo from "../../../molecules/Seo/index.jsx"

import { MdAirlineSeatReclineExtra } from "@react-icons/all-files/md/MdAirlineSeatReclineExtra"
import { TbCalendarTime, TbBusStop } from "react-icons/tb"
import { FaMapMarkerAlt } from "react-icons/fa"

import "./csu.scss"

const CSUSchedule = ({ history }) => {
  return (
    <SchedulePage>
      <Seo
        title={CSUMeta.seo.title}
        description={CSUMeta.seo.description}
        canonicalUrl={"https://landline.com/csu"}
      />
      <section className="full-width-heading schedule-page-banner">
        <h1>Colorado State University to Denver (DIA) Airport Shuttle</h1>
        <span>CSU Campus Transit Center | Old Town Fort Collins</span>
      </section>
      <div className="schedule-search-form">
        <Search
          history={history}
          remoteCallSearchComponent={true}
          containerClassName="schedule-page-search-bg"
        />
      </div>
      <div className="schedule-page">
        <ScheduleHeader metadata={CSUMeta.header} />

        <section className="icon1-section">
          <div className="icon1-grid csu-icon-section">
            {[
              {
                icon: <TbCalendarTime />,
                title: "Schedules",
                scrollTo: "schedules-module",
              },
              {
                icon: <FaMapMarkerAlt />,
                title: "Pick-Up Locations",
                scrollTo: "pick-up-locations-module",
              },
              {
                icon: <FaMapMarkerAlt />,
                title: "Drop Off Location",
                scrollTo: "drop-off-location-module",
              },
              {
                icon: <MdAirlineSeatReclineExtra />,
                title: "Our Service",
                scrollTo: "our-service-module",
              },
            ].map((item, index) => {
              const handleAction = () => {
                const targetElement = document.getElementById(item.scrollTo)
                if (targetElement) {
                  const headerOffset = 180 // Adjust if your navbar is sticky
                  const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY
                  const offsetPosition = elementPosition - headerOffset

                  window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                  })
                } else {
                  console.error(`Element with ID '${item.scrollTo}' not foundj.`)
                }
              }

              return (
                <div
                  key={index}
                  className="icon1-card"
                  onClick={handleAction}
                  role="button"
                  tabIndex={0}
                  style={{ cursor: "pointer" }}
                >
                  <div className="icon1-wrapper">{item.icon}</div>
                  <h3 className="icon1-title">{item.title}</h3>
                </div>
              ) 
            })}
          </div>
        </section>

        <ModuleTitle title="Schedules" />
        <div id="schedules-module"></div>
        <Schedule>
          <ScheduleTable schedule={CSUData.to} />
          <ScheduleTable schedule={CSUData.from} />
        </Schedule>

        <PickupLocations>
          <div id="pick-up-locations-module"></div>
          {Pickup_Locations.map(location => {
            return <PickupLocation location={location} />
          })}
          <div class="otherlocations-container">
            <a class="otherlocations" href="/fort-collins">Other Pickup Locations in Fort Collins</a>
          </div>
        </PickupLocations>

        <section>
          <ModuleTitle title="Drop Off Location" />
          <div id="drop-off-location-module"></div>
          <LocationService service={DropOffData} />
        </section>

        <section>
          <ModuleTitle title="Our Service" />
          <div id="our-service-module"></div>
          <OurService service={CSUService} />
        </section>

      </div>
    </SchedulePage>
  )
}

export default CSUSchedule