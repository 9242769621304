import "./Footer.styles.scss"

import React from "react"

import {
  CHARTER_LANDLINE,
  CONTACT_PAGE,
  CORPORATE_TRAVEL,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  FAQ_PAGE
} from "../../../constants/routes"
import config from "../../../utils/config"
import TopMenuLogo from "../../atoms/TopMenuLogo"
import { Socials } from "../../socials/Socials"

const FooterLinks = () => {
  return (
    <p>
      Premium Airport Shuttle serving:{" "}
      <a href="/denver" className="footer-city-link">
        Denver
      </a>
      ,{" "}
      <a href="/fort-collins" className="footer-city-link">
        Fort Collins / Loveland
      </a>
      ,{" "}
      <a href="/csu" className="footer-city-link">
        Colorado State University
      </a>
      ,{" "}
      <a href="/msp" className="footer-city-link">
        Minneapolis
      </a>
      , and{" "}
      <a href="/duluth" className="footer-city-link">
        Duluth
      </a>
    </p>
  )
}

const Footer = () => {
  return (
    <div className="container">
      <div className="line" />
      <div className="footer-links-wrapper">
        <div className="footerLogoColumn">
          <div>
            <TopMenuLogo />
          </div>
          <a href="tel:+18884281149" className="phoneplus">
            +1 <span className="phone">(888)-428-1149</span>
          </a>
        </div>
        <div>
          <div className="links">
            <a href={`${config.LANDLINE_URL}${CHARTER_LANDLINE}`}>Charters</a>
            <a href={`${config.LANDLINE_URL}${FAQ_PAGE}`}>FAQ</a>
            <a href={`${config.LANDLINE_URL}${CORPORATE_TRAVEL}`}>Corporate Travel</a>
            <a href="https://landlineco.com/" target="_blank">
              The Landline Co
            </a>
            <a href={`${config.LANDLINE_URL}${CONTACT_PAGE}`}>Contact</a>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "15px",
        }}
      >
        <Socials />
      </div>
      <div className="city-links">
        <FooterLinks />
      </div>
      <div className="copyright">
        &copy;
        {new Date().getFullYear()} The Landline Company. <br /> All Rights Reserved.
      </div>
      <div className="privacy">
        <a href={`${config.LANDLINE_URL}${TERMS_AND_CONDITIONS}`}>Terms and Conditions</a>{" "}
        <span style={{ color: "#FAB500" }}> | </span>
        <a href={`${config.LANDLINE_URL}${PRIVACY_POLICY}`}>Privacy Policy</a>{" "}
      </div>
    </div>
  )
}

export default Footer
