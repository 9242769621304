const dayMap = {
  1: "MON",
  2: "TUE",
  3: "WED",
  4: "THU",
  5: "FRI",
  6: "SAT",
  7: "SUN",
}

const flights = [
  {
    eff: "07-Feb-25",
    dis: "07-Feb-25",
    freq: "….5..",
    orig: "DLH",
    dept: "1042",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "13-Feb-25",
    dis: "13-Feb-25",
    freq: "…4...",
    orig: "DLH",
    dept: "1027",
    dest: "MSP",
    arr: "1325",
  },
  {
    eff: "14-Feb-25",
    dis: "14-Feb-25",
    freq: "….5..",
    orig: "DLH",
    dept: "1027",
    dest: "MSP",
    arr: "1325",
  },
  {
    eff: "07-Feb-25",
    dis: "15-Feb-25",
    freq: "…..6.",
    orig: "DLH",
    dept: "1042",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "07-Feb-25",
    dis: "16-Feb-25",
    freq: "…...7",
    orig: "DLH",
    dept: "1042",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "07-Feb-25",
    dis: "17-Feb-25",
    freq: "1…...",
    orig: "DLH",
    dept: "1042",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "20-Feb-25",
    dis: "20-Feb-25",
    freq: "…4...",
    orig: "DLH",
    dept: "1042",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "21-Feb-25",
    dis: "21-Feb-25",
    freq: "….5..",
    orig: "DLH",
    dept: "1052",
    dest: "MSP",
    arr: "1350",
  },
  {
    eff: "22-Feb-25",
    dis: "22-Feb-25",
    freq: "…..6.",
    orig: "DLH",
    dept: "1022",
    dest: "MSP",
    arr: "1320",
  },
  {
    eff: "07-Feb-25",
    dis: "26-Feb-25",
    freq: "..3….",
    orig: "DLH",
    dept: "1102",
    dest: "MSP",
    arr: "1400",
  },
  {
    eff: "28-Feb-25",
    dis: "28-Feb-25",
    freq: "….5..",
    orig: "DLH",
    dept: "1032",
    dest: "MSP",
    arr: "1330",
  },
  {
    eff: "01-Mar-25",
    dis: "01-Mar-25",
    freq: "…..6.",
    orig: "DLH",
    dept: "1042",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "24-Feb-25",
    dis: "03-Mar-25",
    freq: "1…...",
    orig: "DLH",
    dept: "1032",
    dest: "MSP",
    arr: "1330",
  },
  {
    eff: "07-Mar-25",
    dis: "07-Mar-25",
    freq: "….5..",
    orig: "DLH",
    dept: "1102",
    dest: "MSP",
    arr: "1400",
  },
  {
    eff: "08-Mar-25",
    dis: "08-Mar-25",
    freq: "…..6.",
    orig: "DLH",
    dept: "1052",
    dest: "MSP",
    arr: "1350",
  },
  {
    eff: "23-Feb-25",
    dis: "09-Mar-25",
    freq: "…...7",
    orig: "DLH",
    dept: "1102",
    dest: "MSP",
    arr: "1400",
  },
  {
    eff: "10-Mar-25",
    dis: "10-Mar-25",
    freq: "1…...",
    orig: "DLH",
    dept: "1042",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "27-Feb-25",
    dis: "13-Mar-25",
    freq: "…4...",
    orig: "DLH",
    dept: "1032",
    dest: "MSP",
    arr: "1330",
  },
  {
    eff: "16-Mar-25",
    dis: "16-Mar-25",
    freq: "…...7",
    orig: "DLH",
    dept: "1042",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "20-Mar-25",
    dis: "20-Mar-25",
    freq: "…4...",
    orig: "DLH",
    dept: "1052",
    dest: "MSP",
    arr: "1350",
  },
  {
    eff: "23-Mar-25",
    dis: "23-Mar-25",
    freq: "…...7",
    orig: "DLH",
    dept: "1022",
    dest: "MSP",
    arr: "1320",
  },
  {
    eff: "05-Mar-25",
    dis: "26-Mar-25",
    freq: "..3….",
    orig: "DLH",
    dept: "1042",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "14-Mar-25",
    dis: "26-Mar-25",
    freq: "….5..",
    orig: "DLH",
    dept: "1047",
    dest: "MSP",
    arr: "1345",
  },
  {
    eff: "15-Mar-25",
    dis: "26-Mar-25",
    freq: "…..6.",
    orig: "DLH",
    dept: "1042",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "17-Mar-25",
    dis: "26-Mar-25",
    freq: "1…...",
    orig: "DLH",
    dept: "1022",
    dest: "MSP",
    arr: "1320",
  },
  {
    eff: "27-Mar-25",
    dis: "27-Mar-25",
    freq: "…4...",
    orig: "DLH",
    dept: "1012",
    dest: "MSP",
    arr: "1310",
  },
  {
    eff: "31-Mar-25",
    dis: "31-Mar-25",
    freq: "1…...",
    orig: "DLH",
    dept: "1012",
    dest: "MSP",
    arr: "1310",
  },
  {
    eff: "28-Mar-25",
    dis: "04-Apr-25",
    freq: "….5..",
    orig: "DLH",
    dept: "1042",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "29-Mar-25",
    dis: "05-Apr-25",
    freq: "…..6.",
    orig: "DLH",
    dept: "1047",
    dest: "MSP",
    arr: "1345",
  },
  {
    eff: "30-Mar-25",
    dis: "06-Apr-25",
    freq: "…...7",
    orig: "DLH",
    dept: "1040",
    dest: "MSP",
    arr: "1350",
  },
  {
    eff: "03-Apr-25",
    dis: "10-Apr-25",
    freq: "…4...",
    orig: "DLH",
    dept: "1022",
    dest: "MSP",
    arr: "1320",
  },
  {
    eff: "12-Apr-25",
    dis: "12-Apr-25",
    freq: "…..6.",
    orig: "DLH",
    dept: "0952",
    dest: "MSP",
    arr: "1250",
  },
  {
    eff: "13-Apr-25",
    dis: "13-Apr-25",
    freq: "…...7",
    orig: "DLH",
    dept: "1015",
    dest: "MSP",
    arr: "1325",
  },
  {
    eff: "07-Apr-25",
    dis: "14-Apr-25",
    freq: "1…...",
    orig: "DLH",
    dept: "1007",
    dest: "MSP",
    arr: "1305",
  },
  {
    eff: "20-Apr-25",
    dis: "20-Apr-25",
    freq: "…...7",
    orig: "DLH",
    dept: "0950",
    dest: "MSP",
    arr: "1300",
  },
  {
    eff: "17-Apr-25",
    dis: "24-Apr-25",
    freq: "…4...",
    orig: "DLH",
    dept: "1012",
    dest: "MSP",
    arr: "1310",
  },
  {
    eff: "11-Apr-25",
    dis: "25-Apr-25",
    freq: "….5..",
    orig: "DLH",
    dept: "1007",
    dest: "MSP",
    arr: "1305",
  },
  {
    eff: "19-Apr-25",
    dis: "26-Apr-25",
    freq: "…..6.",
    orig: "DLH",
    dept: "1022",
    dest: "MSP",
    arr: "1320",
  },
  {
    eff: "27-Apr-25",
    dis: "27-Apr-25",
    freq: "…...7",
    orig: "DLH",
    dept: "0940",
    dest: "MSP",
    arr: "1250",
  },
  {
    eff: "21-Apr-25",
    dis: "28-Apr-25",
    freq: "1…...",
    orig: "DLH",
    dept: "0942",
    dest: "MSP",
    arr: "1240",
  },
  {
    eff: "01-May-25",
    dis: "15-May-25",
    freq: "…4...",
    orig: "DLH",
    dept: "1057",
    dest: "MSP",
    arr: "1355",
  },
  {
    eff: "22-May-25",
    dis: "22-May-25",
    freq: "…4...",
    orig: "DLH",
    dept: "1052",
    dest: "MSP",
    arr: "1350",
  },
  {
    eff: "04-May-25",
    dis: "25-May-25",
    freq: "…...7",
    orig: "DLH",
    dept: "1045",
    dest: "MSP",
    arr: "1355",
  },
  {
    eff: "02-May-25",
    dis: "30-May-25",
    freq: "….5..",
    orig: "DLH",
    dept: "1057",
    dest: "MSP",
    arr: "1355",
  },
  {
    eff: "03-May-25",
    dis: "31-May-25",
    freq: "…..6.",
    orig: "DLH",
    dept: "1142",
    dest: "MSP",
    arr: "1440",
  },
  {
    eff: "05-May-25",
    dis: "02-Jun-25",
    freq: "1…...",
    orig: "DLH",
    dept: "1057",
    dest: "MSP",
    arr: "1355",
  },
  {
    eff: "09-Jun-25",
    dis: "09-Jun-25",
    freq: "1…...",
    orig: "DLH",
    dept: "1052",
    dest: "MSP",
    arr: "1350",
  },
  {
    eff: "29-May-25",
    dis: "02-Sep-25",
    freq: "…4...",
    orig: "DLH",
    dept: "1102",
    dest: "MSP",
    arr: "1400",
  },
  {
    eff: "01-Jun-25",
    dis: "02-Sep-25",
    freq: "…...7",
    orig: "DLH",
    dept: "1050",
    dest: "MSP",
    arr: "1400",
  },
  {
    eff: "06-Jun-25",
    dis: "02-Sep-25",
    freq: "….5..",
    orig: "DLH",
    dept: "1102",
    dest: "MSP",
    arr: "1400",
  },
  {
    eff: "07-Jun-25",
    dis: "02-Sep-25",
    freq: "…..6.",
    orig: "DLH",
    dept: "1102",
    dest: "MSP",
    arr: "1400",
  },
  {
    eff: "16-Jun-25",
    dis: "02-Sep-25",
    freq: "1…...",
    orig: "DLH",
    dept: "1102",
    dest: "MSP",
    arr: "1400",
  },
  {
    eff: "07-Feb-25",
    dis: "07-Feb-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1640",
    dest: "DLH",
    arr: "1938",
  },
  {
    eff: "14-Feb-25",
    dis: "14-Feb-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1700",
    dest: "DLH",
    arr: "1958",
  },
  {
    eff: "21-Feb-25",
    dis: "21-Feb-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1630",
    dest: "DLH",
    arr: "1928",
  },
  {
    eff: "28-Feb-25",
    dis: "28-Feb-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1640",
    dest: "DLH",
    arr: "1938",
  },
  {
    eff: "07-Feb-25",
    dis: "06-Mar-25",
    freq: "…4...",
    orig: "MSP",
    dept: "1625",
    dest: "DLH",
    arr: "1923",
  },
  {
    eff: "07-Feb-25",
    dis: "08-Mar-25",
    freq: "…..6.",
    orig: "MSP",
    dept: "1615",
    dest: "DLH",
    arr: "1913",
  },
  {
    eff: "07-Feb-25",
    dis: "09-Mar-25",
    freq: "…...7",
    orig: "MSP",
    dept: "1625",
    dest: "DLH",
    arr: "1923",
  },
  {
    eff: "15-Mar-25",
    dis: "15-Mar-25",
    freq: "…..6.",
    orig: "MSP",
    dept: "1640",
    dest: "DLH",
    arr: "1938",
  },
  {
    eff: "16-Mar-25",
    dis: "16-Mar-25",
    freq: "…...7",
    orig: "MSP",
    dept: "1610",
    dest: "DLH",
    arr: "1908",
  },
  {
    eff: "22-Mar-25",
    dis: "22-Mar-25",
    freq: "…..6.",
    orig: "MSP",
    dept: "1630",
    dest: "DLH",
    arr: "1928",
  },
  {
    eff: "23-Mar-25",
    dis: "23-Mar-25",
    freq: "…...7",
    orig: "MSP",
    dept: "1650",
    dest: "DLH",
    arr: "1948",
  },
  {
    eff: "07-Feb-25",
    dis: "26-Mar-25",
    freq: "..3….",
    orig: "MSP",
    dept: "1625",
    dest: "DLH",
    arr: "1923",
  },
  {
    eff: "07-Feb-25",
    dis: "26-Mar-25",
    freq: "1…...",
    orig: "MSP",
    dept: "1625",
    dest: "DLH",
    arr: "1923",
  },
  {
    eff: "07-Mar-25",
    dis: "26-Mar-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1630",
    dest: "DLH",
    arr: "1928",
  },
  {
    eff: "13-Mar-25",
    dis: "26-Mar-25",
    freq: "…4...",
    orig: "MSP",
    dept: "1645",
    dest: "DLH",
    arr: "1943",
  },
  {
    eff: "27-Mar-25",
    dis: "27-Mar-25",
    freq: "…4...",
    orig: "MSP",
    dept: "1645",
    dest: "DLH",
    arr: "1943",
  },
  {
    eff: "28-Mar-25",
    dis: "28-Mar-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1605",
    dest: "DLH",
    arr: "1915",
  },
  {
    eff: "30-Mar-25",
    dis: "30-Mar-25",
    freq: "…...7",
    orig: "MSP",
    dept: "1550",
    dest: "DLH",
    arr: "1848",
  },
  {
    eff: "29-Mar-25",
    dis: "05-Apr-25",
    freq: "…..6.",
    orig: "MSP",
    dept: "1635",
    dest: "DLH",
    arr: "1933",
  },
  {
    eff: "06-Apr-25",
    dis: "06-Apr-25",
    freq: "…...7",
    orig: "MSP",
    dept: "1625",
    dest: "DLH",
    arr: "1923",
  },
  {
    eff: "03-Apr-25",
    dis: "10-Apr-25",
    freq: "…4...",
    orig: "MSP",
    dept: "1705",
    dest: "DLH",
    arr: "2003",
  },
  {
    eff: "12-Apr-25",
    dis: "12-Apr-25",
    freq: "…..6.",
    orig: "MSP",
    dept: "1640",
    dest: "DLH",
    arr: "1938",
  },
  {
    eff: "13-Apr-25",
    dis: "13-Apr-25",
    freq: "…...7",
    orig: "MSP",
    dept: "1630",
    dest: "DLH",
    arr: "1928",
  },
  {
    eff: "17-Apr-25",
    dis: "17-Apr-25",
    freq: "…4...",
    orig: "MSP",
    dept: "1600",
    dest: "DLH",
    arr: "1858",
  },
  {
    eff: "20-Apr-25",
    dis: "20-Apr-25",
    freq: "…...7",
    orig: "MSP",
    dept: "1635",
    dest: "DLH",
    arr: "1933",
  },
  {
    eff: "31-Mar-25",
    dis: "21-Apr-25",
    freq: "1…...",
    orig: "MSP",
    dept: "1630",
    dest: "DLH",
    arr: "1928",
  },
  {
    eff: "24-Apr-25",
    dis: "24-Apr-25",
    freq: "…4...",
    orig: "MSP",
    dept: "1615",
    dest: "DLH",
    arr: "1913",
  },
  {
    eff: "19-Apr-25",
    dis: "26-Apr-25",
    freq: "…..6.",
    orig: "MSP",
    dept: "1600",
    dest: "DLH",
    arr: "1858",
  },
  {
    eff: "27-Apr-25",
    dis: "27-Apr-25",
    freq: "…...7",
    orig: "MSP",
    dept: "1620",
    dest: "DLH",
    arr: "1918",
  },
  {
    eff: "28-Apr-25",
    dis: "28-Apr-25",
    freq: "1…...",
    orig: "MSP",
    dept: "1710",
    dest: "DLH",
    arr: "2008",
  },
  {
    eff: "01-May-25",
    dis: "01-May-25",
    freq: "…4...",
    orig: "MSP",
    dept: "1555",
    dest: "DLH",
    arr: "1853",
  },
  {
    eff: "08-May-25",
    dis: "08-May-25",
    freq: "…4...",
    orig: "MSP",
    dept: "1625",
    dest: "DLH",
    arr: "1923",
  },
  {
    eff: "05-May-25",
    dis: "12-May-25",
    freq: "1…...",
    orig: "MSP",
    dept: "1555",
    dest: "DLH",
    arr: "1853",
  },
  {
    eff: "15-May-25",
    dis: "15-May-25",
    freq: "…4...",
    orig: "MSP",
    dept: "1545",
    dest: "DLH",
    arr: "1843",
  },
  {
    eff: "22-May-25",
    dis: "22-May-25",
    freq: "…4...",
    orig: "MSP",
    dept: "1620",
    dest: "DLH",
    arr: "1918",
  },
  {
    eff: "19-May-25",
    dis: "26-May-25",
    freq: "1…...",
    orig: "MSP",
    dept: "1625",
    dest: "DLH",
    arr: "1923",
  },
  {
    eff: "04-Apr-25",
    dis: "30-May-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1625",
    dest: "DLH",
    arr: "1935",
  },
  {
    eff: "02-Jun-25",
    dis: "02-Jun-25",
    freq: "1…...",
    orig: "MSP",
    dept: "1610",
    dest: "DLH",
    arr: "1908",
  },
  {
    eff: "03-May-25",
    dis: "02-Sep-25",
    freq: "…..6.",
    orig: "MSP",
    dept: "1550",
    dest: "DLH",
    arr: "1848",
  },
  {
    eff: "04-May-25",
    dis: "02-Sep-25",
    freq: "…...7",
    orig: "MSP",
    dept: "1600",
    dest: "DLH",
    arr: "1858",
  },
  {
    eff: "29-May-25",
    dis: "02-Sep-25",
    freq: "…4...",
    orig: "MSP",
    dept: "1550",
    dest: "DLH",
    arr: "1848",
  },
  {
    eff: "06-Jun-25",
    dis: "02-Sep-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1550",
    dest: "DLH",
    arr: "1900",
  },
  {
    eff: "09-Jun-25",
    dis: "02-Sep-25",
    freq: "1…...",
    orig: "MSP",
    dept: "1550",
    dest: "DLH",
    arr: "1848",
  },
  {
    eff: "07-Feb-25",
    dis: "07-Feb-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1640",
    dest: "FAR",
    arr: "2045",
  },
  {
    eff: "14-Feb-25",
    dis: "14-Feb-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1640",
    dest: "FAR",
    arr: "2045",
  },
  {
    eff: "21-Feb-25",
    dis: "21-Feb-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1630",
    dest: "FAR",
    arr: "2035",
  },
  {
    eff: "28-Feb-25",
    dis: "28-Feb-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1640",
    dest: "FAR",
    arr: "2045",
  },
  {
    eff: "07-Feb-25",
    dis: "06-Mar-25",
    freq: "…4...",
    orig: "MSP",
    dept: "1625",
    dest: "FAR",
    arr: "2030",
  },
  {
    eff: "07-Feb-25",
    dis: "08-Mar-25",
    freq: "…..6.",
    orig: "MSP",
    dept: "1615",
    dest: "FAR",
    arr: "2020",
  },
  {
    eff: "07-Feb-25",
    dis: "09-Mar-25",
    freq: "..3….",
    orig: "MSP",
    dept: "1625",
    dest: "FAR",
    arr: "2030",
  },
  {
    eff: "07-Feb-25",
    dis: "09-Mar-25",
    freq: ".2…..",
    orig: "MSP",
    dept: "1625",
    dest: "FAR",
    arr: "2030",
  },
  {
    eff: "07-Feb-25",
    dis: "09-Mar-25",
    freq: "…...7",
    orig: "MSP",
    dept: "1625",
    dest: "FAR",
    arr: "2030",
  },
  {
    eff: "15-Mar-25",
    dis: "15-Mar-25",
    freq: "…..6.",
    orig: "MSP",
    dept: "1640",
    dest: "FAR",
    arr: "2045",
  },
  {
    eff: "07-Mar-25",
    dis: "16-Mar-25",
    freq: "….5..",
    orig: "MSP",
    dept: "1630",
    dest: "FAR",
    arr: "2035",
  },
  {
    eff: "16-Mar-25",
    dis: "16-Mar-25",
    freq: "…...7",
    orig: "MSP",
    dept: "1610",
    dest: "FAR",
    arr: "2015",
  },
  {
    eff: "07-Feb-25",
    dis: "07-Feb-25",
    freq: "….5..",
    orig: "FAR",
    dept: "0935",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "13-Feb-25",
    dis: "13-Feb-25",
    freq: "…4...",
    orig: "FAR",
    dept: "0920",
    dest: "MSP",
    arr: "1325",
  },
  {
    eff: "14-Feb-25",
    dis: "14-Feb-25",
    freq: "….5..",
    orig: "FAR",
    dept: "0920",
    dest: "MSP",
    arr: "1325",
  },
  {
    eff: "07-Feb-25",
    dis: "15-Feb-25",
    freq: "…..6.",
    orig: "FAR",
    dept: "0935",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "07-Feb-25",
    dis: "16-Feb-25",
    freq: "…...7",
    orig: "FAR",
    dept: "0935",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "07-Feb-25",
    dis: "17-Feb-25",
    freq: "1…...",
    orig: "FAR",
    dept: "0935",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "20-Feb-25",
    dis: "20-Feb-25",
    freq: "…4...",
    orig: "FAR",
    dept: "0935",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "21-Feb-25",
    dis: "21-Feb-25",
    freq: "….5..",
    orig: "FAR",
    dept: "0945",
    dest: "MSP",
    arr: "1350",
  },
  {
    eff: "22-Feb-25",
    dis: "22-Feb-25",
    freq: "…..6.",
    orig: "FAR",
    dept: "0915",
    dest: "MSP",
    arr: "1320",
  },
  {
    eff: "07-Feb-25",
    dis: "26-Feb-25",
    freq: "..3….",
    orig: "FAR",
    dept: "0955",
    dest: "MSP",
    arr: "1400",
  },
  {
    eff: "28-Feb-25",
    dis: "28-Feb-25",
    freq: "….5..",
    orig: "FAR",
    dept: "0925",
    dest: "MSP",
    arr: "1330",
  },
  {
    eff: "01-Mar-25",
    dis: "01-Mar-25",
    freq: "…..6.",
    orig: "FAR",
    dept: "0935",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "24-Feb-25",
    dis: "03-Mar-25",
    freq: "1…...",
    orig: "FAR",
    dept: "0925",
    dest: "MSP",
    arr: "1330",
  },
  {
    eff: "07-Mar-25",
    dis: "07-Mar-25",
    freq: "….5..",
    orig: "FAR",
    dept: "0955",
    dest: "MSP",
    arr: "1400",
  },
  {
    eff: "08-Mar-25",
    dis: "08-Mar-25",
    freq: "…..6.",
    orig: "FAR",
    dept: "0945",
    dest: "MSP",
    arr: "1350",
  },
  {
    eff: "23-Feb-25",
    dis: "09-Mar-25",
    freq: "…...7",
    orig: "FAR",
    dept: "0955",
    dest: "MSP",
    arr: "1400",
  },
  {
    eff: "27-Feb-25",
    dis: "10-Mar-25",
    freq: "…4...",
    orig: "FAR",
    dept: "0925",
    dest: "MSP",
    arr: "1330",
  },
  {
    eff: "05-Mar-25",
    dis: "10-Mar-25",
    freq: "..3….",
    orig: "FAR",
    dept: "0935",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "10-Mar-25",
    dis: "10-Mar-25",
    freq: "1…...",
    orig: "FAR",
    dept: "0935",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "15-Mar-25",
    dis: "15-Mar-25",
    freq: "…..6.",
    orig: "FAR",
    dept: "0935",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "16-Mar-25",
    dis: "16-Mar-25",
    freq: "…...7",
    orig: "FAR",
    dept: "0935",
    dest: "MSP",
    arr: "1340",
  },
  {
    eff: "17-Mar-25",
    dis: "17-Mar-25",
    freq: "1…...",
    orig: "FAR",
    dept: "0915",
    dest: "MSP",
    arr: "1320",
  },
]

const cities = {
  duluth: "DLH",
  fargo: "FAR",
  lacrosse: "LSE",
  minneapolis: "MSP",
}

const locations = {
  minneapolisDuluth: "MSP-DLH",
  minneapolisFargo: "MSP-FAR",
  minneapolisLacrosse: "MSP-LSE",
}

const getFlightsByOriginAndDestiny = (origin, destiny) =>
  flights.filter(
    ({ orig, dest }) => [origin, destiny].includes(orig) && [origin, destiny].includes(dest),
  )

const schedule = {
  [locations.minneapolisDuluth]: {
    flights: getFlightsByOriginAndDestiny(cities.minneapolis, cities.duluth),
    city: cities.duluth,
    from: cities.duluth,
    to: cities.minneapolis,
    title: { from: "From Duluth", to: "To Duluth" },
  },
  [locations.minneapolisFargo]: {
    flights: getFlightsByOriginAndDestiny(cities.minneapolis, cities.fargo),
    city: cities.fargo,
    from: cities.fargo,
    to: cities.minneapolis,
    title: { from: "From Fargo", to: "To Fargo" },
  },
  [locations.minneapolisLacrosse]: {
    flights: getFlightsByOriginAndDestiny(cities.minneapolis, cities.lacrosse),
    city: cities.lacrosse,
    from: cities.lacrosse,
    to: cities.minneapolis,
    title: { from: "From LaCrosse", to: "To LaCrosse" },
  },
}

const formatTime = timeStr => `${timeStr.slice(0, -2)}:${timeStr.slice(-2)}`

// Utility to add/subtract minutes from a time string and format it properly with a colon
const adjustTime = (timeStr, minutes) => {
  const hours = parseInt(timeStr.slice(0, -2))
  const mins = parseInt(timeStr.slice(-2))
  const date = new Date()
  date.setHours(hours, mins + minutes, 0)
  const adjustedHours = date
    .getHours()
    .toString()
    .padStart(2, "0")
  const adjustedMinutes = date
    .getMinutes()
    .toString()
    .padStart(2, "0")
  return `${adjustedHours}:${adjustedMinutes}`
}

const getFlightDelta = flight => {
  const freq = flight.freq.match(/\d+/).at(0)
  return freq - 1
}

const getWeeklyItinerary = (location, start, end, origin, direction) => {
  const weeklyFlights = schedule[location].flights.filter(flight => {
    if (flight.orig !== origin) return false

    const weekStart = new Date(start)
    const weekEnd = new Date(end)
    const flightStart = new Date(flight.eff)
    const flightEnd = new Date(flight.dis)

    // active week is within flight
    if (flightStart <= weekStart && weekEnd <= flightEnd) return true

    // flight is within active week
    if (weekStart <= flightStart && flightEnd <= weekEnd) return true

    // ending flight or starting flight are outside of active week
    if (!(flightStart <= weekEnd && weekStart <= flightEnd)) return false

    const flightEndDelta = getFlightDelta(flight)
    const weeklyFlight = new Date(
      weekStart.getFullYear(),
      weekStart.getMonth(),
      weekStart.getDate() + flightEndDelta,
    )

    // flight frequency within week is outside of flight bounds
    if (weeklyFlight <= flightEnd) return true

    return false
  })

  const daysWithService = new Map()

  weeklyFlights.forEach(flight => {
    // For flights to LaCrosse (from MSP)
    // MSA departs 20 min before MSB, MSB is original departure, LSE is original arrival
    const msaTime =
      direction === "to"
        ? adjustTime(flight.dept, -20) // MSA departs 20 min before MSB
        : adjustTime(flight.arr, 15) // MSA arrives 15 min after MSB

    const times =
      direction === "to"
        ? [
            msaTime, // MSA departure (20 min before MSB)
            formatTime(flight.dept), // MSB departure (original MSP time)
            formatTime(flight.arr), // LSE arrival
          ]
        : [
            formatTime(flight.dept), // LSE departure
            formatTime(flight.arr), // MSB arrival (original MSP time)
            msaTime, // MSA arrival (15 min after MSB)
          ]

    if (flight.freq.includes("1")) daysWithService.set(1, [dayMap[1], ...times])
    if (flight.freq.includes("2")) daysWithService.set(2, [dayMap[2], ...times])
    if (flight.freq.includes("3")) daysWithService.set(3, [dayMap[3], ...times])
    if (flight.freq.includes("4")) daysWithService.set(4, [dayMap[4], ...times])
    if (flight.freq.includes("5")) daysWithService.set(5, [dayMap[5], ...times])
    if (flight.freq.includes("6")) daysWithService.set(6, [dayMap[6], ...times])
    if (flight.freq.includes("7")) daysWithService.set(7, [dayMap[7], ...times])
  })

  return Array.from(daysWithService.values()).sort((a, b) => {
    const dayA = Object.entries(dayMap).find(([_, val]) => val === a[0])[0]
    const dayB = Object.entries(dayMap).find(([_, val]) => val === b[0])[0]
    return parseInt(dayA) - parseInt(dayB)
  })
}

const generateItineraryData = (location, week) => {
  if (!week?.start || !week?.end) {
    console.error("Invalid week object provided:", week)
    return {
      from: { title: "", stops: [], itinerary: [] },
      to: { title: "", stops: [], itinerary: [] },
    }
  }

  const { from, to, city, title } = schedule[location]
  const itineraryFrom = getWeeklyItinerary(location, week.start, week.end, from, "from")
  const itineraryTo = getWeeklyItinerary(location, week.start, week.end, to, "to")

  return {
    from: {
      title: title.from,
      stops: [
        { type: "", name: "DAYS" },
        { type: "Depart", name: city },
        { type: "Arrive", name: "MSP T2" },
        { type: "Arrive", name: "MSP T1" }, // New column for MSA stop
      ],
      itinerary: itineraryFrom,
    },
    to: {
      title: title.to,
      stops: [
        { type: "", name: "DAYS" },
        { type: "Depart", name: "MSP T1" }, // New column for MSA stop
        { type: "Depart", name: "MSP T2" },
        { type: "Arrive", name: city },
      ],
      itinerary: itineraryTo,
    },
  }
}

const generateWeeks = location => {
  // Find earliest and latest dates in schedule
  const dates = schedule[location].flights.flatMap(flight => [
    new Date(flight.eff),
    new Date(flight.dis),
  ])
  const earliestDate = new Date(Math.min(...dates))
  const latestDate = new Date(Math.max(...dates))

  // Get to the Monday of the earliest week
  const firstMonday = new Date(earliestDate)
  firstMonday.setDate(firstMonday.getDate() - firstMonday.getDay() + 1) // Move to Monday

  const weeks = []
  let currentMonday = new Date(firstMonday)

  while (currentMonday <= latestDate) {
    // Create Sunday by adding 6 days to Monday
    const sunday = new Date(currentMonday)
    sunday.setDate(sunday.getDate() + 6)

    weeks.push({
      start: currentMonday
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "2-digit",
        })
        .replace(/ /g, "-"),
      end: sunday
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "2-digit",
        })
        .replace(/ /g, "-"),
    })

    // Move to next Monday
    currentMonday = new Date(currentMonday)
    currentMonday.setDate(currentMonday.getDate() + 7)
  }

  return weeks
}

export { generateWeeks, generateItineraryData, locations }
