import * as React from "react"
import { useEffect } from "react"
import PropTypes from "prop-types"
import SpecialFooter from "../../organisms/SpecialFooter"
import AmericanHeader from "./Header"

const AmericanLayout = ({ children, headerPhoneNumber }) => {

  const getEnv = variable => {
      if (typeof window !== "undefined") {
        try {
          return window._env_[variable]
        } catch (error) {
          console.log(error)
        }
      }
      return ""
    }

  useEffect(() => {
    if (typeof window !== "undefined") {
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : ""
        j.async = true
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, "script", "dataLayer", getEnv("GA_TAG_MANAGER_ID"))
    }
  }, [])

  return (
    <>
      <AmericanHeader phoneNumber={headerPhoneNumber} />
      {children}
      <SpecialFooter />
    </>
  )
}

AmericanLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AmericanLayout
