import * as React from "react"
import TopMenu from "../../organisms/TopMenu"
import Footer from "../../organisms/Footer"
import "./TermsAndConditionsPage.scss"

const TermsAndConditionsPage = () => {
  const terms = (
    <div className="wpWrapper wpTop">
      <div className="wpContent">
        <div>
          <h2>
            <strong>Terms and Conditions of Purchase and Carriage</strong>
          </h2>

          <p>
            <strong>Last updated:&nbsp;October 16, 2023</strong>
          </p>

          <p>
            The Landline Company (“Landline”) is a Delaware corporation headquartered at 113 S
            College Avenue, Fort Collins, CO 80524. Landline’s DOT number is 3425026.
          </p>

          <p>
            <strong>1. Landline Reservations</strong>
          </p>

          <p>
            <strong>Pricing.</strong>&nbsp;All prices are displayed in United States Dollars (USD).
            Any displayed fares are inclusive of any taxes applicable to the segment. Landline
            cannot control booking or reservation fees applied to Landline segments booked via third
            parties.
          </p>

          <p>
            <strong>Refunds.</strong>&nbsp;All tickets are non-refundable unless you are a Silver,
            Gold, or Platinum Penguin Perks loyalty member. As a loyalty member, tickets may be
            refunded prior to the departure of the first leg of travel, provided that the
            cancellation is made at least 12 hours before the scheduled departure time. Loyalty
            members can cancel their trips on landline.com under the “My Trips” tab. If you did not
            use a Landline Penguin Perks loyalty account for your original purchase, please contact
            a customer service agent to cancel your reservation and redeem your credit for the
            original ticket price if cancelled before departure.&nbsp;
          </p>

          <p>
            <strong>Changes.</strong>&nbsp;Reservations may be changed prior to travel and will be
            charged any difference in fare. This policy is subject to change at Landline’s sole
            discretion at any time in advance of travel.
          </p>

          <p>
            <strong>Schedule Changes.</strong>&nbsp;Landline reserves the right to amend the
            published travel times, dates and routes from time to time. Passengers will be notified
            in advance if their travel itinerary is affected by any change in schedule and will be
            assisted to reaccommodate if necessary. Schedule changes shall not entitle passengers to
            assert compensation claims. Substantial schedule changes shall entitle passengers to
            withdraw from the contract of carriage free of charge.
          </p>

          <p>
            <strong>Passenger Age.</strong>&nbsp;Customers must be 18 years of age or older to make
            a reservation. All children under 14 years of age must be accompanied by an adult 18
            years of age or over. By purchasing travel on Landline, you affirm that you are 18 years
            of age or older and the passenger traveling is 14 years of age or older (or is traveling
            with an accompanying adult). Landline reserves the right to validate the age of
            customers to enforce this policy.
          </p>

          <p>
            <strong>Booking Confirmation.</strong>&nbsp;It is your responsibility to check that your
            booking confirmation is accurate. Landline is only responsible for information that was
            requested and provided at the time travel was booked.
          </p>

          <p>
            <strong>Reservations for Code-Share Flights. </strong>Landline also offers
            transportation through “codeshare” arrangements with partner airlines.&nbsp; These trips
            are reserved and sold through our partner airlines, and their rules and policies
            regarding prices, fees, reservations, refunds, changes, cancellations and confirmations
            shall govern with respect to those trips.
          </p>

          <p>
            <strong>2. Traveling on Landline</strong>
          </p>

          <p>
            <strong>Time of Departure.</strong>&nbsp;Landline requires that passengers arrive at
            least 10 minutes prior to the scheduled departure time of all Landline segments in order
            to facilitate an on-time departure. In the event that you are not at the point of
            departure at least 10 minutes prior to the scheduled departure time of your Landline
            segment, your reservation may be forfeited.
          </p>

          <p>
            <strong>Check-In and Boarding.</strong>&nbsp;All passengers are required to provide
            proof of reservation (by providing access to booking confirmation) and a valid photo
            identification prior to boarding the Landline vehicle. If Landline has reason to suspect
            fraudulent activity as it relates to a customer booking, Landline reserves the right to
            refuse travel.
          </p>

          <p>
            <strong>NOTE: Check-In for Codeshare Flights.</strong>&nbsp;Passengers ticketed on a
            codeshare flight can check in and check their baggage for the flight at the beginning of
            the Landline journey.&nbsp; Passengers are requested to be at the Landline vehicle at
            least 10 minutes before departure to complete check-in procedures.&nbsp; Passengers must
            have all necessary documentation required for airline check-in, including, for
            passengers connecting to an international flight, passport and visa (if the destination
            requires a visa).
          </p>

          <p>
            <strong>Connecting Travel.</strong>&nbsp; In the event that you have booked connecting
            travel to another destination (for example, connecting to an airline flight), Landline
            cannot guarantee a successful connection, and will not be liable for any damages
            resulting from a missed connection. Landline recommends that you plan for adequate time
            between connections to ensure that there are no travel interruptions as a result of
            delays.
          </p>

          <p>
            <strong>Passenger Obligations Onboard.</strong>&nbsp;Passengers must obey all
            instructions issued by the driver or other Landline staff. Landline’s driver is
            authorized to remove or deny boarding to passengers that are intoxicated or under the
            influence of drugs, or who compromise the safety or well-being of the other passengers.
            Smoking (including e-cigarettes) is not permitted onboard. Passengers shall be liable
            for damages caused on, in or to the vehicle in a culpable manner. &nbsp;
          </p>

          <p>
            <strong>Food and Beverages.</strong>&nbsp;Landline permits passengers to bring food and
            non-alcoholic beverages onboard.&nbsp;
          </p>

          <p>
            <strong>Recording Policy.</strong>&nbsp;For customer service and operational reasons,
            Landline may be recording both vehicle and station operations. These recordings can be
            viewed anytime by Landline personnel. In addition, these recordings can be made
            available to government officials or law enforcement at their request. Your acceptance
            of these Terms and Conditions and/or boarding of a Landline segment constitutes the
            acceptance of this recording policy and implies consent to any such recordings and their
            use.
          </p>

          <p>
            <strong>3. Acceptance of&nbsp;Baggage</strong>&nbsp;
          </p>

          <p>
            Each Landline customer is entitled to one (1) piece of luggage. That luggage must not
            have exterior dimensions in excess of 70 inches. In addition to luggage, customers are
            entitled to one (1) small carry-on bag and one (1) personal item. The personal item must
            be able to comfortably fit in the overhead bins, beneath your seat, or safely in your
            lap. Depending on the vehicle type and size, the carry-on baggage must be able to
            comfortably fit in the overhead bins or beneath your seat, or it may be stored in the
            baggage compartment. Landline personnel have the exclusive right to determine the
            suitability of personal and carry-on baggage. While Landline will make efforts to
            accommodate all passengers’ luggage, any passengers carrying baggage in excess of these
            quantities may be refused with no refunds issued in such circumstances.
          </p>

          <p>
            Landline recommends that all luggage should be locked with TSA-approved locking devices.
            In addition, Landline recommends that customers ensure their luggage is tagged and
            identified with both the customer’s full legal name and contact information. Any
            required medical devices or strollers for customers are considered in addition to these
            luggage limitations. Strollers must be stowed in the luggage compartment and have the
            ability to be folded.
          </p>

          <p>
            Passengers traveling with sports or musical equipment, or other over-sized baggage must
            notify Landline reservations in advance to make sure it can be accommodated.
          </p>

          <p>
            Customers may not transport any luggage that could be inherently dangerous or hazardous.
            This includes any hazardous items as defined by the U.S. Department of Transportation or
            weapons of any kind. This also includes any item that is prohibited by law.
          </p>

          <p>
            <strong>NOTE: Baggage on Codeshare Flights.</strong>&nbsp;For passengers ticketed on
            codeshare flights, Landline will check passenger bags for the full ticketed journey. The
            partner airline’s rules and policies regarding acceptance of baggage will govern.&nbsp;
            Passengers are encouraged to review the partner airline’s website to determine what may
            or may not be accepted.&nbsp; Landline cannot accept baggage for check-in that is not in
            accordance with the partner airline’s requirements.&nbsp; Limitations on baggage
            liability follow below.
          </p>

          <p>
            <strong>4.&nbsp;Passengers with Disabilities&nbsp;or Limited Mobility</strong>
          </p>

          <p>
            Landline vehicles are fully compliant with all applicable ADA requirements and the
            provisions of 49 CFR Parts 37 and 38.&nbsp;
          </p>

          <p>
            <strong>For passengers ticketed on a codeshare flight</strong>, the provisions of 14 CFR
            Part 382 will apply both to the air portion and the land portion of the trip.&nbsp;
            Passengers requiring special assistance are urged to contact the partner airline for any
            necessary arrangements.
          </p>

          <p>
            <strong>Traveling in a Wheelchair or Mobility Scooter.&nbsp;</strong>Any traveler who
            intends to travel in a wheeled mobility device is encouraged to book as far in advance
            as possible. Landline may not be able to accommodate all customers traveling in wheeled
            mobility devices in one single vehicle.
          </p>

          <p>
            When you book your trip, make sure you tell us if you’re traveling with a wheelchair or
            mobility scooter. If you’re booking online, you can select this prior to making final
            payment for your reservation.
            <strong>&nbsp;If you select “Travel in Wheelchair,” please email us at&nbsp;</strong>
            <a href="mailto:reservations@landline.com">
              <strong>reservations@landline.com</strong>
            </a>
            <strong>
              &nbsp;so that we can confirm availability and provide the best possible assistance
              <em>.&nbsp;</em>
            </strong>
          </p>

          <p>
            The maximum weight and size of a wheelchair or mobility scooter that we can accept are:
          </p>

          <ul>
            <li>Weight including the passenger can’t be over 600lbs (272kg)</li>
            <li>Wheelchair dimensions of 30 x 48 inches (76cm x 121cm)</li>
            <li>Mobility scooter with dimensions of 30 x 30 x 48 inches (76 x 76 x 122cm)</li>
          </ul>

          <p>
            If your wheelchair or mobility scooter doesn’t fit within the dimensions above, it will
            need to be stored in the baggage compartment.
          </p>

          <p>
            <strong>5. Service Animals</strong>
          </p>

          <p>
            A Service Animal is any animal that is individually trained or able to provide
            assistance to a qualified person with a disability.
          </p>

          <p>
            Landline will accept for transportation without charge a Service Animal when the Service
            Animal accompanies the passenger with a disability in the vehicle cabin; however, the
            Service Animal will not be permitted to occupy a seat and must accompany the passenger
            in the passenger’s lap or in the passenger’s foot space (unless this location and
            placement would be inconsistent with any applicable regulations or encroach into another
            passenger’s space). Service Animals are not considered personal items and do not count
            against the personal item allowance.
          </p>

          <p>
            Before traveling with a Service Animal, and, as a condition of permitting the Service
            Animal to travel in the vehicle, the passenger must provide Landline a current completed
            U.S. Department of Transportation Service Animal Air Transportation Form. Arrangements
            for the transportation of a Service Animal must be made at least forty-eight (48) hours
            prior to departure via reservations@landline.com.
          </p>

          <p>
            Service Animals must be harnessed, leashed or otherwise tethered at all times by the
            passenger or Service Animal handler while at the waiting area and onboard the vehicle.
          </p>

          <p>
            Landline reserves the right to deny transport to a Service Animal under the
            circumstances outlined in 14 CFR Section 382.79, including if the Service Animal poses a
            direct threat to the health or safety of others, it causes a significant disruption in
            the vehicle cabin or at the waiting area, its behavior on the vehicle or at the waiting
            area indicates that it has not been trained to behave properly in public, its carriage
            would violate applicable safety or health requirements of any U.S. federal agency, U.S.
            territory, or foreign government, or the passenger with a disability fails to provide
            completed current forms as required above.
          </p>

          <p>
            Passengers with disabilities traveling with Service Animals accepted under this rule
            will be seated in compliance with “14 CFR Part 382 – Non-Discrimination on the Basis of
            Disability in Air Travel”.
          </p>

          <p>
            Landline will accept without charge a properly harnessed animal trained in explosive
            detection, drug detection or search and rescue when it is accompanied by its handler.
            Such animal will be permitted to accompany its handler in the cabin but will not be
            permitted to occupy a seat. The animal and its handler must be on official duty status
            and such status must be documented in writing to the satisfaction of Landline.
          </p>

          <p>
            Landline does not accept the following animals on board: snakes or other reptiles;
            ferrets; rodents; spiders; animals that may be too large or heavy to accommodate in the
            vehicle cabin; or animals that pose a threat to the health or safety of others
          </p>

          <p>
            <strong>6. Acceptance of Pets</strong>
          </p>

          <p>
            <em>
              The following requirements do not apply to Service Animals. Please refer to the
              Service Animals section.
            </em>
          </p>

          <p>
            Landline accepts small cats and dogs (weighing 16 lbs. or less) in the passenger cabin
            only, subject to the following conditions.
          </p>

          <ul>
            <li>
              Advance arrangements are required and availability is limited. You must contact
              Landline Reservations to book pet travel.
            </li>

            <li>
              A non-refundable fee will apply, which can be revised by Landline from time to time.
            </li>

            <li>
              Pets are accepted conditionally and must not pose a hazard to the safety of any
              passenger or Landline staff.
            </li>

            <li>The number of pets per trip may be limited.</li>

            <li>
              Pet and its carrier are considered a carry-on item and are included in passenger’s
              regular carry-on allotment.
            </li>

            <li>Pet must remain in its carrier at all times while aboard the Landline vehicle.</li>

            <li>
              Owner is responsible for the pet and may be denied future travel on Landline if pet is
              taken out of the carrier while aboard the vehicle or disturbs other passengers.
            </li>

            <li>
              Passengers are responsible for complying with applicable federal, state and local
              regulations or ordinances related to animals.
            </li>

            <li>Unaccompanied animals of any kind are not accepted.</li>
          </ul>

          <p>
            <strong>Pet Carrier Requirements.</strong>&nbsp;The pet must fit in a carrier that does
            not exceed 16″L x 11″W x 8″ and must be able to stand up and move around inside the
            carrier with ease. The carrier must have a leak-proof bottom and must be well-ventilated
            on at least two sides. Approved soft-sided carriers specifically designed as pet
            carriers are recommended as long as they meet the above requirements. Landline does not
            have pet carriers available for use or sale.
          </p>

          <p>
            <strong>7. Dispute Resolution</strong>
          </p>

          <p>
            <strong>Arbitration.</strong>&nbsp;Except as specifically outlined herein, you may elect
            to resolve any dispute, claim or controversy (collectively “Claims”) by individual
            arbitration. Such Claims include, but are not limited to, all disputes between the
            parties arising out of, or in any way relating to: transportation services; loss, damage
            or injury to persons and/or property transported by Landline; claims of discrimination;
            the use of the Landline website and its implementation; and these Terms and Conditions
            or the breach, termination, enforcement, interpretation or validity thereof. The Federal
            Arbitration Act will govern the interpretation and enforcement of this Section.
          </p>

          <p>
            <strong>No Trial by Jury or Class Action / Representative Claims.</strong>&nbsp;If
            arbitration is chosen by either party, neither you nor Landline will have the right to
            litigate that Claim in court or have a jury trial on that Claim. By accepting these
            Terms and Conditions, you acknowledge and agree that you and Landline are each waiving
            the right to a trial by jury to any Claim subject to arbitration.
          </p>

          <p>
            If either party elects to resolve a claim by arbitration, that claim will be arbitrated
            on an individual basis. There will be no right or authority for any claims to be
            arbitrated on a class action basis or on bases involving claims brought in a purported
            representative capacity on behalf of the general public or other persons similarly
            situated.
          </p>

          <p>
            The arbitrator’s authority is limited to claims between you and Landline alone. Claims
            may not be joined or consolidated unless you and Landline agree in writing. An
            arbitration award and any judgment confirming it will apply only to the specific case
            and cannot be used in any other case except to enforce the award.
          </p>

          <p>
            <strong>Arbitration Rules and Process.</strong>&nbsp;Claims are to be decided by a
            neutral arbitrator. The arbitration will be administered by the American Arbitration
            Association (“AAA”) in accordance with the Commercial Arbitration Rules and the
            Supplementary Procedures for Consumer-Related Disputes (the “AAA Rules”) then in effect,
            except as modified by this “Dispute Resolution” section. The AAA Rules are available at
            www.adr.org or by calling the AAA at 1-800-778-7879.
          </p>

          <p>
            A party who desires to initiate arbitration must provide the other party with a written
            Demand for Arbitration as specified in the AAA Rules. The AAA provides a form Demand for
            Arbitration on its website. The arbitrator will be either a retired judge or an attorney
            licensed to practice law and will be selected by the parties from the AAA’s roster of
            consumer dispute arbitrators. If the parties are unable to agree upon an arbitrator
            within thirty (30) days of delivery of the Demand for Arbitration, then the AAA will
            appoint the arbitrator in accordance with the AAA Rules.
          </p>

          <p>
            <strong>Arbitration Location and Procedure.</strong>&nbsp;Unless you and Landline agree
            otherwise in writing, the arbitration will be conducted in the county where you reside.
            In the event that you reside outside of the continental United States, the arbitration
            will be conducted in the county of your departure.
          </p>

          <p>
            If your claim does not exceed $10,000, then the arbitration will be conducted solely on
            the basis of documents you and Landline submit to the arbitrator, unless you request a
            hearing or the arbitrator determines that a hearing is necessary. If your claim exceeds
            $10,000, your right to a hearing will be determined by the AAA Rules. Subject to the AAA
            Rules, the arbitrator will have the discretion to direct a reasonable exchange of
            information by the parties, consistent with the expedited nature of the arbitration.
          </p>

          <p>
            <strong>Arbitrator’s Decision.</strong>&nbsp;The arbitrator’s decision is as enforceable
            as any court order and is subject to very limited review by a court. The arbitrator will
            render an award within the time frame specified in the AAA Rules. The arbitrator’s
            decision will include the essential findings and conclusions upon which the arbitrator
            based the award. Judgment on the arbitration award may be entered in any court having
            jurisdiction thereof.
          </p>

          <p>
            <strong>Fees.</strong>&nbsp;Your responsibility to pay any AAA filing, administrative
            and arbitrator fees will be solely as set forth in the AAA Rules. However, if your claim
            for damages does not exceed $75,000, Landline will pay all such fees, unless the
            Arbitrator finds that either the substance of your claim or the relief sought in your
            Demand for Arbitration was frivolous or was brought for an improper purpose (as measured
            by the standards set forth in Federal Rule of Civil Procedure 11(b)).
          </p>

          <p>
            <strong>Governing Law.</strong>&nbsp; This agreement shall be governed by and construed
            in accordance with the law of the State of Colorado.
          </p>

          <p>
            <strong>Exclusive Jurisdiction.</strong>&nbsp; If arbitration is not elected, and an
            action is brought before a court of law, any such actions arising out of this contract
            shall be brought exclusively in a state or federal court located in the State of
            Colorado.
          </p>

          <p>
            <strong>8. Limitation of Liability/Claims</strong>
          </p>

          <p>
            <strong>General.</strong>&nbsp; Landline shall not be liable for loss, damage or delay
            caused by:
          </p>

          <ul>
            <li>The act or default by the passenger;</li>

            <li>The nature of the property or defect therein;</li>

            <li>
              An act of God, public enemies, authority of the law, quarantine, perils of navigation,
              riots, strikes, or the hazards or dangers incidents to a state of war;
            </li>

            <li>
              Accidents, breakdowns, bad conditions of roads or other causes beyond Landline’s
              control; or&nbsp;
            </li>

            <li>
              Acts that occur before the passenger boards the Landline vehicle or after the
              passenger disembarks.
            </li>
          </ul>

          <p>
            Landline will not be responsible for any incidental or consequential damages caused by
            any of the above.
          </p>

          <p>
            Landline assumes no liability for the loss of or damage to baggage caused by Landline in
            excess of two hundred and fifty ($250) dollars, and then only to the extent of the
            actual loss or damage sustained caused by Landline, computed on the basis of the actual
            value of the baggage. Except as provided below, Landline will assume no liability for
            damage, breakage, deterioration, delay, and/or loss of any article prohibited to be
            transported on its vehicles. Such prohibited articles include any hazardous materials as
            that term is defined by the US Department of Transportation or any weapons of any kind.
          </p>

          <p>
            Any claim against Landline for loss, damage or delay must be filed with Landline within
            30 days of the discovery of the loss or damage on which the claim is based, but in no
            case shall this extend any statute of limitations otherwise in effect. Otherwise,
            Landline will have no responsibility to pay the claim.
          </p>

          <p>
            If a passenger’s Landline journey is delayed or canceled and a passenger doesn’t accept
            Landline’s alternative arrangements, or none are available, Landline will refund the
            full value of the passenger’s ticket. In all situations, Landline’s contractual
            liability to the passenger shall not exceed the value of the passenger’s ticket.
          </p>

          <p>
            <strong>Baggage Liability on Codeshare Trips.</strong>&nbsp; If passengers are ticketed
            on a codeshare flight and their checked baggage is lost, delayed, damaged or destroyed
            during the portion of the trip operated by Landline, it shall be subject to the
            following liability limits:&nbsp; for ticketed trips that do not involve an ultimate
            destination or origin outside the United States (i.e., are purely domestic), the actual
            value of the baggage or $3500 per bag, whichever is less; for ticketed trips which
            involve an origin or destination outside the United States, liability is governed by the
            provisions of the Warsaw Convention or the Montreal Convention, whichever shall apply,
            and the limits of Landline’s liability shall be in accordance with the applicable
            Convention. In no event shall Landline be responsible for any damages caused before the
            baggage has been checked in by the passenger, or after the baggage has been delivered to
            the connecting partner airline, or after it has been returned to the passenger at the
            end of the journey.
          </p>

          <p>
            In the event of lost, delayed, damaged or destroyed baggage, the passenger should report
            the matter to Landline within 12 hours after the termination of the land portion of the
            trip.&nbsp; If compensation is sought, a written claim must be presented to Landline
            within 45 days after the initial report was made.&nbsp; Failure to comply with these
            procedures will release Landline from liability.
          </p>

          <p>
            <strong>Disease-related Harm or Injury.</strong>&nbsp;By purchasing this ticket the
            passenger acknowledges the risk associated with vehicle transportation, including
            potential transmission and/or infection by COVID-19 or other diseases. Passengers are
            responsible for taking the necessary precautions and safety measures as they deem
            appropriate. To the fullest extent permitted by applicable law, liability for health in
            case of injury or harm from a transmission and/or infection of COVID-19 or other
            communicable diseases shall be excluded, except in cases of intentional or negligent
            injury. As such, the passenger waives the right to sue Landline or the airline operator
            or partner operator for any disease-related harm or injuries arising from the purchased
            travel.
          </p>

          <p>
            <strong>9. Miscellaneous</strong>
          </p>

          <p>
            <strong>General.</strong>&nbsp;Failure or delay by us to enforce an obligation or
            exercise a right under these terms and conditions does not constitute a waiver of that
            obligation or right.
          </p>

          <p>
            If any provision or term of these terms and conditions shall become or be declared
            illegal, invalid, void or unenforceable for any reason whatsoever, such term or
            provision shall be divisible and severed from the other terms and conditions and shall
            be deemed to be deleted from them. Severance of such terms shall not affect the validity
            or enforceability of the remaining terms.
          </p>

          <p>
            We may alter these terms and conditions from time to time and post the new version on
            our website, following which all use of our website will be governed by that version. It
            is your responsibility to check the terms and conditions on the website regularly and
            prior to making a reservation.
          </p>

          <p>
            Unless specifically stated otherwise herein or required by law, Landline shall not be
            liable for any consequential, compensatory, indirect, incidental or punitive damages
            arising out of or in connection with the performance of its obligations under these
            terms and conditions.
          </p>

          <p>
            Landline and has the right to cancel reservations (whether or not confirmed) of any
            customer whenever such action is necessary to comply with any governmental regulation,
            upon any governmental request for emergency transportation in connection with the
            national defense, or whenever such action is necessary or advisable by reason of weather
            or other conditions beyond Landline’s control, including Force Majeure events.
          </p>

          <p>
            <strong>No Discrimination.</strong>&nbsp;Reservations and seating aboard Landline
            vehicles operated in interstate, intrastate or foreign commerce is without regard to
            race, color, creed, or national origin.
          </p>
        </div>
      </div>
    </div>
  )
  return (
    <>
      <TopMenu />
      {terms}
      <Footer />
    </>
  )
}

export default TermsAndConditionsPage
