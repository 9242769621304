import React, { useState } from "react"
import { useRef } from "react"

import { ComposableMap, Marker, Geographies, Geography } from "react-simple-maps"
import { geoMercator } from "d3-geo"
import { line as d3Line, curveCatmullRom } from "d3-shape"
import BasicPage from "../BasicPage"
import "./howitworks.scss"
import { MdWifi } from "@react-icons/all-files/md/MdWifi"
import { MdPower } from "@react-icons/all-files/md/MdPower"
import { MdAcUnit } from "@react-icons/all-files/md/MdAcUnit"
import { FaWheelchair } from "@react-icons/all-files/fa/FaWheelchair"
import { FaPaw } from "@react-icons/all-files/fa/FaPaw"
import ModuleTitle from "../../atoms/SchedulePages/ModuleTitle"
import { MdOpenInNew } from "@react-icons/all-files/md/MdOpenInNew"
import American from "./americanairlines.png"
import AirCanada from "./aircanada.png"
import United from "./united.png"
import SunCountry from "./suncountry.png"
import Interior from "./shuttleinterior.jpg"
import Seo from "../../molecules/Seo"

import { MdFlight } from "@react-icons/all-files/md/MdFlight"
import { MdAirlineSeatReclineExtra } from "@react-icons/all-files/md/MdAirlineSeatReclineExtra"
import { MdEventSeat } from "@react-icons/all-files/md/MdEventSeat"
import { MdWork } from "@react-icons/all-files/md/MdWork"
import { MdDirectionsBus } from "@react-icons/all-files/md/MdDirectionsBus"
import { MdAccountCircle } from "@react-icons/all-files/md/MdAccountCircle"

const amenities = [
  { icon: <MdWifi size={48} />, title: "Complimentary Wi-Fi & Entertainment" },
  { icon: <MdAirlineSeatReclineExtra size={48} />, title: "Reclining Leather Seats" },
  { icon: <MdPower size={48} />, title: "In-seat Power Outlets" },
  { icon: <MdAcUnit size={48} />, title: "Individual Climate Controls" },
]
// URLs to TopoJSON data
const statesGeoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json"
const countiesGeoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/counties-10m.json"

// Mapping for clickable links in each region
const denMarkerLinks = {
  DEN: "/fort-collins",
  FNL: "/fort-collins",
  CSU: "/csu",
  HTC: "/fort-collins",
}

const mspMarkerLinks = {
  MSP: "/msp",
  DLH: "/duluth",
  LSE: "/msp",
}

// DenverMap: draws a continuous curved path through DEN, FNL, HTC, and CSU.
const DenverMap = ({ stops }) => {
  const width = 1000
  const height = 600

  // Fixed projection: map always renders at 1000x600
  const projection = geoMercator()
    .scale(25000)
    .center([-104.5, 40.2])
    .translate([width / 2, height / 2])

  // Define the desired route order.
  const routeOrder = ["DEN", "FNL", "HTC", "CSU"]
  const routeStops = routeOrder.map(id => stops.find(stop => stop.id === id)).filter(Boolean)

  // Build a continuous curved path using a Catmull–Rom curve.
  let pathData = ""
  if (routeStops.length > 0) {
    const coordinates = routeStops.map(stop => projection([stop.lon, stop.lat]))
    const lineGenerator = d3Line()
      .x(d => d[0])
      .y(d => d[1])
      .curve(curveCatmullRom.alpha(0.5))
    pathData = lineGenerator(coordinates)
  }

  // City labels for the Denver region.
  const denverCities = [
    { name: "Fort Collins", lat: 40.5853, lon: -105.0844, offset: -20 },
    { name: "Greeley", lat: 40.4237, lon: -104.7091, offset: 20 },
    { name: "Timnath", lat: 40.5171, lon: -104.8933, offset: 20 },
    { name: "Loveland", lat: 40.3978, lon: -105.0745, offset: 20 },
    { name: "Denver", lat: 39.7392, lon: -104.9903, offset: 20 },
  ]

  return (
    <ComposableMap
      projection="geoMercator"
      projectionConfig={{ scale: 25000, center: [-104.5, 40.2] }}
      width={1000}
      height={600}
      viewBox="0 0 1000 600"
    >
      {/* Background States */}
      <Geographies geography={statesGeoUrl}>
        {({ geographies }) =>
          geographies.map(geo => (
            <Geography key={geo.rsmKey} geography={geo} fill="#f8f9fa" stroke="#DDD" />
          ))
        }
      </Geographies>

      {/* County Boundaries for Colorado (FIPS starting with "08") */}
      <Geographies geography={countiesGeoUrl}>
        {({ geographies }) =>
          geographies
            .filter(geo => geo.id.startsWith("08"))
            .map(geo => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="none"
                stroke="#BBB"
                strokeWidth={0.5}
              />
            ))
        }
      </Geographies>

      {/* Continuous Curved Route Line */}
      {pathData && (
        <path d={pathData} stroke="#e0e0e0" strokeWidth={4} strokeDasharray="4 2" fill="none" />
      )}

      {/* Render Airport Markers as clickable links */}
      {stops.map(stop => (
        <Marker key={stop.id} coordinates={[stop.lon, stop.lat]}>
          <a href={denMarkerLinks[stop.id]} style={{ textDecoration: "none" }}>
            <circle r={10} fill="#FAB500" stroke="#FFFFFF" strokeWidth={2} />
            <text textAnchor="middle" y={-10} style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}>
              {stop.id}
            </text>
          </a>
        </Marker>
      ))}

      {/* Render City Labels */}
      {denverCities.map(city => (
        <Marker key={city.name} coordinates={[city.lon, city.lat]}>
          <text
            textAnchor="middle"
            y={city.offset}
            style={{ fontFamily: "system-ui", fill: "#888", fontSize: "12px" }}
          >
            {city.name}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  )
}

// MSPMap: fixed dimensions so markers remain at the same size.
const MSPMap = ({ stops }) => {
  const width = 1000
  const height = 600

  const projection = geoMercator()
    .scale(3000)
    .center([-91.6, 44.5])
    .translate([width / 2, height / 2])

  const minnesotaCities = [
    { name: "Minneapolis", lat: 44.9778, lon: -93.265, offset: 27 },
    { name: "Duluth", lat: 46.7867, lon: -92.1005, offset: 20 },
  ]

  const getArcPath = (p1, p2) => {
    const dx = p2[0] - p1[0],
      dy = p2[1] - p1[1],
      mx = (p1[0] + p2[0]) / 2,
      my = (p1[1] + p2[1]) / 2,
      len = Math.sqrt(dx * dx + dy * dy)
    if (len === 0) return ""
    const offset = 30,
      ox = (-dy / len) * offset,
      oy = (dx / len) * offset,
      cx = mx + ox,
      cy = my + oy
    return `M${p1[0]},${p1[1]} Q${cx},${cy} ${p2[0]},${p2[1]}`
  }

  return (
    <ComposableMap
      projection="geoMercator"
      projectionConfig={{ scale: 3000, center: [-91.6, 44.5] }}
      width={1000}
      height={600}
      viewBox="0 0 1000 600"
    >
      <Geographies geography={statesGeoUrl}>
        {({ geographies }) =>
          geographies.map(geo => (
            <Geography key={geo.rsmKey} geography={geo} fill="#f8f9fa" stroke="#DDD" />
          ))
        }
      </Geographies>

      {(() => {
        const hub = stops.find(stop => stop.id === "MSP")
        if (!hub) return null
        return stops
          .filter(stop => stop.id !== "MSP")
          .map(stop => {
            const p1 = projection([hub.lon, hub.lat])
            const p2 = projection([stop.lon, stop.lat])
            const d = getArcPath(p1, p2)
            return (
              <path
                key={`arc-${stop.id}`}
                d={d}
                stroke="#e0e0e0"
                strokeWidth={4}
                strokeDasharray="4 2"
                fill="none"
              />
            )
          })
      })()}

      {stops.map(stop => (
        <Marker key={stop.id} coordinates={[stop.lon, stop.lat]}>
          <a href={mspMarkerLinks[stop.id]} style={{ textDecoration: "none" }}>
            <circle r={10} fill="#FAB500" stroke="#FFFFFF" strokeWidth={2} />
            <text textAnchor="middle" y={-10} style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}>
              {stop.id}
            </text>
          </a>
        </Marker>
      ))}

      {minnesotaCities.map(city => (
        <Marker key={city.name} coordinates={[city.lon, city.lat]}>
          <text
            textAnchor="middle"
            y={city.offset}
            style={{ fontFamily: "system-ui", fill: "#888", fontSize: "12px" }}
          >
            {city.name}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  )
}

const HowItWorksPage = () => {
  const [activeTab, setActiveTab] = useState("DEN")
  const metadata = {
    title: "How It Works | Landline Premium Airport Shuttle",
    subtitle:
      "Landline's premium airport shuttle service offers easy, affordable travel with first class amenities. Enjoy perks like free Wi-Fi, A/C, and comfortable seats.",
  }

  const denStops = [
    { id: "DEN", name: "Denver Airport", lat: 39.849519, lon: -104.674973 },
    { id: "FNL", name: "Northern Colorado", lat: 40.450704, lon: -105.007761 },
    { id: "CSU", name: "CSU Transit", lat: 40.576471, lon: -105.08585 },
    { id: "HTC", name: "Harmony Center", lat: 40.524864, lon: -104.995759 },
  ]

  const mspStops = [
    { id: "MSP", name: "MSP Airport", lat: 44.880574, lon: -93.204905 },
    { id: "DLH", name: "Duluth Airport", lat: 46.837888, lon: -92.179436 },
  ]

  const steps = [
    {
      icon: "https://content.landline.com/wp-content/uploads/2023/01/book-1.png",
      title: "Easy Booking",
      description: "Reserve your MSP shuttle or DEN airport transfer online in minutes",
    },
    {
      icon: "https://content.landline.com/wp-content/uploads/2023/01/board-1.png",
      title: "Quick Check-In",
      description: "Fast baggage handling and ID verification at our shuttle terminals",
    },
    {
      icon: "https://content.landline.com/wp-content/uploads/2023/01/rider-min.webp",
      title: "Comfort Ride",
      description:
        "Enjoy free Wi-Fi and spacious seating on all Duluth-MSP and Fort Collins-DIA shuttles",
    },
    {
      icon: "https://content.landline.com/wp-content/uploads/2023/01/connect.png",
      title: "Seamless Transfer",
      description: "Curbside airport arrival with quick baggage retrieval",
    },
  ]

  const routeLinks = {
    "DIA to FNL Shuttle": "/denver",
    "DIA to CSU Shuttle": "/denver",
    "DIA to Harmony Transfer Center Shuttle": "/denver",
    "FNL to DIA Shuttle": "/fort-collins",
    "CSU to DIA Shuttle": "/csu",
    "Harmony Transfer Center to DIA Shuttle": "/fort-collins",
    "MSP to Duluth (DLH) Shuttle": "/duluth",
    "Duluth (DLH) to MSP Shuttle": "/duluth",
  }

  const routes = {
    DEN: [
      "DIA to FNL Shuttle",
      "DIA to CSU Shuttle",
      "DIA to Harmony Transfer Center Shuttle",
      "FNL to DIA Shuttle",
      "CSU to DIA Shuttle",
      "Harmony Transfer Center to DIA Shuttle",
    ],
    MSP: [
      "MSP to Duluth (DLH) Shuttle",
      "Duluth (DLH) to MSP Shuttle",
    ],
  } 

  // Determine route link for each route item.
  const getRouteLink = (route) => {
    return routeLinks[route] || "#"; // Return mapped URL or fallback "#"
  };

  return (
    <BasicPage>
<Seo 
  title="How It Works | Landline" 
  description="Landline's premium airport shuttle connects MSP and DEN with easy, affordable travel. Enjoy free Wi-Fi, A/C, and comfortable seats." 
  canonicalUrl="https://landline.com/how-it-works" 
/>
      <div className="bp">
        <div className="how-it-works">
          <section className="full-width-heading">
            <h1>Ride Landline to Any Airline</h1>
            <a href="/book" className="cta-button">
              Book Now
            </a>
          </section>
          <section className="amenities-section">
            <section className="icon1-section">
              <div className="icon1-grid">
                {[
                  {
                    icon: <MdAirlineSeatReclineExtra />,
                    title: "Premium Amenities",
                    scrollTo: "relax-ride-module",
                  },
                  {
                    icon: <MdDirectionsBus />,
                    title: "Schedules & Routes",
                    scrollTo: "routes-module",
                  },
                  {
                    icon: <MdWork />,
                    title: "Baggage Handling",
                    scrollTo: "baggage-handling-module",
                  },
                  {
                    icon: <MdEventSeat />,
                    title: "Accommodations",
                    scrollTo: "accommodations-module",
                  },
                  { icon: <MdAccountCircle />, title: "Manage Trip", link: "/trips" },
                  {
                    icon: <MdFlight />,
                    title: "Airline Partners",
                    scrollTo: "airline-partners-module",
                  },
                ].map((item, index) => {
                  const handleAction = () => {
                    if (item.link) {
                      window.location.href = item.link
                    } else {
                      const targetElement = document.getElementById(item.scrollTo)
                      if (targetElement) {
                        const headerOffset = 180 // Adjust if your navbar is sticky
                        const elementPosition =
                          targetElement.getBoundingClientRect().top + window.scrollY
                        const offsetPosition = elementPosition - headerOffset

                        window.scrollTo({
                          top: offsetPosition,
                          behavior: "smooth",
                        })
                      } else {
                        console.error(`Element with ID '${item.scrollTo}' not found.`)
                      }
                    }
                  }

                  return (
                    <div
                      key={index}
                      className="icon1-card"
                      onClick={handleAction}
                      role="button"
                      tabIndex={0}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="icon1-wrapper">{item.icon}</div>
                      <h3 className="icon1-title">{item.title}</h3>
                    </div>
                  )
                })}
              </div>
            </section>
            <ModuleTitle title="Premium Amenities" />
            <div id="relax-ride-module"></div>
            <p className="intro-text">
              Landline is the most convenient and cost-effective way to travel to and from DIA &
              MSP, saving you money on gas, tolls, expensive parking fees, vehicle wear and tear,
              and time spent navigating traffic. We take the stress out of your travel experience by
              handling the driving, allowing you to relax and enjoy the following premium onboard
              amenities:
            </p>
            <div className="amenities-grid">
              {amenities.map((amenity, index) => (
                <div key={index} className="amenity-card">
                  <div className="icon1-wrapper">{amenity.icon}</div>
                  <h3 className="amenity-title">{amenity.title}</h3>
                </div>
              ))}
            </div>
          </section>

          <ModuleTitle title="Our Routes" />
          <div id="routes-module"></div>

          <section className="how-routes">
            {/* Tabs */}
            <div className="tabs-wrapper">
              <button
                className={`tab-button ${activeTab === "DEN" ? "active" : ""}`}
                onClick={() => setActiveTab("DEN")}
              >
                From Denver Airport (DIA)
              </button>
              <button
                className={`tab-button ${activeTab === "MSP" ? "active" : ""}`}
                onClick={() => setActiveTab("MSP")}
              >
                From Minneapolis Airport (MSP)
              </button>
            </div>
            {/* Map Container */}
            <div className="map-container">
              <div className="fixed-map">
                {activeTab === "DEN" && <DenverMap stops={denStops} />}
                {activeTab === "MSP" && <MSPMap stops={mspStops} />}
              </div>
              {/* Routes List Card */}
              <div className="routes-list-wrapper">
                <ul className="routes-list">
                  <p className="routes-title">View Schedules</p>
                  {routes[activeTab].map((route, index) => {
                    const link = getRouteLink(route, activeTab)
                    return (
                      <li key={index}>
                        <a href={link} className="route-item">
                          {route}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </section>

          {/* New Sections Below Map */}
          <section className="baggage-handling">
            <ModuleTitle title="Baggage Handling" />
            <div id="baggage-handling-module"></div>

            <p>
              At Landline, we prioritize the safe and efficient handling of your baggage. Our
              friendly agents are here to assist with loading and unloading your bags with care.
              Large carry-ons and checked baggage will be stowed in the cargo compartment, either
              below the vehicle or in the rear. For smaller personal items, overhead bins are
              available for your convenience.
            </p>
            <p>
              We’re proud to offer a hassle-free baggage policy with no additional fees. Each
              passenger is allowed one checked bag, one carry-on item, and a small personal item.
              Any extra baggage is subject to space availability. For checked items, please ensure
              that the combined dimensions (length, width, and height) do not exceed 90 inches, and
              that the weight is under 50 lbs.
            </p>
          </section>

          <section className="accommodations">
            <ModuleTitle title="Accommodations" />
            <div id="accommodations-module"></div>

            <p>
              At Landline, we offer special travel assistance to ensure a comfortable journey. For
              any special service needs, please contact our customer service team at least 48 hours
              prior to departure by calling 1-888-428-1149 or emailing info@landline.com.
            </p>
            <div className="amenities-grid two-columns">
              <div className="amenity-card">
                <div
                  className="icon1-wrapper large-icon"
                  style={{ fontSize: "3rem", maxWidth: "100px" }}
                >
                  <FaWheelchair />
                </div>
                <h3 className="amenity-title">ADA Accessible</h3>
                <p>
                  All Landline motorcoach vehicles are handicap accessible and have a motorized
                  wheelchair lift and accessible seating available. Smaller shuttles may or may not
                  be wheelchair accessible. Please contact our team to request an ADA accessible
                  vehicle.
                </p>
              </div>
              <div className="amenity-card">
                <div
                  className="icon1-wrapper large-icon"
                  style={{ fontSize: "3rem", maxWidth: "100px" }}
                >
                  <FaPaw />
                </div>
                <h3 className="amenity-title">Service Animals & Pets</h3>
                <p>
                  Furry friends are allowed to travel with Landline free of charge. All pets must be
                  in a TSA approved carrier at all times and must be able to fit at your feet on the
                  vehicle. No exotic animals are allowed on Landline at any time.
                </p>
              </div>
            </div>
          </section>

          <section className="manage-your-trip">
            <ModuleTitle title="Manage Your Trip" />
            <p>
              Create/sign into your FREE Landline loyalty account before booking. Every completed
              trip will help you earn rewards, free rides, and discounts on Landline. Learn more
              about our Penguin Perks loyalty program here.
            </p>
            <p>
              To manage your reservation or make changes to an upcoming trip, navigate to the{" "}
              <a className="mytripslink" href="/trips">
                My Trips
              </a>{" "}
              tab on the home page. You will need your confirmation number and your last name to
              view your reservation.
            </p>
          </section>

          <section className="airline-partners">
            <ModuleTitle title="Airline Partners" />
            <div id="airline-partners-module"></div>

            <p>
              We partner with Air Canada, American Airlines, United Airlines, and Sun Country
              Airlines to provide seamless connectivity to hundreds of destinations around the
              world.
            </p>
            <p>
              By combining your air and ground transportation services into one itinerary, you can
              save money, earn airline miles and rewards more quickly, and check your luggage
              through to your final destination with Landline.
            </p>
          </section>
          <section className="airline-logos">
        <div className="airline-grid">
          {[
            { logo: American, name: "American Airlines", url: "https://www.aa.com/i18n/travel-info/experience/landline.jsp" },
            { logo: AirCanada, name: "Air Canada", url: "https://www.aircanada.com/ca/en/aco/home/book/routes-and-partners/landline.html" },
            { logo: United, name: "United Airlines", url: "https://www.united.com/en-us/flights-from-fort-collins" },
            { logo: SunCountry, name: "Sun Country Airlines", url: "https://suncountry.com/landline" }
          ].map((airline, index) => (
            <a key={index} href={airline.url} target="_blank" rel="noopener noreferrer" className="airline-card">
              <div className="airline-logo-wrapper">
                <img src={airline.logo} alt={airline.name} className="airline-logo" style={{ maxWidth: "180px", maxHeight: "40px" }} />
                <MdOpenInNew className="external-link-icon" />
              </div>
            </a>
          ))}
        </div>
      </section>
          <section className="faq1-section">
            <div className="faq1-content">
              <h2>
                Have questions? <a href="/faq">Explore our FAQ</a>
              </h2>
              <p>
                Contact our team at <a href="tel:888-428-1149">888-428-1149</a> via text or chat
                with us!
              </p>
            </div>
          </section>
        </div>
      </div>
    </BasicPage>
  )
}

export default HowItWorksPage
