import React from "react"
import SchedulePage from ".."
import ScheduleHeader from "../Header"
import OurService from "../OurService"
import LocationService from "../Service"
import ModuleTitle from "../../../atoms/SchedulePages/ModuleTitle"
import { PickupLocations, PickupLocation } from "../Locations"
import { Schedule, ScheduleTable } from "../Schedule.jsx"
import Search from "../../HomePage/Search"

import Pickup_Locations from "./pickup-locations"
import { FortCollinsMeta, FortCollinsService, DropOffData } from "./metadata"
import VisitingFortCollins from "./Visiting"
import FortCollinsData from "./schedule"
import Seo from "../../../molecules/Seo"

import { MdAirlineSeatReclineExtra } from "@react-icons/all-files/md/MdAirlineSeatReclineExtra"
import { TbCalendarTime, TbBusStop } from "react-icons/tb"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaHotel } from "react-icons/fa"

import "./fortCollins.scss"

const FortCollinsSchedule = ({ history }) => {
  return (
    <SchedulePage>
      <Seo
        title={FortCollinsMeta.seo.title}
        description={FortCollinsMeta.seo.description}
        canonicalUrl={"https://landline.com/fort-collins"}
      />
      <section className="full-width-heading schedule-page-banner">
        <h1>Fort Collins to Denver (DIA) Airport Shuttle</h1>
        <span>Fort Collins | Loveland | Timnath | Wellington | Windsor</span>
      </section>
      <div className="schedule-search-form">
        <Search
          history={history}
          remoteCallSearchComponent={true}
          containerClassName="schedule-page-search-bg"
        />
      </div>
      <div className="schedule-page">
        <ScheduleHeader metadata={FortCollinsMeta.header} />

        <section className="icon1-section">
          <div className="icon1-grid fort-collis-icon-section">
            {[
              {
                icon: <TbCalendarTime />,
                title: "Schedules",
                scrollTo: "schedules-module",
              },
              {
                icon: <FaMapMarkerAlt />,
                title: "Pick-Up Locations",
                scrollTo: "pick-up-locations-module",
              },
              {
                icon: <FaMapMarkerAlt />,
                title: "Drop Off Location",
                scrollTo: "drop-off-location-module",
              },
              {
                icon: <MdAirlineSeatReclineExtra />,
                title: "Our Service",
                scrollTo: "our-service-module",
              },
              {
                icon: <FaHotel />,
                title: "Visiting Fort Collins",
                scrollTo: "visiting-fort-collins-module",
              },
            ].map((item, index) => {
              const handleAction = () => {
                const targetElement = document.getElementById(item.scrollTo)
                if (targetElement) {
                  const headerOffset = 180 // Adjust if your navbar is sticky
                  const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY
                  const offsetPosition = elementPosition - headerOffset

                  window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                  })
                } else {
                  console.error(`Element with ID '${item.scrollTo}' not found.`)
                }
              }

              return (
                <div
                  key={index}
                  className="icon1-card"
                  onClick={handleAction}
                  role="button"
                  tabIndex={0}
                  style={{ cursor: "pointer" }}
                >
                  <div className="icon1-wrapper">{item.icon}</div>
                  <h3 className="icon1-title">{item.title}</h3>
                </div>
              )
            })}
          </div>
        </section>

        <ModuleTitle title="Schedules" />
        <div id="schedules-module"></div>
        <Schedule>
          <ScheduleTable schedule={FortCollinsData.to} />
          <ScheduleTable schedule={FortCollinsData.from} />
        </Schedule>

        <PickupLocations>
          <div id="pick-up-locations-module"></div>
          {Pickup_Locations.map(location => {
            return <PickupLocation location={location} />
          })}
        </PickupLocations>

        <section>
          <ModuleTitle title="Drop Off Location" />
          <div id="drop-off-location-module"></div>
          <LocationService service={DropOffData} />
        </section>

        <section>
          <ModuleTitle title="Our Service" />
          <div id="our-service-module"></div>
          <OurService service={FortCollinsService} />
        </section>

        <section>
          <ModuleTitle title="Visiting Fort Collins" />
          <div id="visiting-fort-collins-module"></div>
          <VisitingFortCollins />
        </section>
      </div>
    </SchedulePage>
  )
}

export default FortCollinsSchedule
