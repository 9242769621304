import "./PenguinPerks.scss"
import "../SchedulePages/SchedulePage.mobile.scss"

import React from "react"
import { useHistory } from "react-router-dom"

import SchedulePage from "../SchedulePages"
import ScheduleHeader from "../SchedulePages/Header"
import { PenguinPerksMeta, HowItWorks, RiderTiers, FAQ } from "./metadata"
import OurService from "../SchedulePages/OurService"
import { FargoService } from "../SchedulePages/Fargo/metadata"
import ModuleTitle from "../../atoms/SchedulePages/ModuleTitle"

import Seo from "../../molecules/Seo"
import { ACCOUNT_REGISTER } from "../../../constants/routes"


const PenguinPerksPage = () => {

  const history = useHistory()

  const goToAccountCreation = () => {
    history.push(ACCOUNT_REGISTER)
  }

  return (
    <SchedulePage>
      <Seo title={PenguinPerksMeta.seo.title} description={PenguinPerksMeta.seo.description} />
      <div className="schedule-page">
        <ScheduleHeader metadata={PenguinPerksMeta.header} />
        <div className="penguin-perks-action">
          <button className="penguin-perks-signup" onClick={goToAccountCreation}>Sign Up Now!</button>
        </div>

        <ModuleTitle title={HowItWorks.headline} />
        <HowItWorksPanel metadata={HowItWorks} />

        <ModuleTitle title={RiderTiers.headline} />
        <RiderTiersPanel metadata={RiderTiers} />
        <ModuleTitle title="FAQ" />
        < FAQPanel metadata={FAQ} />
        <OurService service={FargoService} />
      </div>
    </SchedulePage>
  )
}


const HowItWorksPanel = ({ metadata }) => {
  return (
    <div className="penguin-perks-panel">
      {metadata.elements.map((element) => {
        return (
          <div className="how-it-works-element">
            <h4>{element.title}</h4>
            <img src={element.image} alt="" />
            <p>{element.description}</p>
          </div>
        )
      })}
    </div>
  )
}

const RiderTiersPanel = ({ metadata }) => {
  return (
    <div className="penguin-perks-panel">
      {metadata.tiers.map((element) => {
        return (
          <div className="rider-tiers-element">
            <img src={element.image} alt="" />
            <h4>{element.threshold}</h4>
            <ul>
              {element.benefits.map((benefit) => {
                return (
                  <li>{benefit}</li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

const FAQPanel = ({ metadata }) => {
  return (
    <div className="penguin-faq-panel">
      {metadata.map((item) => {
        return (
          <div className="penguin-faq-question">
            <h4>{item.q}</h4>
            <p>{item.a}</p>
          </div>
        )
      })}
    </div>
  )
}

export {
  PenguinPerksPage,
  HowItWorksPanel
}
