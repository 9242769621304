import React, { useState } from "react"
import SchedulePage from ".."
import ScheduleHeader from "../Header"
import OurService from "../OurService"
import ModuleTitle from "../../../atoms/SchedulePages/ModuleTitle"
import { PickupLocations, PickupLocation } from "../Locations"
import { Schedule, ScheduleTable, ScheduleWeekSelector } from "../Schedule.jsx"
import { generateWeeks, generateItineraryData, locations } from "../schedule"
import Pickup_Locations from "./pickup-locations"
import { DuluthMeta, DuluthParking, DuluthService } from "./metadata"
import Parking from "../Parking"
import Seo from "../../../molecules/Seo"

// Generate all weeks for Duluth
const weeks = generateWeeks(locations.minneapolisDuluth)

// Helper function to get the current week based on today's date
const getCurrentWeek = weeks => {
  const today = new Date()
  return (
    weeks.find(week => new Date(week.start) <= today && today <= new Date(week.end)) || weeks[0]
  )
}

const defaultWeek = getCurrentWeek(weeks)

const DuluthSchedule = ({ history }) => {
  const [selectedWeek, setSelectedWeek] = useState(() => defaultWeek)
  const [schedule, setSchedule] = useState(() =>
    generateItineraryData(locations.minneapolisDuluth, defaultWeek),
  )

  const handleWeekChange = week => {
    setSelectedWeek(week)
    setSchedule(generateItineraryData(locations.minneapolisDuluth, week))
  }

  return (
    <SchedulePage>
      <Seo
        title={DuluthMeta.seo.title}
        description={DuluthMeta.seo.description}
        canonicalUrl={"https://landline.com/duluth"}
      />
      {/* <div className="schedule-search-form">
        <Search history={history} remoteCallSearchComponent={true} />
      </div> */}
      <div className="schedule-page">
        <ScheduleHeader metadata={DuluthMeta.header} />

        <ModuleTitle title="Schedule" />

        <ScheduleWeekSelector
          weeks={weeks}
          selectedWeek={selectedWeek}
          handleWeekChange={handleWeekChange}
        />
        <Schedule>
          <ScheduleTable schedule={schedule.from} />
          <ScheduleTable schedule={schedule.to} />
        </Schedule>

        <PickupLocations>
          {Pickup_Locations.map(location => {
            return <PickupLocation key={location.id} location={location} />
          })}
        </PickupLocations>

        <Parking data={DuluthParking} />

        <OurService service={DuluthService} />
      </div>
    </SchedulePage>
  )
}

export default DuluthSchedule
