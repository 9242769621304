import React from "react";
import "./FAQ.scss";
import BasicPage from "../BasicPage";
import Seo from "../../molecules/Seo";

const FAQPage = () => {
  const metadata = {
    title: "Riding with us in Minnesota or Colorado?",
    subtitle: "You've got questions. We have answers.",
  };

  const FAQs = [
    {
      title: "General Questions",
      questions: [
        {
          question: "Where does Landline operate?",
          answer: (
            <>
              <p>
                • Denver International Airport (DEN) to/from Northern Colorado<br />
                • Minneapolis International Airport (MSP) to/from Duluth, MN.<br />
                • We also provide service in partnership with American Airlines, United Airlines, Sun Country Airlines, and Air Canada. For more information visit our partner pages.
              </p>
            </>
          ),
        },
        {
          question: "Can I use Landline if I'm not actually flying with an airline?",
          answer: <p>Yes absolutely. You do not need an airline ticket to ride with Landline.</p>,
        },
        {
          question: "How does this service work if I booked Landline service through the airline?",
          answer: (
            <p>
              For more information, please visit our individual partner pages:<br />
              <a href="https://suncountry.com/landline" target="_blank" rel="noopener noreferrer">Sun Country</a><br />
              <a href="https://www.united.com/en-us/landline" target="_blank" rel="noopener noreferrer">United Airlines</a><br />
              <a href="https://www.aa.com/i18n/travel-info/experience/landline.jsp" target="_blank" rel="noopener noreferrer">American Airlines</a><br />
              <a href="https://www.aircanada.com/ca/en/aco/home/book/routes-and-partners/landline.html" target="_blank" rel="noopener noreferrer">Air Canada</a>
            </p>
          ),
        },
        {
          question: "Can Landline accommodate special travel needs?",
          answer: (
            <p>
              Yes, we are able to provide special travel assistance. This assistance includes steadying a customer or providing a helping hand as the customer ascends or descends the stairs, assistance in getting to or from the seat, and assistance with loading and retrieving carry-on items and other assistive devices stowed on board. Employees are not permitted, however, to lift or carry customers on board the vehicle or assist with medical services.<br /><br />
              As the safest seating arrangement for each customer may vary with the situation and with the vehicle type, Landline may offer to pre-board customers who require special accommodations, or may suggest boarding the customer last for access to the most accessible seat. Some vehicles are equipped with ADA compliant lifts and seating.<br /><br />
              When making your booking, simply select "Wheelchair Assistance" prior to checkout. In addition, to accommodate each passenger's requests, we ask any special service requests be made by contacting our customer service team at least 48 hours in advance by calling <a href="tel:1-888-428-1149">888-428-1149</a> or emailing <a href="mailto:info@landline.com">info@landline.com</a> with details of the request.
            </p>
          ),
        },
        {
          question: "Are there any baggage fees or restrictions?",
          answer: (
            <p>
              Landline does not have any baggage fees, and each passenger is initially allowed:<br />
              • One checked bag<br />
              • One carry-on item<br />
              • One small personal item<br /><br />
              Additional baggage is subject to space availability. Restrictions:<br />
              • Maximum dimensions: 90 inches in overall length, width and height<br />
              • Maximum weight: 50 lbs<br /><br />
              If your bag exceeds these limits, please contact us at <a href="tel:888-428-1149">888-428-1149</a> to see if we are able to accommodate your oversized luggage request.
            </p>
          ),
        },
        {
          question: "Can I bring weapons?",
          answer: (
            <p>
              All passengers are prohibited from carrying weapons on the bus or in carry-on baggage. Federal or local law enforcement officers are subject to exemptions.<br /><br />
              Firearms may be transported in checked baggage if:<br />
              • Unloaded<br />
              • In a locked hard-sided case<br />
              • Reported to the Landline representative at check-in
            </p>
          ),
        },
        {
          question: "Do you allow pets to travel with Landline?",
          answer: (
            <p>
              Yes, your furry friends are allowed to travel with Landline free of charge. Requirements:<br />
              • Must be in a TSA approved carrier at all times<br />
              • Must fit at your feet on the vehicle<br />
              • No animals in the cargo compartment<br />
              • No exotic animals allowed<br /><br />
              Service animals are always welcome on our vehicles but must not block the aisle for passenger safety.
            </p>
          ),
        },
        {
          question: "Does Landline require car seats for small children?",
          answer: (
            <p>
              We are a commercial vehicle and do not require car seats for small children, however passengers are welcome to bring, and install their own car seats on our vehicles. Please note that our seats are not LATCH compatible, but car seats may be installed using the seat belt path.
            </p>
          ),
        },
        {
          question: "What are the age requirements for a young person traveling alone?",
          answer: (
            <p>
              • In CO, someone as young as 15 can travel alone on our service<br />
              • In MN, someone as young as 14 can travel alone on our service<br /><br />
              All minors traveling alone will be required to have a government issued photo ID/passport. School ID's will not be accepted.
            </p>
          ),
        },
        {
          question: "Do I need to check-in prior to boarding? Will I receive a boarding pass?",
          answer: (
            <p>
              After booking, you will receive an email confirmation. We do not require passengers to check in online in advance, or issue boarding passes. All you need is your government issued photo ID at the time of departure. Landline agents will check your ID against our manifest. Please arrive early to facilitate an on-time departure.
            </p>
          ),
        },
        {
          question: "What time should I arrive prior to departure for my Landline trip?",
          answer: (
            <p>
              • If you booked with one of our airline partners and have luggage to check: arrive no later than 25 minutes prior to departure<br />
              • For all other Landline passengers: we may begin boarding up to 15 minutes prior to departure<br /><br />
              Give yourself enough time to park and/or make your way to the boarding area before boarding begins.
            </p>
          ),
        },
        {
          question: "Do you have short to long term parking available for Landline passengers? If so, what are the parking rates?",
          answer: (
            <p>
              Parking rates vary by location:<br /><br />
              • Duluth International Airport (DLH) - <a href="https://duluthairport.com/before-you-arrive/parking/rates/" target="_blank" rel="noopener noreferrer">See parking rates</a><br />
              • Northern Colorado Regional Airport (FNL) - Free for up to 3 weeks in the main terminal and overflow lots. Contact FNL Airport to <a href="https://www.flynoco.com/directions-parking/">purchase a parking pass</a> if you exceed 3 weeks<br />
              • Harmony Transfer Center (HTC) - Only offers short term 24hr parking to passengers<br />
              • CSU Transit Center (CSU) - No parking allowed on the CSU campus unless you have a school permit
            </p>
          ),
        },
        {
          question: "Can I cancel or change my reservation?",
          answer: (
            <p>
              If you booked directly with Landline while logged into your account:<br />
              • Edit or cancel your trip prior to departure through <a href="/trips">My Trips</a> on our website<br />
              • Or contact us at <a href="tel:1-888-428-1149">888-428-1149</a><br />
              • Only Landline Penguin Perks tiered members receive refunds for cancelled legs<br />
              • All other passengers receive credit for cancelled legs<br /><br />
              For airline partner bookings, contact the airline directly:<br />
              • United Airlines: <a href="tel:800-864-8331">(800) 864-8331</a><br />
              • American Airlines: <a href="tel:800-433-7300">(800) 433-7300</a><br />
              • Sun Country Airlines: <a href="tel:651-905-2737">(651) 905-2737</a><br />
              • Air Canada Airlines: <a href="tel:888-247-2262">(888) 247-2262</a><br /><br />
              For additional assistance: Text/call <a href="tel:1-888-428-1148">888-428-1148</a> or email <a href="mailto:info@landline.com">info@landline.com</a>
            </p>
          ),
        },
        {
          question: "If I cancel my Landline reservation, will I get a refund?",
          answer: (
            <p>
              • Penguin Perks loyalty members who were logged into their account when booking, will receive a refund for all cancelled trips<br />
              • All other passengers will receive a credit for the amount paid that can be used on a future Landline trip as long as the trip was cancelled prior to the time of departure<br />
              • Any bookings cancelled after departure are not eligible for refunds or credits
            </p>
          ),
        },
        {
          question: "How much time should I give myself at the airport?",
          answer: (
            <p>
              That is personal preference, but you can use <a href="reserve">TSA Reserve</a> to schedule your TSA clearance in DEN or MSP if you are worried about connection times.
            </p>
          ),
        },
        {
          question: "Can I get an itemized receipt?",
          answer: (
            <p>
              Contact us at <a href="mailto:info@landline.com">info@landline.com</a>
            </p>
          ),
        },
        {
          question: "What if my flight is delayed?",
          answer: (
            <p>
              Reach out to a member of our team, if we have space available, we will move you to the next available departure at no extra cost.
            </p>
          ),
        },
        {
          question: "Where does Landline pick up?",
          answer: (
            <p>
              For specific pick up locations and directions, please view our <a href="/locations">schedule pages</a>. If you have additional questions, contact our team by texting or calling <a href="tel:888-428-1149">(888)-428-1149</a>
            </p>
          ),
        },
        {
          question: "Does Landline make additional stops along the way?",
          answer: (
            <p>
              If you are being picked up at the CSU Transit Center or Harmony Transfer Center, Landline will make an additional stop at the FNL Airport. All other locations are non-stop.
            </p>
          ),
        },
        {
          question: "How do I use Landline store credit?",
          answer: (
            <p>
              To use your Landline store credit:<br /><br />
              1. Book a new trip on our website while logged into your Landline account<br />
              2. Navigate to the checkout/fee summary page after entering passenger details<br />
              3. Look for the "Store Credit" box underneath the "Promo Code" field<br />
              4. Enter the amount of store credit you would like to use<br />
              5. Click "Apply Store Credit"<br /><br />
              This will deduct the amount from your store credit balance and the fare cost. If the store credit fully covers the trip cost, no credit card information will be needed.
            </p>
          ),
        },
      ],
      
    },
    {
      title: "About Colorado Service",
      questions: [
        {
          question: "Where is the Landline pick-up/drop off location at Denver International Airport (DEN)?",
          answer: (
            <div>
              <p>
                We drop off and pick up passengers on level 5, the West side of the terminal, outside of door 504, immediately curbside. We do not pick up at an island.
              </p>
              <p>
                For passengers who booked Landline service through United Airlines, we pick up at Gate B83 which will be reflected on your boarding pass. Your checked luggage will be transferred on your behalf to the Landline vehicle and can be collected at Northern Colorado Regional Airport (FNL).
              </p>
            </div>
          ),
        },
        {
          question: "Which side of the airport does my airline depart from at DEN?",
          answer: (
            <div>
              <p>
                <strong>West Terminal:</strong><br />
                Aeromexico, Air Canada, Allegiant, Breeze Airways, Cayman Airways, Copa Airlines, Delta Airlines, Edelweiss, Icelandair, Lufthansa, Sun Country Airlines, Turkish Airlines, United Airlines, and West Jet.
              </p>
              <p>
                <strong>East Terminal:</strong><br />
                Aer Lingus, Air France, Alaska Airlines, American Airlines, British Airways, Contour Airlines, Denver Air Connection, Frontier, JetBlue Airways, Southern Airways Express, Southwest Airlines, Viva Aerobus, and Volaris.
              </p>
              <p>
                This information is subject to change, please visit <a href="https://flydenver.com" target="_blank" rel="noopener noreferrer">flydenver.com</a> for the most up to date information.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: "About Minnesota Service",
      questions: [
        {
          question: "Where is the Landline pick-up/drop off location at Minneapolis St. Paul International Airport (MSP)? Does Landline service both terminals?",
          answer: (
            <div>
              <p>
                Yes, Landline provides service to both Terminal 1 (Lindbergh) and Terminal 2 (Humphrey). Landline does not have a counter or staff at MSP airport. If you have any questions please reach out to our customer service team by texting or calling <a href="tel:1-888-428-1149">888-428-1149</a>.
              </p>
              <p>
                <strong>Terminal 1:</strong><br />
                We pick up/drop off on the first level of the Silver Parking Ramp Building, which can be found by following signs for "buses" and/or "rental cars". Our pick up is just outside the doors near the Ace Rental Car counter. Our driver will come in to announce departure.
              </p>
              <p>
                <strong>Terminal 2:</strong><br />
                We pick up/drop off at the first floor of the Purple Parking Ramp Ground Transportation Center which can be found across the street from Sun Country baggage claim. Please wait just inside the double doors. Our driver will come in to announce departure.
              </p>
            </div>
          ),
        },
        {
          question: "Which terminal does my airline depart from in MSP?",
          answer: (
            <div>
              <p>
                <strong>Terminal 1:</strong><br />
                Aer Lingus, Air Canada, Air Choice One, Air France, Alaska Airlines, American Airlines, Boutique Air, Delta Airlines, Denver Air, KLM Royal Dutch Airlines, Lufthansa, Spirit Airlines, United Airlines and WestJet
              </p>
              <p>
                <strong>Terminal 2:</strong><br />
                Allegiant, Condor, Frontier Airlines, Icelandair, Southwest Airlines, and Sun Country Airlines.
              </p>
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <BasicPage>
      <Seo 
          title="FAQs | Landline" 
          description="Find answers to common questions about Landline's airport shuttle services in Minnesota, Colorado, and beyond. Learn about routes, baggage policies, special accommodations, and more." 
          canonicalUrl="https://landline.com/faq" 
        />

      <div className="faq-page">
        <div className="faq-header">
          <h2>{metadata.title}</h2>
          <h5>{metadata.subtitle}</h5>
        </div>
       <div className="faq-sections">
            {FAQs.map((section, index) => (
              <div key={index} className="faq-section" id={section.title.toLowerCase().replace(/\s+/g, "-")}>
                <h3>{section.title}</h3>
                <a href="#top" className="faq-back">Back to Top</a>
                {section.questions.map((item, qIndex) => (
                  <div key={qIndex} className="faq-question">
                    <h4>{item.question}</h4>
                    <div className="faq-answer">{item.answer}</div>
                  </div>
                ))}
              </div>
            ))}
</div>

      </div>
    </BasicPage>
  );
};

export default FAQPage;