import React, { useState } from "react"
import SchedulePage from ".."
import ScheduleHeader from "../Header"
import OurService from "../OurService"
import ModuleTitle from "../../../atoms/SchedulePages/ModuleTitle"
import { ScheduleLocation } from "../Locations"
import { Schedule, ScheduleTable, ScheduleWeekSelector } from "../Schedule.jsx"
import "./styles.scss"

import { DuluthLocation } from "./locations"
import { MSPLocationService, MSPMeta, MSPService } from "./metadata"
import LocationService from "../Service"
import { generateWeeks, generateItineraryData, locations } from "../schedule"
import Seo from "../../../molecules/Seo"

// Generate week arrays for each location
const duluth = generateWeeks(locations.minneapolisDuluth)
const fargo = generateWeeks(locations.minneapolisFargo)

// Helper function to determine the current week based on today's date
const getCurrentWeek = weeks => {
  const today = new Date()
  return (
    weeks.find(week => new Date(week.start) <= today && today <= new Date(week.end)) || weeks[0]
  )
}

// Use the helper function to get the default/current week for each location
const defaultDuluthWeek = getCurrentWeek(duluth)
const defaultFargoWeek = getCurrentWeek(fargo)

const MSPSchedule = ({ history }) => {
  const [duluthWeek, setDuluthWeek] = useState(defaultDuluthWeek)

  const [duluthSchedule, setDuluthSchedule] = useState(() =>
    generateItineraryData(locations.minneapolisDuluth, defaultDuluthWeek),
  )

  const handleDuluthWeekChange = week => {
    setDuluthWeek(week)
    setDuluthSchedule(generateItineraryData(locations.minneapolisDuluth, week))
  }

  const goToDuluth = () => {
    history.push("/duluth")
  }


  return (
    <SchedulePage>
      <Seo
        title={MSPMeta.seo.title}
        description={MSPMeta.seo.description}
        canonicalUrl={"https://landline.com/msp"}
      />
      {/* <div className="schedule-search-form">
        <Search history={history} remoteCallSearchComponent={true} />
      </div> */}
      <div className="schedule-page">
        <ScheduleHeader metadata={MSPMeta.header} />

        <ModuleTitle title="Duluth (DLH)" />
        <ScheduleLocation data={DuluthLocation} onButtonClick={goToDuluth} />
        <ScheduleWeekSelector
          weeks={duluth}
          selectedWeek={duluthWeek}
          handleWeekChange={handleDuluthWeekChange}
        />
        <Schedule>
          <ScheduleTable schedule={duluthSchedule.to} />
          <ScheduleTable schedule={duluthSchedule.from} />
        </Schedule>

        <OurService service={MSPService} />

        <LocationService service={MSPLocationService} />
      </div>
    </SchedulePage>
  )
}

export default MSPSchedule
