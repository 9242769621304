import * as React from "react"
import * as styles from "./B2BHowItWorksIconAndDes.module.scss"

const B2BHowItWorksIconAndDes = ({ icon, description, link }) => {
  return (
    <div className={styles.B2BHowItWorksIconAndDes}>
      <img
        src={icon}
        alt="how it works united"
        className={styles.B2BHowItWorksIcon}
      ></img>
      {link ? (
        <h4 className={styles.B2BHowItWorksDescription}>
          {description} <a href={link.url}> {link.text}</a>
        </h4>
      ) : (
        <h4 className={styles.B2BHowItWorksDescription}>{description}</h4>
      )}
    </div>
  )
}

export default B2BHowItWorksIconAndDes
