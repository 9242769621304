import "./Subscribe.scss"
import React from "react"
import ActiveCampaignForm from "../../forms/ActiveCampaign"

export default ({
  subtitle = "Don't miss out on the latest Landline news and exclusive deals.",
}) => {
  return (
    <div className="subscribe-container">
      <div className="subscribe-inner-container">
        <p>{subtitle}</p>
        <ActiveCampaignForm />
      </div>
    </div>
  )
}
