import React from "react"
import "./FormsPage.desktop.scss"
import "./FormsPage.mobile.scss"
import { NotificationConsumer } from "ibe-components"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"
import { TbTrafficCone } from "react-icons/tb"
import {
  FaCarCrash,
  FaMedkit,
  FaSearch,
  FaRegFileAlt,
  FaUnlockAlt,
  FaKey,
  FaIdBadge,
} from "react-icons/fa"

const sections = [
  {
    title: "Safety Reporting",
    groups: [
      {
        items: [
          {
            id: "sp-111",
            Icon: FaCarCrash,
            title: "SP111",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUOTJPM1BGT0xBVEJDQlkxTDVPSU5HRVk4SSQlQCN0PWcu",
            subtitle: "Incident/Accident",
            isCentered: true,
            coverages: [
              "Accident/Collision",
              "Security Incident",
              "Property Damage",
              "Vehicle Damage",
            ],
          },
        ],
      },
      {
        items: [
          {
            id: "sp-110",
            Icon: FaMedkit,
            title: "SP110",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUOFE4RzNCWFZLMzNJM1hHQ0VCMlg2V000SSQlQCN0PWcu",
            subtitle: "Injury/Medical",
            isCentered: true,
            coverages: [
              "Employee Injury",
              "Passenger Injury",
              "Any on the job injuries or medical events",
            ],
          },
        ],
      },
      {
        items: [
          {
            id: "sp-101a",
            Icon: TbTrafficCone,
            title: "SP101A",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUQzc4VUEzOTM2UVVLUVlaRzhUSkFaMkZMSiQlQCN0PWcu",
            subtitle: "Safety Concern",
            isCentered: true,
            coverages: [
              "Unsafe Activity",
              "Unsafe Procedure",
              "Unsafe Conditions",
              "Unattended Luggage",
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Safety Assessments",
    groups: [
      {
        items: [
          {
            id: "sp-201a",
            Icon: FaSearch,
            title: "SP201A",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUMkY0VTY2R0tMSExXTTRGMEc5S0daNjBJQyQlQCN0PWcu",
            subtitle: "Risk Assessment",
            isCentered: true,
          },
        ],
      },
      {
        items: [
          {
            id: "sp-202",
            Icon: FaRegFileAlt,
            title: "SP202",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUQzFPS0dBRUpQOTdCQUdSMFRRRVZCSVJEUSQlQCN0PWcu",
            subtitle: "Safety Assessment",
            isCentered: true,
          },
        ],
      },
      {
        items: [
          {
            id: "sp-101b",
            Icon: TbTrafficCone,
            title: "SP101B",
            url: "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZO-jRHT_6vdEu92emvwU5aNUQlNYTllUNVBNMFBDSFBNWlVQNlYzWEFPNy4u",
            subtitle: "Safety Concern Response",
            description: "Management Only",
            isCentered: true,
          },
        ],
      },
    ],
  },
  {
    title: "Required Station Audits",
    groups: [
      {
        items: [
          {
            id: "safety",
            Icon: FaIdBadge,
            header: "Midwest",
            title: "Safety and Security Audit",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUMUxPRFdWVldHRFRHQzRaOE9UUUlWMDA1QyQlQCN0PWcu",
          },
          {
            id: "gsc",
            Icon: FaIdBadge,
            header: "Midwest",
            title: "GSC Audit",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUOTFJWk5HR1lHSllLNkhSMlVSNFNEWVQwOCQlQCN0PWcu",
          },
          {
            id: "safety",
            Icon: FaIdBadge,
            header: "Canada",
            title: "Safety and Security Audit",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUOTA0TTlDQ0JNNlM2NzAyQU1MWU43QkY0SCQlQCN0PWcu",
          },
        ],
      },
      {
        items: [
          {
            id: "safety",
            Icon: FaIdBadge,
            header: "Colorado",
            title: "Safety and Security Audit",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUNlBWTFlCT0NPWU5BRDE1SUFYRExFQzE3QSQlQCN0PWcu",
          },
          {
            id: "gsc",
            Icon: FaIdBadge,
            header: "Colorado",
            title: "GSC Audit",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUM0JXWklPUFlYQVNQUEI4SUIyRDBTR0JVTCQlQCN0PWcu",
          },
        ],
      },
      {
        items: [
          {
            id: "safety",
            Icon: FaIdBadge,
            header: "Northeast",
            title: "Safety and Security Audit",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJURFVONUpNRjlFRktOQlVWQVkwMUY3UDNKRiQlQCN0PWcu",
          },
          {
            id: "gsc",
            Icon: FaIdBadge,
            header: "Northeast",
            title: "GSC Audit",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUN0IyN0RDMjNaOElMUEswQVgyMU41SUhTSSQlQCN0PWcu",
          },
        ],
      },
    ],
  },
  {
    title: "Secure To Secure Inspection Search Forms",
    groups: [
      { items: [] },
      {
        items: [
          {
            id: "secure-to-secure",
            Icon: FaKey,
            title: "Secure to Secure",
            header: "Northeast OP-302C",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUQVlHRVRNMUQ5RVJVTEJWNkhRNEpIVlNRNCQlQCN0PWcu",
            subtitle: "Inspection Search Form",
            isCentered: true,
          },
        ],
      },
    ],
  },
  {
    title: "Vehicle Security Inspection Search",
    subtitle: "Requires a company account to access",
    groups: [
      { items: [] },
      {
        items: [
          {
            id: "vehicle-search",
            Icon: FaUnlockAlt,
            title: "Vehicle Search",
            header: "Colorado OP-302A",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUMldEUFVFMlVZSVgzTFdUTEdKM0hGM1kxNyQlQCN0PWcu",
            subtitle: "Inspection checklist",
            isCentered: true,
          },
          {
            id: "vehicle-search",
            Icon: FaUnlockAlt,
            title: "Vehicle Search",
            header: "Colorado OP-302B",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUN1JTUUpQVFlCRUJERkYxSUpUNkQ0R0lGSiQlQCN0PWcu",
            subtitle: "Inspection checklist",
            isCentered: true,
          },
          {
            id: "vehicle-search",
            Icon: FaUnlockAlt,
            title: "Vehicle Search",
            header: "Northeast OP-302A",
            url:
              "https://forms.office.com/Pages/ResponsePage.aspx?id=RKDyrHsT2UWMBCbpcCrCZIQ9nE7rhkJPhIMYSpmVftJUMlVVMllSTDRWQllCRVdOSlIzU0hQNkFJMCQlQCN0PWcu",
            subtitle: "Inspection checklist",
            isCentered: true,
          },
        ],
      },
    ],
  },
]

const FormCard = ({ Icon, title, subtitle, coverages, url, isCentered = false }) => (
  <div className="form-card">
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="form-card-header-container"
      style={{ alignItems: isCentered ? "center" : "flex-start" }}
    >
      <div className="form-card-header-div">
        <div className="form-card-header">
          <Icon size={"1.3rem"} />

          <span className="form-card-title">{title}</span>
        </div>

        {subtitle && <div className="form-card-subtitle">{subtitle}</div>}
      </div>
    </a>

    {Boolean(coverages?.length) && (
      <ul className="form-card-list">
        {coverages.map((coverage, index) => (
          <li key={index}>{coverage}</li>
        ))}
      </ul>
    )}
  </div>
)

const FormSection = ({ title, subtitle, groups }) => (
  <section className="forms-section">
    <h3>{title}</h3>
    {subtitle && <h4 className="forms-section-subtitle">{subtitle}</h4>}
    <div className="forms-grid">
      {groups.map((group, index) => (
        <div className="group-item-container" key={`${title} ${index}`}>
          {group.items.map(groupItem => (
            <>
              {groupItem.header && (
                <span className="group-item-header-title">{groupItem.header}</span>
              )}
              <FormCard key={`${groupItem.header} ${groupItem.id}`} {...groupItem} />
            </>
          ))}
        </div>
      ))}
    </div>
  </section>
)

const FormsPageV2 = () => (
  <NotificationConsumer>
    {() => (
      <ErrorBoundary>
        <TabNavbarLayout
          pageLayoutBackground="#F5F7FA"
          render={() => (
            <>
              <div className="forms-page-bus-container">
                <h2 className="forms-page-centered-title" id="landline-forms">
                  Landline Forms
                </h2>

                <div className="forms-page-image-block">
                  <figure className="centered-figure">
                    <img
                      src="https://content.landline.com/wp-content/uploads/2025/02/bus-760x220-1.png"
                      alt="Landline Forms"
                      className="responsive-image"
                    />
                  </figure>
                </div>
              </div>
              <div className="forms-page">
                {sections.map((section, index) => (
                  <>
                    {Boolean(index) && (
                      <div key={`${section.title}: divider`} className="section-divider" />
                    )}
                    <FormSection key={section.title} {...section} />
                  </>
                ))}
              </div>
            </>
          )}
        />
      </ErrorBoundary>
    )}
  </NotificationConsumer>
)

export default FormsPageV2
