const ChartersMeta = {
    header: {
        title: "Relax and Let Landline Drive",
        description: "Landline has a team of travel experts that will help plan your transportation needs. From the very start with a quote to final trip confirmation, a\n Charter Manager will personally ensure your trip is trouble-free. Sit back and relax knowing your journey will be safe, reliable, and affordable."
    }
}

const ChartersQuoteMeta = {
    header: {
        title: "Relax and Let Landline Drive",
        description: "Fill out the information below to request a personalized charter service quote from one of travel managers."
    }
}

const CharterServiceBullets = {
    subtitle: "Who We Serve",
    items: [
        "Sports Teams",
        "Corporate Travel",
        "Tour Groups",
        "Conventions",
        "School Trips",
        "Church Groups",
        "Holiday Parties",
        "Weddings",
    ]
}

const CharterService = {
    headline: "Planning a group trip or event? Let Landline drive.  Whether you’re planning a corporate event, a family reunion, or a school trip, Landline is the perfect way to simplify and ensure a stress-free trip.",
    title: "Locations We Serve",
    description: "Landline Charter service is based in Colorado and New Jersey, however, we offer service across the country! Go where you want, when you want!",
    image: "https://content.landline.com/wp-content/uploads/2024/07/Landline-MC-driver.jpg",
    bullets: CharterServiceBullets
}



const OurFleet = {
    headline: "Our Fleet",
    title: "Travel in Comfort & Style",
    content: "Our fleet and seating capacities vary slightly by region, with premium motor coaches accommodating 35 to 56 passengers. Each coach is equipped with complimentary Wi-Fi and streaming entertainment options, in-seat power outlets, extra legroom, premium leather reclining seats, and an onboard lavatory for your convenience.",
    extra: "Large and over-sized luggage can be stowed underneath in our secure and spacious cargo compartments while smaller items can be placed in the overhead bins inside the cabin. Every Landline motor coach is ADA compliment and is equipped with an automated wheelchair lift.",
    image: "https://content.landline.com/wp-content/uploads/2024/07/46-passenger-MC-inside.jpg"
}

const OurTeam = {
    headline: "Our Team",
    title: "Exceptionally Skilled Staff",
    content: "Landline drivers are highly trained and certified professionals with years of experience. Our friendly staff is there to assist your group with anything they might need including luggage handling, boarding, loading and unloading fragile or over-sized items, and answering any questions you might have along the way. They are experienced in single or multiple day trips and have passed an extensive background check.",
    extra: "Sit back and relax, knowing your safety and comfort is our top priority.",
    image: "https://content.landline.com/wp-content/uploads/2024/07/Flint_Landline__1593.jpg"
}

const CustomerTestimonialImages = [
    "https://content.landline.com/wp-content/uploads/2024/07/Charter-Test-1.jpg",
    "https://content.landline.com/wp-content/uploads/2024/07/Charter-Test-2.jpg",
    "https://content.landline.com/wp-content/uploads/2024/07/Charter-Test-3.jpg"
]

export {
    ChartersMeta,
    OurFleet,
    CharterService,
    CharterServiceBullets,
    OurTeam,
    CustomerTestimonialImages,
    ChartersQuoteMeta
}