import React from "react"

const OurService = ({ service }) => {
  return (
    <div className="schedule-service-container">
      <div className="schedule-service-text">{service}</div>
      <img
        src="https://content.landline.com/wp-content/uploads/2024/05/f5c4061a694a2b3c4734782660d6bc56.jpg"
        alt=""
      />
    </div>
  )
}

export default OurService
