import * as React from "react"
import QuestionAndAnswer from "../AirCanada/questionAndAnswer"
import * as styles from "./Faq.module.scss"

const Faq = ({ items, questionBgColor }) => {
  return (
    <div className={styles.faq}>
      <div className={styles.content}>
        <h1 className={styles.title}>FAQs</h1>
        {items.map(item => {
          return (
            <QuestionAndAnswer
              item={item}
              questionBgColor={questionBgColor}
            ></QuestionAndAnswer>
          )
        })}
      </div>
    </div>
  )
}

export default Faq
