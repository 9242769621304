import React from "react"
import "./OnBoardPage.scss"
import { Socials } from "../../socials/Socials"

const OnBoardPage = () => {
  return (
    <div className="page-onboard">
      <div className="page-onboard-media-block">
        <figure
          className="page-onboard-media-img"
          style={{
            backgroundImage:
              "url(https://content.landline.com/wp-content/uploads/2023/01/aboard.png)",
            backgroundPosition: "50% 50%",
          }}
        ></figure>
        <div className="page-onboard-media-text-content">
          <figure
            className="page-onboard-landline-logo"
            style={{
              backgroundImage:
                "url(https://landline.com/_gatsby/image/1994f75fd38a1faa416db72912156ffd/f68d8f3490114e9b62d70a5ba9ad77d3/landline-logo-blk.jpg?u=https%3A%2F%2Fcontent.landline.com%2Fwp-content%2Fuploads%2F2023%2F01%2Flandline-logo-blk.jpg&a=w%3D579%26h%3D100%26fm%3Djpg%26q%3D90&cd=2023-01-31T23%3A27%3A06)",
            }}
          ></figure>

          <h2 className="page-onboard-title">Welcome Aboard</h2>
          <h4 className="page-onboard-subtitle">Get Connected</h4>
          <p>
            <strong>Welcome aboard!</strong> Enjoy free wi-fi and entertainment during travel. To
            connect to Landline wi-fi, please use the network and password below.
          </p>
          <ul>
            <li>
              <strong>Network:</strong> Landline
            </li>
            <li>
              <strong>Password:</strong> RideLandline
            </li>
          </ul>
          <p>
            To access entertainment, please visit{" "}
            <a href="https://goview.net" target="_blank" rel="noopener noreferrer">
              <strong>GoView.net </strong>↗
            </a>
          </p>
          <h4 className="page-onboard-subtitle">We love feedback!</h4>
          <ul>
            <li>
              <strong>Leave us a Google review!</strong>
            </li>
            <li>
              <a
                href="https://www.surveymonkey.com/r/J53X6NG"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Fill out our quick survey</strong>
              </a>
            </li>
            <li>
              Need more help? Text us at{" "}
              <strong>
                <a href="tel:1-888-428-1149">1-888-428-1149</a>
              </strong>{" "}
              or email us{" "}
              <strong>
                <a href="mailto:info@landline.com">info@landline.com</a>
              </strong>
            </li>
          </ul>
          <h4 className="page-onboard-subtitle">Tell a Friend 🙂</h4>
          <p>
            <strong>Refer your friends and earn free Landline trips</strong>: We appreciate your
            business! For every three new customer referrals, you’ll receive your next Landline trip
            on us. Submit their names or record locators by text or email to our team and we’ll
            create a future booking code for your free trip!
          </p>
        </div>
      </div>
      <h4 className="page-onboard-empty-h4">
        Follow us on social for updates & tag us in your next trip! @ridelandline
      </h4>
      <div className="page-onboard-social-media">
        <Socials />
      </div>
      <p className="page-onboard-bottom-p">
        <strong>Ready to book your next trip? </strong>
        <br />
        Visit{" "}
        <a href="https://landline.com" target="_blank" rel="noopener noreferrer">
          <strong>Landline.com</strong>
        </a>
        .
      </p>
      <p className="page-onboard-bottom-p">
        <strong>Questions? </strong>
        <br />
        Visit our{" "}
        <a href="https://landline.com/faq" target="_blank" rel="noopener noreferrer">
          <strong>FAQ</strong>
        </a>{" "}
        or text us at{" "}
        <a href="tel:1-888-428-1149">
          <strong>1-888-428-1149</strong>
        </a>
        .
      </p>
    </div>
  )
}

export default OnBoardPage
