import * as React from "react"
import * as styles from "./B2BNavigation.module.scss"

const B2BNavigation = ({ data }) => {
    return (
      <div className={styles.B2BNavigation} id="B2BNavigation">
        <div className={styles.B2BNavigationItem}>
          <a href="#B2BHowItWorks">HOW IT WORKS</a>
        </div>
        <div className={styles.B2BNavigationItem}>
          <a href="#B2BWhereToFindUs">WHERE TO FIND US</a>
        </div>
        <div className={styles.B2BNavigationItem}>
          <a href="#">PARKING</a>
        </div>
        <div className={styles.B2BNavigationItem}>
          <a href="#B2BFAQs">FAQ</a>
        </div>
      </div>
    )
  }
  
  export default B2BNavigation