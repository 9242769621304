import lacrossePic from "../LaCrosse/lacrosse.jpg"
import duluthPic from "../Duluth/duluth.jpg"
import fargoPic from "../Fargo/fargo.jpg"



const DuluthLocation = {
    image: duluthPic,
    title: "",
    prices: "Adults: $33 and up | Kids: FREE (12 & under)",
    content: "Landline offers service to the Duluth International Airport (DLH) in Duluth, MN. The pick-up and drop off point is in front of the main lobby entrance. For more information about our shuttle service to DLH including parking information, please view our Duluth page.",
    button: "More about our Duluth service"
}

const FargoLocation = {
    image: fargoPic,
    title: "",
    prices: "Adults: $35 and up",
    content: "Landline offers service to the Hector International Airport (FAR) in Fargo, ND. The pick-up and drop off point is in front of the main terminal entrance. For more information about our shuttle service to FAR including parking information, please view our Fargo page.",
    button: "More about our Fargo service"
}

export {
    DuluthLocation,
    FargoLocation,
}