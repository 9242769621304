import React from "react"

const VisitingFortCollins = () => {
  return (
    <div className="visiting-fort-collins">
      <h4>Where to Stay</h4>
      <b>
        <a
          href="https://reservations.travelclick.com/109713?RatePlanId=7098073"
          target="_blank"
          rel="noreferrer noopener"
        >
          Armstrong Hotel
        </a>
      </b>
      <p>
        Landline customers receive 20% off your hotel when you book using our link. <br />
        The Armstrong is one of the best hotels in Old Town offering close proximity to the best
        restaurants and shops.
      </p>
      <b>
        <a
          href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1711569781434&key=CORP&app=resvlink"
          target="_blank"
          rel="noreferrer noopener"
        >
          Courtyard by Marriott
        </a>
      </b>
      <p>
        Landline customers can receive 15% off your hotel when you book using our link. The
        Courtyard by Marriott is newly renovated and offers premium amenities and a beautiful
        community courtyard.{" "}
      </p>
      <b>
        <a
          href="https://www.theelizabethcolorado.com/?gclid=CjwKCAjwuJ2xBhA3EiwAMVjkVKbNmFi1nMCAFqPsj9dHF3xzQwq_7G83NKc7s8Y7BfvXdJWJ3a56KBoCZrQQAvD_BwE&scid=801407fb-c3ea-4831-b37f-7ab7b94ab9a8&gclsrc=aw.ds&gad_source=1&s_kwcid=AL!4331!3!494699505247!p!!g!!elizabeth%20hotel&ef_id=Ze9h5gAAAJyeqgOY:20240423162037:s"
          target="_blank"
          rel="noreferrer noopener"
        >
          Elizabeth Hotel
        </a>
      </b>
      <p>Stay in the heart of Old Town Fort Collins in this gorgeous music themed hotel.</p>
      <h4>What to Do</h4>
      <b>Brewery Tours</b>
      <p>
        Check out Fort Collin’s microbreweries and schedule a tour to learn how your favorite frosty
        beverage is made.
      </p>
      <b>Horsetooth Resevoir</b>
      <p>
        Whether you enjoy hiking, biking, fishing, camping, or boating an adventure waits for you at
        Fort Collin’s favorite watering hole. Rent a paddleboard or pontoon boat for the day and
        explore everything the 6.5 mile long reservoir has to offer.
      </p>
      <b>Ghost Tours</b>
      <p>
        Visit Old Town’s most haunted locations during this guided tour. You’ll be taken around town
        and even underground exploring old tunnels, hidden rooms, and historic buildings.
      </p>
    </div>
  )
}

export default VisitingFortCollins
