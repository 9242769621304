import * as React from "react";
import "./ContactPage.scss";
import BasicPage from "../BasicPage";
import Subscribe from "../../organisms/Subscribe";
import { FaPhoneAlt } from "react-icons/fa";
import { BsChatLeftTextFill } from "react-icons/bs";
import { IoMdChatbubbles } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaQuestionCircle } from "react-icons/fa";
import ModuleTitle from "../../atoms/SchedulePages/ModuleTitle";
import { Cities } from "../../promotionals/Cities";

const contactInfo = [
  {
    Icon: FaPhoneAlt,
    information: "(888) 428-1149",
    href: "tel:(888) 428-1149",
  },
  {
    Icon: BsChatLeftTextFill,
    information: "(888) 428-1149",
    href: "sms:(888) 428-1149",
  },
  {
    Icon: MdEmail,
    information: "info@landline.com",
    href: "mailto:info@landline.com",
  },
  {
    Icon: IoMdChatbubbles,
    information: "Chat Support",
    href: "#",
    onClick: () => {
      if (window.zE) {
        window.zE("webWidget", "open");
      } else {
        console.error("Zendesk Widget not loaded yet.");
      }
    },
  },
];

const faqInfo = [
  {
    Icon: FaQuestionCircle,
    information: "General FAQ",
    href: "/faq#general-questions",
  },
  {
    Icon: FaQuestionCircle,
    information: "Minnesota FAQ",
    href: "/faq#about-minnesota-service",
  },
  {
    Icon: FaQuestionCircle,
    information: "Colorado FAQ",
    href: "/faq#about-colorado-service",
  },
];

const ContactCard = ({ Icon, information, href, onClick }) => {
  const handleClick = (event) => {
    if (onClick) {
      event.preventDefault(); // Prevents page reload for interactive elements
      onClick();
    }
  };

  return (
    <a href={href || "#"} className="contact-card" onClick={handleClick}>
      <div className="contact-card-icon">
        <Icon size={"1.8rem"} />
      </div>
      <span className="contact-card-title">{information}</span>
    </a>
  );
};

const ContactPage = () => {
  // ✅ Load Zendesk Widget Script on Page Load (Prevents multiple loads)
  React.useEffect(() => {
    if (!document.getElementById("ze-snippet")) {
      const script = document.createElement("script");
      script.src =
        "https://static.zdassets.com/ekr/snippet.js?key=ccfb920f-f898-428e-9791-9d9ca99a2298";
      script.id = "ze-snippet";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <BasicPage>
      <div className="contact-page-container">
        <section className="full-width-heading">
          <h1>Contact Customer Support</h1>
          <a href="/book" className="cta-button">
            Book Now
          </a>
        </section>

        <section className="contact-page-content">
          <ModuleTitle title="Get 24/7 Customer Support" />
          <div className="contact-page-cards-container">
            {contactInfo.map((contact, index) => (
              <ContactCard key={`${contact.information} ${index}`} {...contact} />
            ))}
          </div>

          <ModuleTitle title="Frequently Asked Questions" />
          <div className="contact-page-cards-container faq-cards">
            {faqInfo.map((faq, index) => (
              <ContactCard key={`${faq.information} ${index}`} {...faq} />
            ))}
          </div>

          <ModuleTitle title="Subscribe" />
          <Subscribe subtitle="Keep up to date on Landline news and get exclusive offers and discounts on your favorite airport shuttle." />
        </section>

        <Cities></Cities>
      </div>
    </BasicPage>
  );
};

export default ContactPage;
