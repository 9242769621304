import * as React from "react"
import TopMenu from "../../organisms/TopMenu"
import Footer from "../../organisms/Footer"
import "./PrivacyPolicyPage.scss"

const PrivacyPolicyPage = () => {
  const policy = (
    <div className="wpWrapper wpTop">
      <div className="wpContent">
        <div>
          <h1 className="has-text-color privacy-policy-title" id="privacy-policy">
            Privacy Policy
          </h1>

          <h5 className="has-text-color last-updated-text" id="last-updated-june-9-2020">
            Last updated: June 9, 2020.
          </h5>

          <div className="is-layout-flex wp-container-104 wp-block-columns is-style-default">
            <div className="is-layout-flow wp-block-column">
              <p className="has-text-color has-small-font-size">
                Landline is aware that the security of your private information from the use of our
                website is an important concern. We take the protection of your personal data very
                seriously and demonstrate a strong commitment to online privacy. In acknowledgment
                of that commitment, we have adopted a comprehensive policy to safeguard the privacy
                of all visitors to the Landline website.
              </p>
            </div>
          </div>

          <h2 className="has-text-color" id="collection-of-data">
            Collection of Data
          </h2>

          <div className="is-layout-flex wp-container-106 wp-block-columns">
            <div className="is-layout-flow wp-block-column">
              <p className="wp-elements-121a9a9e072b1defe10493aabfba0479 has-text-color has-link-color has-small-font-size">
                We store data on our servers for various security reasons. This data may include the
                name of your Internet service provider, click sources, the websites that you visit
                from our site and your Internet Protocol (IP) address. This information can be used
                to help diagnose problems with our server, to administer our website, to help
                identify you and to gather broad demographic information. The data we collect will
                only be used for the purpose of supplying you with the requested products or
                services, company marketing purposes or for other reasons for which you have given
                your consent, except where otherwise provided by law.
              </p>
            </div>
          </div>

          <p className="wp-elements-61ed9b36ab7784527a94017dacf59ee9 has-text-color has-link-color has-small-font-size">
            We use Universal Analytics, Google’s Web tracking tool that provides detailed data about
            website traffic, sources and more. Learn&nbsp;
            <a
              href="http://www.google.com/policies/privacy/partners"
              target="_blank"
              rel="noreferrer noopener"
            >
              how Google uses data when you use our partners’ sites or apps
            </a>
            .
          </p>

          <p className="wp-elements-9841ae4bffa21960793418633151a2f6 has-text-color has-link-color has-small-font-size">
            Universal Analytics uses the DoubleClick cookie to distinguish users from one another
            and gather data on users’ demographics (age, gender) and interests (affinity categories,
            in-market segments, other categories). For more information on the DoubleClick cookie,
            view the&nbsp;
            <a
              href="http://www.google.com/policies/technologies/ads"
              target="_blank"
              rel="noreferrer noopener"
            >
              Google Advertising Policy FAQ
            </a>
            .
          </p>

          <p className="wp-elements-628a36aeca73b5f98cbc6b6dc2effa87 has-text-color has-link-color has-small-font-size">
            We do not (and does not allow third parties to) use Universal Analytics to track,
            collect or upload any data that personally identifies an individual (such as a name,
            email address or billing information), or other data that can be reasonably linked to
            such information by Google. View details on&nbsp;
            <a
              href="http://www.google.com/intl/en/policies/privacy"
              target="_blank"
              rel="noreferrer noopener"
            >
              Google’s Privacy Policy
            </a>
            .
          </p>

          <p className="wp-elements-ea5ee7a9638cd06914a964392b2be2cf has-text-color has-link-color has-small-font-size">
            To prevent your data from being used by Universal Analytics, you may use the&nbsp;
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noreferrer noopener"
            >
              Google Analytics Opt-Out Browser Add-On
            </a>
            . You can also use&nbsp;
            <a href="http://www.google.com/settings/ads" target="_blank" rel="noreferrer noopener">
              Ad Settings
            </a>
            &nbsp;to manage the Google ads you see and opt out of interest-based ads. However, even
            if you opt out of interest-based ads, you may still see ads based on several factors,
            such as your general location derived from your IP address, your browser types and
            recent, previous searches related to your current search.
          </p>

          <h2 className="has-text-color" id="making-a-landline-reservation">
            Making a Landline Reservation
          </h2>

          <p className="has-text-color has-small-font-size">
            In order to make a reservation on Landline.com, we may ask you to provide information
            such as the following:
          </p>

          <ul className="has-text-color has-small-font-size">
            <li>Full Name</li>
            <li>Your Email Address</li>
            <li>Credit Card Information</li>
            <li>Date of Birth</li>
            <li>Gender</li>
            <li>Any Special Assistance Requirements</li>
          </ul>

          <p className="has-text-color has-small-font-size">
            In addition to requesting this information at time of booking, we will also
            automatically collect:
          </p>

          <ul className="has-text-color has-small-font-size">
            <li>
              The type of web browser and operating system you have used to access our Services;
            </li>
            <li>
              The type of device you have used to access our Services, including unique device
              identifiers;
            </li>
            <li>
              Your IP address (which is a number automatically assigned to your computer when you
              access the Internet and can be used to derive your general geographic location);
            </li>
            <li>The websites you visited before and after you visit our Services;</li>
            <li>
              Information about the content you view and the actions you take on the Services;
            </li>
            <li>
              Information about your current location (if you consent to providing us this
              information); and
            </li>
            <li>Information we collect</li>
          </ul>

          <p className="has-text-color has-small-font-size">
            Please note that we may use third-party providers to help us collect some of this
            information. This information is not linked to your browser session ID as stored by
            cookies. For more information on how we use cookies, please see Cookies below.
          </p>

          <h2 className="has-text-color" id="website-cookies">
            Website Cookies
          </h2>

          <p className="has-text-color has-small-font-size">
            This site uses web cookies. Cookies are pieces of data that are sent from our website to
            your browser, which may then store them on your computer system. No personal information
            or credit card details of any kind are stored or gathered by cookies. Cookies collect
            anonymous information to improve your experience on the website and to allow
            landline.com to improve our digital advertising.
          </p>

          <p className="has-text-color has-small-font-size">
            We use cookies to store a unique ID that identifies your browser session. This ID is in
            no way associated with your machine or you individually.
          </p>

          <p className="has-text-color has-small-font-size">
            Without cookies, landline.com would “forget” the items in your basket each time you went
            to a different page. The session cookie is deleted when your browser is closed, removing
            access to any session information which will be removed from our servers a short time
            afterwards.
          </p>

          <h2 className="has-text-color" id="do-not-track-requests">
            Do Not Track Requests
          </h2>

          <p className="has-text-color has-small-font-size">
            Although some browsers allow you to send a signal indicating that you do not wish to be
            “tracked”, our Services do not recognize or respond to “Do Not Track” signals.
          </p>

          <h2 className="has-text-color" id="use-of-information">
            Use of Information
          </h2>

          <p className="has-text-color has-small-font-size">
            The information we collect and receive is used to provide the service you have
            requested; to confirm your reservation or to reply to questions you may ask. It is also
            used to provide you updated information concerning changes to the landline.com service
            you have reserved. Your information will also be available to our customer service
            agents to allow them to assist you with any questions you may have. We will also use
            this information to enable us to deliver a more effective and targeted service to you,
            the passenger. We will use information about your computer equipment and the pages on
            our Services that you visited for statistical evaluation. Finally, you may receive email
            from us concerning promotions and offers from Landline.com and about changes to our
            website. You will be offered the opportunity in those emails to cease receiving them.
          </p>

          <h2 className="has-text-color" id="sharing-of-information">
            Sharing of Information
          </h2>

          <p className="has-text-color has-small-font-size">
            Landline.com does not sell your information or provide it to any third parties except in
            compliance with this Policy.
          </p>

          <p className="has-text-color has-small-font-size">
            All payment transactions on our website are handled by Stripe, a secure payment
            processing provider. Your credit card and related personal details are collected by
            Stripe in order to process your payment. More details regarding Stripe’s terms and
            conditions, and privacy and security policies can be found by visiting{" "}
            <a href="https://stripe.com/us/ssa">https://stripe.com/us/ssa.</a>
          </p>

          <p className="has-text-color has-small-font-size">
            If you accessed our Services from a third party website, we may provide your information
            to that third party for purposes of enabling the third party to collect a commission
            from Landline.com related to your purchase of a reservation on our service.
          </p>

          <p className="has-text-color has-small-font-size">
            We may provide your information to companies that provide services directly to us,
            including our website designers and technical consultants. This information will be used
            by such organizations only to perform their functions and/or to provide the service you
            have requested and for no other purpose.
          </p>

          <p className="has-text-color has-small-font-size">
            If you are an existing customer, Landline may use your email address to provide you with
            information about promotions and services we think may be of interest to you; however,
            you can choose not to receive such notices. If you are simply a visitor to this website
            and not an existing customer of landline.com, we may nonetheless wish to give you the
            opportunity to receive such information and may ask for your consent to do so.
          </p>

          <p className="has-text-color has-small-font-size">
            In the event of the sale or transfer of our company or its assets, information we have
            collected from you or about you may be disclosed to the buyer as part of the sale or
            transfer process and will be transferred to the buyer as one of the transferred assets.
          </p>

          <p className="has-text-color has-small-font-size">
            It is not our policy to provide any further entities or organization(s) with your
            information provided to us via this website, unless we share in response to legal
            process or when necessary to protect our Services.
          </p>

          <h2 className="has-text-color" id="accuracy-of-personal-information">
            Accuracy of Personal Information
          </h2>

          <p className="has-text-color has-small-font-size">
            If the credit card holder for reservations is not you, it will be your responsibility to
            ensure that you have the card holder’s consent before entering any related details, and
            that such details when entered are true. Other than the foregoing, by using our
            Services, you confirm that you will only enter personal information about yourself and
            that such information is true.
          </p>

          <h2 className="has-text-color" id="protection-of-information">
            Protection of Information
          </h2>

          <p className="has-text-color has-small-font-size">
            We use industry standard physical, technical, and administrative measures to safeguard
            information in our possession against loss, theft and unauthorized use, disclosure, or
            modification. Please note, however, that no data transmission or storage can be
            guaranteed to be 100% secure. As a result, while we strive to protect the information we
            maintain, we cannot ensure or warrant the security of any information that you transmit
            to us.
          </p>

          <h2 className="has-text-color" id="children">
            Children
          </h2>

          <p className="has-text-color has-small-font-size">
            Our Services are intended for a general audience. We do not knowingly collect, use, or
            disclose personal information from children under the age of 13 without prior parental
            consent, except as permitted by the Children’s Online Privacy Protection Act. If you are
            a parent who consents to the collection of personal information from your child, you
            agree that your child may use all our Services and that we may collect, use, and
            disclose your child’s personal information consistent with this Privacy Policy.
          </p>

          <h2 className="has-text-color" id="third-party-website-and-services">
            Third Party Website and Services
          </h2>

          <p className="has-text-color has-small-font-size">
            Our Services may link to websites or other online services operated by third-party
            companies (e.g., websites where you can book hotel accommodations). We are not
            responsible for the privacy practices of these third parties. Once you have left our
            Services, you should check the applicable privacy policy of the third party website or
            service to determine how the third party will handle any information it collects from
            you.
          </p>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <TopMenu />
      {policy}
      <Footer />
    </>
  )
}

export default PrivacyPolicyPage
