import React from "react"
import TopMenu from "../../organisms/TopMenu"
import Footer from "../../organisms/Footer"

function Layout({ children, className = "" }) {
  return (
    <main style={{ overflow: "hidden" }} className={className}>
      <TopMenu />
      {children}
      <Footer />
    </main>
  )
}

export default Layout
