import "../../organisms/LoyaltyProgram/LoyaltyProgram.scss"

import PropTypes from "prop-types"
import React from "react"
import { InputPreview, PageTitle, Button, Icon } from "ibe-components"
import { REFERRAL } from "../../../constants/routes"
import { useHistory } from "react-router-dom"


const StoreCreditsInfo = ({ balance, referrals }) => {

  const history = useHistory()

  const goToReferrals = () => {
      history.push(REFERRAL)
    }

  return (
    <div>
      <PageTitle
        title="Store credits"
        fontSize="20px"
        margin="0 0 20px 0"
        style={{
          height: 40,
          boxSizing: "content-box",
          display: "flex",
          alignItems: "center",
        }}
      />

      <InputPreview title="YOUR STORE CREDITS" value={"$" + balance} />

      {referrals.has_referral && <InputPreview title="YOUR REFERRAL CREDITS" value={"$" + referrals.referral_amount} />}

      <InputPreview title="LIFETIME REFERRAL EARNINGS" value={"$" + referrals.awarded} />

      <div className="loyalty-program-buttons">
        <Button
          type="secondary"
          onClick={goToReferrals}
          fullWidth
          icon={<Icon align="right" name="points" />}
        >
          My Referrals
        </Button>
      </div>
    </div>
  )
}

StoreCreditsInfo.propTypes = {
  balance: PropTypes.number.isRequired,
}

StoreCreditsInfo.defaultProps = {}

export default StoreCreditsInfo
