import * as React from "react"
import AmericanLayout from "./Layout"
import Faq from "./faq"
import HowItWorksIcon from "./howItWorksIcon"
import ticketsIcon from "../../../media/tickets.svg"
import luggageIcon from "../../../media/luggage.svg"
import seatWifiIcon from "../../../media/seat-wifi.svg"
import dollarIcon from "../../../media/dollar.svg"
import * as styles from "./American.module.scss"

const AmericanPage = () => {
  const faqData = [
    {
      q: "1. How is it booked?",
      a: (
        <p>
          Landline service in partnership with American Airlines is booked just like any other
          American Airlines flight. Enter Atlantic City (ACY), Lehigh Valley (ABE), Wilkes-Barre /
          Scranton, Pennsylvania (AVP) or Wilmington, Delaware (ILG) as your origin or destination
          and select an itinerary with Landline.
        </p>
      ),
    },
    {
      q: "2. What is the Cost?",
      a: (
        <p>
          The price of the Landline service is included in your ticket purchased with American
          Airlines.
        </p>
      ),
    },
    {
      q: "3. What is the ride like?",
      a: (
        <p>
          Landline and American have partnered to provide a premium experience for all customers on
          our services. Each Landline segment includes seat assignments, premium seating, and free
          onboard wi-fi.
        </p>
      ),
    },
    {
      q: "4. Where do I catch the Landline?",
      a: (
        <p>
          At Landline, we operate just like an airline. Proceed to your airport as if you are
          catching a flight. Check-in will be at the American Airlines ticket counter, and you will
          be directed to the appropriate boarding area or gate. There is no need to leave the secure
          area of the airport and your baggage is transferred for you.
        </p>
      ),
    },
    {
      q: "5. Can I use this for International Travel?",
      a: <p>Absolutely!</p>,
    },
    {
      q: "6. How can I learn more?",
      a: (
        <p>
          Feel free to reach out to us at 888-428-1149 or info@landline.com and we will be happy to
          answer any additional questions you might have in a timely manner.
        </p>
      ),
    },
  ]

  return (
    <>
      <AmericanLayout headerPhoneNumber="855-480-1160">
        <div className={styles.american}>
          <div className={styles.hero}>
            <div className={styles.heroContent}>
              <h2 className={styles.heroTitle}>Meet Landline</h2>
              <p className={styles.heroText}>
                American Airlines and The Landline Company are bringing new travel options to
                Atlantic City, Lehigh Valley, Scranton, and Wilmington.
              </p>
              <p className={styles.heroText}>
                Enjoy premium seating, checked baggage, and a seamless connection through
                Philadelphia International Airport (PHL) to American Airlines’ global network.
              </p>
              <div className={styles.bookNowBtn}>
                <a href="https://www.aa.com/" className={styles.bookNowLink}>
                  Book Now
                </a>
              </div>
            </div>
            <div className={styles.heroImageWrapper}></div>
          </div>
          <div className={styles.howItWorks}>
            <h3 className={styles.howItWorksTitle}>How it Works</h3>
            <div className={styles.icons}>
              <HowItWorksIcon icon={ticketsIcon} iconClassName="ticketsIcon">
                <p>
                  Book your travel to/from Atlantic City (ACY), Lehigh Valley (ABE), Scranton (AVP),
                  or Wilmington (ILG) to anywhere American flies
                </p>
              </HowItWorksIcon>
              <HowItWorksIcon icon={luggageIcon} iconClassName="luggageIcon">
                <p>
                  Ability to check baggage and have a seamless connection to your American Airlines
                  Flight
                </p>
              </HowItWorksIcon>
              <HowItWorksIcon icon={seatWifiIcon} iconClassName="seatWifiIcon">
                <p>
                  Travel on Landline. Enjoy premium seating, free wi-fi, and a seamless connection
                  at the gate to your flight on American Airlines
                </p>
              </HowItWorksIcon>
              <HowItWorksIcon icon={dollarIcon} iconClassName="dollarIcon">
                <p>Earn your AAdvantage miles</p>
              </HowItWorksIcon>
            </div>
          </div>
          <div className={styles.whereToFindUs}>
            <img
              src="https://content.landline.com/wp-content/uploads/2025/01/where-to-find-us-aa.png"
              alt=""
              srcset=""
            />
            <div className={styles.whereToFindUsContent}>
              <h4 className={styles.whereToFindUsTitle}>Where to find Us: </h4>
              <p className={`${styles.whereToFindUsText} ${styles.whereToFindUsBold}`}>
                Lehigh Valley International Airport
              </p>
              <p className={styles.whereToFindUsText}>3311 Airport Road</p>
              <p className={styles.whereToFindUsText}>Allentown, PA 18109</p>
              <p className={`${styles.whereToFindUsText} ${styles.whereToFindUsBold}`}>
                Atlantic City International Airport
              </p>
              <p className={styles.whereToFindUsText}>101 Atlantic City International Airport</p>
              <p className={styles.whereToFindUsText}>Egg Harbor Township, NJ 08234</p>

              <p className={`${styles.whereToFindUsText} ${styles.whereToFindUsBold}`}>
                Wilkes-Barre / Scranton, Pennsylvania (AVP)
              </p>
              <p className={styles.whereToFindUsText}>100 Terminal Rd, Avoca, PA 18641</p>

              <p className={`${styles.whereToFindUsText} ${styles.whereToFindUsBold}`}>
                Wilmington, Delaware (ILG)
              </p>
              <p className={styles.whereToFindUsText}>151 N Dupont Hwy, New Castle, DE 19720</p>
            </div>
          </div>
          <Faq items={faqData} questionBgColor="blue" />
          <div className={styles.aaLogoWrapper}>
            <a href="https://www.aa.com/">
              <img
                src="https://content.landline.com/wp-content/uploads/2025/01/american-airlines-logo.png"
                className={styles.aaLogo}
                alt=""
                srcset=""
              />
            </a>
          </div>
        </div>
      </AmericanLayout>
    </>
  )
}

export default AmericanPage
