import React from "react"
import propTypes from "prop-types"
import BookNow from "./BookNow"

const ScheduleWeekSelector = ({ weeks, selectedWeek, handleWeekChange }) => {
  const currentIndex = weeks.findIndex(
    week => week.start === selectedWeek.start && week.end === selectedWeek.end,
  )

  const handlePrevious = () => {
    if (currentIndex > 0) {
      handleWeekChange(weeks[currentIndex - 1])
    }
  }

  const handleNext = () => {
    if (currentIndex < weeks.length - 1) {
      handleWeekChange(weeks[currentIndex + 1])
    }
  }

  return (
    <div className="week-selector">
      <button
        onClick={handlePrevious}
        disabled={currentIndex === 0}
        className="nav-button"
        aria-label="Previous week"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 18L9 12L15 6"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      <div className="week-display">
        <span className="week-date">
          Week of{" "}
          {new Date(selectedWeek.start).toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
          })}
        </span>
        <span className="week-count">
          {new Date(selectedWeek.start).toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
          })}{" "}
          to{" "}
          {new Date(selectedWeek.end).toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
          })}
        </span>
      </div>

      <button
        onClick={handleNext}
        disabled={currentIndex === weeks.length - 1}
        className="nav-button"
        aria-label="Next week"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 6L15 12L9 18"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  )
}

const Schedule = props => {
  return (
    <>
      <div className="schedule-page-schedules-container">
        <div className="schedule-page-schedules-inner-container">{props.children}</div>
        <p className="schedules-page-schedules-disclaimer">
          *Schedules are subject to change. The most up-to-date departures/arrivals are available
          when booking.
        </p>
        <BookNow />
      </div>
    </>
  )
}

const ScheduleTable = ({ schedule }) => {
  return (
    <div className="schedule-table-container">
      <table className="schedule-table">
        <tbody>
          <tr>
            <th colSpan={schedule.stops.length} className="schedule-table-title">
              {schedule.title}
            </th>
          </tr>
          <ScheduleHeader stops={schedule.stops} />
          <ScheduleItinerary itinerary={schedule.itinerary} />
        </tbody>
      </table>
    </div>
  )
}

const ScheduleItinerary = ({ itinerary }) => {
  let rotation = 0

  function getClassName() {
    if (rotation == 0) {
      rotation = 1
      return "schedule-itinerary-item white-background"
    } else {
      rotation = 0
      return "schedule-itinerary-item gray-background"
    }
  }

  return (
    <>
      {itinerary.map((item, index) => {
        return (
          <tr className={getClassName()} key={index}>
            {item.map((value, index) => {
              return <td key={index}>{value}</td>
            })}
          </tr>
        )
      })}
    </>
  )
}

const ScheduleHeader = ({ stops }) => {
  return (
    <>
      <tr>
        {stops.map((stop, index) => {
          const column_width = 100 / stops.length + "%"
          return (
            <th className="schedule-table-header" style={{ width: column_width }} key={index}>
              <p className="stop-type">{stop.type}</p>
              <p className="stop-name">{stop.name}</p>
            </th>
          )
        })}
      </tr>
    </>
  )
}

ScheduleTable.propTypes = {
  schedule: propTypes.object,
}

ScheduleTable.defaultProps = {
  schedule: {
    stops: [],
    title: "",
    itinerary: [],
  },
}

export { ScheduleTable, Schedule, ScheduleWeekSelector }
