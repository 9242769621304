import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Button, InputFieldSet, PageTitle } from "ibe-components"

import {
  ACCOUNT_REGISTER,
  PASSWORD_RECOVERY,
  TERMS_AND_CONDITIONS,
} from "../../../constants/routes"
import accountActions from "../../../redux/account/actions"
import errorActions from "../../../redux/error/actions"
import redirectActions from "../../../redux/redirect/actions"
import { accountSelector, errorSelector } from "../../../redux/selector"
import config from "../../../utils/config"
import { validateLogin } from "../../../utils/validation"
import { reduceErrors } from "../../../utils/common"
import Loading from "../../atoms/Loading"

const LoginForm = ({ history, actions, onBeforeSubmit, showAsDialog = false }) => {
  const [state, setState] = useState({
    data: {
      email: "",
      password: "",
    },
    modalErrors: [],
    formErrors: [],
  })
  const dispatch = useDispatch()
  const loginUser = useCallback(params => dispatch(accountActions.loginUser(params)), [dispatch])
  const setRedirectId = useCallback(url => dispatch(redirectActions.setRedirectId(url)), [dispatch])
  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])

  const error = useSelector(state => errorSelector(state))
  const account = useSelector(state => accountSelector(state))

  const handleInputChanged = field => value => {
    setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [field]: value,
      },
    }))
  }

  const validateForm = errors => {
    if (errors && Object.keys(errors).length > 0) {
      if (typeof errors === "string") {
        setState(prevState => ({ ...prevState, modalErrors: errors }))
      } else {
        setState(prevState => ({
          ...prevState,
          formErrors: errors,
          modalErrors: reduceErrors(errors),
        }))
      }
      return false
    }
    setState(prevState => ({
      ...prevState,
      formErrors: {},
      modalErrors: {},
    }))
    return true
  }

  const onSubmit = e => {
    e.preventDefault()
    const errors = validateLogin(state.data)
    if (validateForm(errors)) {
      if (typeof onBeforeSubmit === "function") {
        onBeforeSubmit()
      }

      if (showAsDialog) {
        const [body] = document.getElementsByTagName("body")
        body.style.overflow = "visible"
      }

      loginUser({ email: state.data.email, password: state.data.password, history })
    }
  }

  const goToForgotPassword = () => {
    setRedirectId(true)
    history.push(PASSWORD_RECOVERY)
  }

  const goToSignUp = () => {
    if (showAsDialog) {
      window.open(ACCOUNT_REGISTER, "_blank", "noopener,noreferrer")
    } else {
      history.push(ACCOUNT_REGISTER)
    }
  }

  if (error && error.isBusy) {
    return <Loading />
  }

  if (error) {
    validateForm(error)
    clearError()
  }

  if (state.modalErrors) {
    actions.show({ type: "is-danger", message: state.modalErrors })
    setState(prevState => ({
      ...prevState,
      modalErrors: undefined,
    }))
  }

  const { data } = state

  return (
    <div className={`SectionWrapper loginPage__wrapper ${showAsDialog ? "login--dialog" : ""}`}>
      <form onSubmit={onSubmit} autoComplete="false">
        <PageTitle title="Login" margin="0" />
        <div className="Input--Fields">
          <InputFieldSet
            inputType="email"
            inputProps={{ id: "email", name: "email" }}
            labelText="YOUR EMAIL"
            margin="20px 0 20px 0"
            fullWidth
            inputValue={data.email}
            onChange={e => {
              handleInputChanged("email")(e.target.value)
            }}
            error={!!state.formErrors.email}
          />
          <InputFieldSet
            inputType="password"
            labelText="YOUR PASSWORD"
            fullWidth
            inputValue={data.password}
            onChange={e => {
              handleInputChanged("password")(e.target.value)
            }}
            error={!!state.formErrors.password}
          />
        </div>
        <div className="Login--Buttons">
          <Button fullWidth type="primary" buttonType="submit">
            {account.isBusy ? "Please wait" : "Login"}
          </Button>
        </div>
        <div className="Login--remember-me-row" onClick={goToForgotPassword}>
          <p>Forgot Password?</p>
        </div>
        <div className="loginPage--legalSection">
          By logging in you agree to the
          <a href={`${config.LANDLINE_URL}${TERMS_AND_CONDITIONS}`}> Terms and Conditions</a>.
        </div>
        <div className="loginPage--action">
          <div className="loginPage--action--question">
            Don&apos;t have an account? Join and earn perks on every ride!
          </div>
          <div className="Login--Buttons">
            <Button fullWidth type="secondary" onClick={goToSignUp}>
              Create Account
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default LoginForm
