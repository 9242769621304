import React from "react"
import "./CorporateTravelPage.scss"
import BasicPage from "../BasicPage"
import FAQBox from "../SchedulePages/FAQ"
import ModuleTitle from "../../atoms/SchedulePages/ModuleTitle"
import Seo from "../../molecules/Seo"
import { IoWifi } from "react-icons/io5"
import { FaTruckPlane } from "react-icons/fa6"
import { RiMoneyDollarCircleFill } from "react-icons/ri"
import { FaSeedling } from "react-icons/fa"
import { MdGroups } from "react-icons/md"
import { GiCheckMark } from "react-icons/gi"

const employeeTransportationText = [
  "Managing employee travel can be a logistical challenge\u2014especially in regions where direct flights are limited, and navigation airport transportation adds stress to the journey. Landline airport shuttle service provides a seamless, cost-effective solution to employee travel, bridging the gap between smaller regional cities and major airport hubs.",
  "Whether your employees are traveling from Fort Collins, CO, Duluth, MN or Fargo, ND, Landline ensures a smooth, stress-free experience, eliminating the hassle of rental cars, parking, and reducing airport congestion.",
]

const landlineBenefits = [
  {
    title: "Hassle-Free, Productive Travel",
    description:
      "Instead of spending time navigating traffic or dealing with car rentals, employees can relax or prepare for their day while Landline handles the journey. Our modern, comfortable shuttles provide a quiet, Wi-Fi-enabled space so your team can stay productive or simply unwind before their flights.",
    Icon: IoWifi,
  },
  {
    title: "Convenient, Direct Connections",
    description:
      "Landline connects employees in Fort Collins, Duluth, and Fargo directly to major airport hubs, including Denver International Airport (DEN) and Minneapolis-Saint Paul International Airport (MSP). With strategically scheduled departures, employees can count on a smooth connection to their flights without the stress of driving or parking",
    Icon: FaTruckPlane,
  },
  {
    title: "Cost Savings for your Business",
    description:
      "Renting cars, reimbursing mileage, and dealing with parking expenses add up quickly. Landline's employee shuttle service offers a more budget-friendly alternative, reducing travel costs while ensuring a premium experience for your team. Additionally, some states offer tax incentives for businesses that provide transportation solutions\u2014potentially adding even more savings.",
    Icon: RiMoneyDollarCircleFill,
  },
  {
    title: "A Greener Way to Travel",
    description:
      "By reducing the number of individual car trips to major airports, Landline helps companies lower their carbon footprint and contribute to more sustainable business practices. Fewer rental cars and personal vehicles on the road mean less congestion, lower emissions, and a cleaner environment.",
    Icon: FaSeedling,
  },
  {
    title: "Enhanced Employee Experience & Retention",
    description:
      "Employees appreciate the convenience of stress-free travel. Providing a dedicated shuttle service to and from major airports shows that your company values their time and well-being. This can be a key factor in attracting and retaining top talent, especially in industries where business travel is frequent.",
    Icon: MdGroups,
  },
]

const landlineServiceBenefits = {
  benefits: [
    "Direct airport connections from regional cities to major hubs",
    "Comfortable, spacious seating with Wi-Fi and power outlets",
    "No rental cars, parking headaches, or mileage reimbursements",
    "Cost-effective and environmentally friendly travel solution",
    "Reliable schedules aligned with major flight departures",
  ],
}

const landlineExperienceText = [
  "Ready to simplify business travel for your team? Partner with Landline to provide reliable, convenient, and cost-effective employee airport transportation.",
  "Landline is more than just a shuttle service\u2014we're an extension of the airline experience, seamslessly connecting regional travelers to major airport hubs. Our premium ground transportation ensures that your employees arrive relaxed, prepared, and on time.",
  "Contact us today to explore corporate travel solutions for your company in Fort Collins, Duluth, or Fargo. Let's make business travel smoother, greener, and more efficient together!",
]

const DescriptionWithCTA = ({ description, buttonText, buttonLink }) => {
  return (
    <div className="corporate-travel-description-with-cta">
      {description.map((text, index) => (
        <p key={index}>{text}</p>
      ))}
      <a href={buttonLink}>{buttonText}</a>
    </div>
  )
}

function IconicDescriptionCard({ title, description, Icon }) {
  return (
    <div className="corporate-travel-iconic-description-card">
      <div className="corporate-travel-card-icon">
        <Icon />
      </div>
      <div className="corporate-travel-card-info">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  )
}

function ServiceBenefitsList({ benefits }) {
  return (
    <div className="corporate-travel-service-benefits-container">
      <div>
        <img
          src="https://content.landline.com/wp-content/uploads/2024/07/46-passenger-MC-inside.jpg"
          alt="bus seats"
        />
      </div>
      <div>
        <ul className="corporate-travel-service-benefits-list">
          {benefits.map((benefit, index) => (
            <li key={index}>
              <GiCheckMark />
              <p>{benefit}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const CorporateTravelPage = () => {
  return (
    <BasicPage>
      <Seo
        title="Employee Airport Shuttle Service | Landline Corporate Travel"
        description="Simplify business travel with Landline’s employee airport shuttle service. Direct, hassle-free connections from Fort Collins, Duluth, and Fargo to major airport hubs. Reduce costs, enhance productivity, and improve employee satisfaction. Book today!"
        canonicalUrl="https://landline.com/corporate-travel"
      />
      <section className="corporate-travel-section">
        <h1 className="corporate-travel-section-title">
          Seamless Employee Airport Transportation with Landline
        </h1>
        <DescriptionWithCTA
          description={employeeTransportationText}
          buttonText="Get a Quote Today!"
          buttonLink="/corporate-travel-quote"
        />
      </section>
      <section className="corporate-travel-section">
        <ModuleTitle title="Why Choose Landline for Employee Travel?" />
        {landlineBenefits.map((benefit, index) => (
          <IconicDescriptionCard key={index} {...benefit} />
        ))}
      </section>
      <section className="corporate-travel-section">
        <ModuleTitle title="Key Benefits of Landline Service" />
        <ServiceBenefitsList {...landlineServiceBenefits} />
      </section>
      <section className="corporate-travel-section">
        <ModuleTitle title="Bring the Landline Experience to Your Business" />
        <DescriptionWithCTA
          description={landlineExperienceText}
          buttonText="Contact Our Team"
          buttonLink="/corporate-travel-quote"
        />
      </section>
      <FAQBox />
    </BasicPage>
  )
}

export default CorporateTravelPage
