import "./style.scss"

import {
  Button,
  CollapsibleButton,
  DropDownDatePicker,
  DropdownMenu,
  InputFieldSet,
  NotificationConsumer,
  PageTitle,
  RadioButton,
  RadioGroup,
} from "ibe-components"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"

import { BOOKING_FLOW_STARTING_INDEX } from "../../../constants/booking-flows"
import { birthDayMaxDate, birthDayMinDate } from "../../../constants/date"
import passengerActions from "../../../redux/passenger/actions"
import {
  accountSelector,
  errorSelector,
  itinerarySelector,
  passengerSelector,
  ticketSelector,
  tripSelector,
} from "../../../redux/selector"
import tripActions from "../../../redux/trip/actions"
import { reduceErrors, scrollToTop } from "../../../utils/common"
import { goToNextSegment, isBlackCar, containsBus } from "../../../utils/tripUtil-util"
import {
  validatePassengerBasic,
  validatePassengerContact,
  validatePassengerDoB,
} from "../../../utils/validation"
import Breadcrumbs from "../../atoms/Breadcrumbs"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"
import MobileBreadcrumbs from "../../atoms/Breadcrumbs/MobileBreadcrumbs"
import LoginDialog from "../LoginPage/LoginDialog"

const Passenger = ({ history }) => {
  const itinerary = useSelector(state => itinerarySelector(state))
  const passenger = useSelector(state => passengerSelector(state))
  const account = useSelector(state => accountSelector(state))
  const error = useSelector(state => errorSelector(state))
  const ticket = useSelector(state => ticketSelector(state))
  const trip = useSelector(state => tripSelector(state))

  const dispatch = useDispatch()
  const setCurrentPassenger = useCallback(
    index => dispatch(passengerActions.setCurrentPassenger(index)),
    [dispatch],
  )
  const setLeadPassenger = useCallback(
    leadPassenger => dispatch(passengerActions.setLeadPassenger(leadPassenger)),
    [dispatch],
  )
  const setPassengerProperty = useCallback(
    (field, value) => dispatch(passengerActions.setPassengerProperty({ field, value })),
    [dispatch],
  )

  const [state, setState] = useState({
    formErrors: {},
    modalErrors: {},
    showTripSummaryModal: false,
  })
  const topElementRef = useRef(null)

  useEffect(() => {
    if (!passenger.index && account.isAuthenticated) {
      setLeadPassenger(account.profile)
    } else {
      setCurrentPassenger(passenger.index > 0 ? passenger.index : 0)
    }
  }, [account.isAuthenticated, account.profile, passenger.index])

  useEffect(() => {
    if (trip.bookingFlowIndex !== BOOKING_FLOW_STARTING_INDEX) {
      dispatch(tripActions.setBookingFlowIndex(BOOKING_FLOW_STARTING_INDEX))
    }
  }, [])

  const { passengers } = itinerary.payload || {}

  const onInputChanged = (field, value) => setPassengerProperty(field, value)

  const onSaveClicked = async e => {
    if (e) {
      e.preventDefault()
    }
    const params = passenger[passenger.index]

    let errors = validatePassengerBasic({ ...params })
    if (passenger.index < 1) {
      errors = { ...errors, ...validatePassengerContact({ ...params }) }
    }

    errors = {
      ...errors,
      ...validatePassengerDoB(
        params.date_of_birth,
        passengers[passenger.index].passenger_type,
        ticket.departureTicket[0].departure_date,
      ),
    }

    if (errors && Object.keys(errors).length > 0) {
      setState(prevState => ({
        ...prevState,
        formErrors: errors,
        modalErrors: reduceErrors(errors),
        showReserveSeat: false,
      }))
    } else if (passenger.index < passengers.length - 1) {
      setState(prevState => ({
        ...prevState,
        formErrors: {},
        modalErrors: {},
      }))
      setCurrentPassenger(+passenger.index + 1)
      scrollToTop(topElementRef)
    } else {
      goToNextSegment(history)
    }
  }
  const showSpecialAssistance = () => {
    if (isBlackCar(ticket.departureTicket) || isBlackCar(ticket.returnTicket || [])) {
      return false
    }
    return true
  }

  const showSpecialNotes = () => {
    if (ticket.departureTicket) {
      if (containsBus(ticket.departureTicket)) {
        return false
      }
    }
    if (ticket.returnTicket) {
      if (containsBus(ticket.returnTicket)) {
        return false
      }
    }

    return true
  }
  return (
    <NotificationConsumer>
      {({ actions }) => {
        return (
          <PageLayout
            background="#fff"
            contentStyle={{
              alignContent: "flex-start",
            }}
            render={() => {
              if (itinerary.isBusy || !itinerary.payload || passenger.isBusy) {
                return <Loading />
              }
              if (error) {
                actions.show({ type: "is-danger", message: error })
              }
              if (state.modalErrors) {
                actions.show({ type: "is-danger", message: state.modalErrors })
                setState(prevState => ({
                  ...prevState,
                  modalErrors: undefined,
                }))
              }

              let currentPassenger = {}
              if (passengers) {
                currentPassenger = passengers[passenger.index]
              }

              return (
                <>
                  <Breadcrumbs history={history} className="passenger-page-breadcrumbs" />
                  <MobileBreadcrumbs
                    history={history}
                    className="passenger-page-mobile-breadcrumbs"
                  />

                  <div className="SectionWrapper passenger-page-container" ref={topElementRef}>
                    {currentPassenger && itinerary.payload && (
                      <div className="passenger-page-form-container">
                        {!account?.isAuthenticated && (
                          <LoginDialog history={history} actions={actions} />
                        )}
                        <PageTitle
                          title={`${currentPassenger.passenger_type} #${passenger.index + 1}${
                            passenger.index === 0 ? ": Lead Passenger" : ""
                          }`}
                          margin="0 0 0 0"
                        />
                        <span className="use-legal-name">
                          For adult passengers, please enter names as they appear on ID
                        </span>
                        <div>
                          <ErrorBoundary>
                            <PassengerForm
                              type={currentPassenger.passenger_type}
                              titles={itinerary.payload.titles}
                              genders={itinerary.payload.genders}
                              onInputChanged={onInputChanged}
                              specialRequirements={itinerary.payload.special_requirements}
                              passenger={passenger[passenger.index] || {}}
                              isLead={passenger.index === 0}
                              passengerId={passenger.index}
                              formErrors={state.formErrors}
                              showSpecialAssistance={showSpecialAssistance}
                              showSpecialNotes={showSpecialNotes}
                              onPrevClicked={() => {
                                setCurrentPassenger(passenger.index - 1)
                                scrollToTop(topElementRef)
                              }}
                              passengers={passengers}
                              onSaveClicked={onSaveClicked}
                            />
                          </ErrorBoundary>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )
            }}
          />
        )
      }}
    </NotificationConsumer>
  )
}

export default withRouter(Passenger)

Passenger.defaultProps = {
  history: {},
}

Passenger.propTypes = {
  history: PropTypes.instanceOf(Object),
}

const AIRLINES = [
  [1, "American"],
  [2, "Sun Country"],
  [3, "United"],
]

const PassengerForm = ({
  titles,
  specialRequirements,
  showSpecialAssistance,
  showSpecialNotes,
  passenger,
  formErrors,
  onInputChanged,
  onSaveClicked,
  isLead,
  genders,
  type,
}) => {
  const { departureTicket } = useSelector(state => ticketSelector(state))

  return (
    <form onSubmit={onSaveClicked}>
      <input type="hidden" autoComplete={false} />
      <div className="title">
        <DropdownMenu
          tabIndex={0}
          labelText="TITLE"
          mandatory
          error={formErrors.title}
          value={passenger.title}
          content={titles}
          onSelect={value => {
            onInputChanged("title", value)
          }}
        />
      </div>
      <div className="first-name">
        <InputFieldSet
          tabIndex={1}
          labelText="FIRST NAME"
          fullWidth
          mandatory
          inputValue={passenger.first_name}
          onChange={e => onInputChanged("first_name", e.target.value)}
          error={formErrors.first_name}
        />
      </div>
      <div className="last-name">
        <InputFieldSet
          tabIndex={2}
          fullWidth
          labelText="LAST NAME"
          mandatory
          inputValue={passenger.last_name}
          onChange={e => onInputChanged("last_name", e.target.value)}
          error={formErrors.last_name}
        />
      </div>
      <div className="inline-fields">
        <div className="date-of-birth">
          <DropDownDatePicker
            tabIndex={3}
            labelText="DATE OF BIRTH"
            mandatory
            maxDate={birthDayMaxDate(type, departureTicket[0].departure_date)}
            minDate={birthDayMinDate(type, departureTicket[0].departure_date)}
            value={passenger.date_of_birth}
            onDateChange={date => onInputChanged("date_of_birth", date)}
            onYearChange={date => {
              const maxDate = birthDayMaxDate(type, departureTicket[0].departure_date)
              const minDate = birthDayMinDate(type, departureTicket[0].departure_date)

              if (moment(date).isAfter(moment(maxDate))) {
                date.setDate(1)
                date.setMonth(maxDate.getMonth())
                date.setDate(maxDate.getDate())
              }

              if (minDate && moment(date).isSameOrBefore(moment(minDate))) {
                date.setDate(1)
                date.setMonth(minDate.getMonth())
                date.setDate(minDate.getDate())
              }

              onInputChanged("date_of_birth", date)
            }}
            error={formErrors.date_of_birth}
          />
          <span
            className="explainer"
            title="Your date of birth is used at check-in to verify your ID for travel."
          >
            Why do we ask for this?
          </span>
        </div>
        <div className="gender">
          <DropdownMenu
            tabIndex={4}
            labelText="GENDER"
            mandatory
            error={formErrors.gender}
            value={(passenger.gender || [])[1]}
            content={genders}
            onSelect={value => {
              onInputChanged(
                "gender",
                genders.find(gender => gender[0] === value),
              )
            }}
            margin="0 0 10px 0"
          />
        </div>
      </div>
      <h3>Contact Info</h3>
      <div className="email">
        <InputFieldSet
          tabIndex={5}
          fullWidth
          labelText="EMAIL"
          mandatory={isLead}
          inputValue={passenger.email}
          onChange={e => onInputChanged("email", e.target.value.trim())}
          error={formErrors.email}
          margin="10px 0 20px 0"
          inputType="email"
        />
      </div>
      <div className="phone" id="passenger-form-phone-number">
        <InputFieldSet
          tabIndex={6}
          fullWidth
          labelText="MOBILE"
          maskType="phone"
          mandatory={isLead}
          inputValue={passenger.phone_number}
          onChange={e => onInputChanged("phone_number", e.target.value)}
          error={formErrors.phone_number}
        />
        <span className="mobile-usage-label">
          Used only to contact you in case of delays or itinerary changes
        </span>
      </div>

      {showSpecialAssistance() && (
        <div className="special-assistance">
          {showSpecialNotes() && (
            <div className="special-notes">
              <span className="special-notes-title">Special Notes</span>
              <br />
              <span className="special-notes-description">
                If requesting assistance for an on board wheelchair or a service animal, please
                reach out to us at info@landline.com or call +1 888 428 1149.
              </span>
            </div>
          )}
          <div className="assistance-options">
            <CollapsibleButton
              title={<strong style={{ fontSize: 20 }}>Wheelchair Assistance</strong>}
            >
              <RadioGroup
                titleText="Wheelchair Assistance"
                selected={(passenger.special_requirements[0] || {}).fax_code_id || "none"}
                onChange={e => {
                  let selectedReq = {}
                  if (e.target.value !== "none") {
                    selectedReq = specialRequirements.find(
                      item => item.fax_code_id === e.target.value,
                    )
                  }
                  onInputChanged("special_requirements", selectedReq)
                }}
              >
                <RadioButton id="none" margin="0 0 10px 10px">
                  No wheelchair assistance needed
                </RadioButton>
                {specialRequirements.map(requirement => (
                  <RadioButton id={requirement.fax_code_id} margin="10px 0 10px 10px">
                    {requirement.fax_description}
                  </RadioButton>
                ))}
              </RadioGroup>
            </CollapsibleButton>
          </div>
        </div>
      )}

      {/* <h3 className="optional-fields">Optional Flight Info</h3>
      <div className="inline-fields">
        <div className="flight-number">
          <InputFieldSet
            tabIndex={5}
            fullWidth
            labelText="FLIGHT-NUMBER"
            mandatory={false}
            inputValue={passenger.flightNumber}
            onChange={e => onInputChanged("flightNumber", e.target.value)}
            error={formErrors.flightNumber}
            margin="10px 0 20px 0"
          />
        </div>
        <div className="airline">
          <DropdownMenu
            tabIndex={4}
            labelText="AIRLINE"
            mandatory={false}
            error={formErrors.airline}
            value={(passenger.airline || [])[1]}
            content={AIRLINES}
            onSelect={value => {
              onInputChanged(
                "airline",
                AIRLINES.find(airline => airline[0] === value),
              )
            }}
            margin="0 0 10px 0"
          />
        </div> 
          </div> */}
      <div className="passenger-buttons">
        <Button fullWidth type="primary" buttonType="submit">
          Continue
        </Button>
      </div>
    </form>
  )
}

PassengerForm.propTypes = {
  showSpecialAssistance: PropTypes.func.isRequired,
  titles: PropTypes.instanceOf(Array),
  specialRequirements: PropTypes.instanceOf(Array),
  passenger: PropTypes.instanceOf(Object).isRequired,
  formErrors: PropTypes.instanceOf(Object),
  onInputChanged: PropTypes.func,
  onSaveClicked: PropTypes.func,
  isLead: PropTypes.bool,
  genders: PropTypes.instanceOf(Array).isRequired,
  type: PropTypes.string,
}

PassengerForm.defaultProps = {
  titles: [],
  specialRequirements: [],
  formErrors: {},
  onInputChanged: () => {},
  onSaveClicked: () => {},
  isLead: false,
  type: "Adult",
}
