import React, { useEffect } from "react"
import ScheduleHeader from "../SchedulePages/Header"
import { NotificationConsumer } from "ibe-components"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"

import { ChartersQuoteMeta } from "./metadata"
import "./Charters.scss"

const ChartersQuotePage = () => {

  useEffect(() => {

    if (typeof document !== "undefined") {
      ; (function (document) {
        const script = document.createElement('script');

        script.src = "https://landlineco56170.activehosted.com/f/embed.php?id=6";
        script.async = true;

        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script)
        }
      })(document)
    }

  }, []);

  return (
    <NotificationConsumer>
      {({ actions }) => {
        return (
          <ErrorBoundary>
            <TabNavbarLayout
              pageLayoutBackground="#FFF"
              render={() => {

                return (
                  <>
                    <div className="schedule-page">
                      <ScheduleHeader metadata={ChartersQuoteMeta.header} />
                      <div className="_form_6"></div>
                    </div>
                  </>
                )
              }}
            />
          </ErrorBoundary>
        )
      }}
    </NotificationConsumer>
  )
}

export default ChartersQuotePage
