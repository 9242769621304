import "./ReferalPage.scss"
import "../PenguinPerksPage/PenguinPerks.scss"
import "../SchedulePages/SchedulePage.mobile.scss"

import PropTypes from "prop-types"
import React from "react"
import { useHistory } from "react-router-dom"

import ModuleTitle from "../../atoms/SchedulePages/ModuleTitle"
import SchedulePage from "../SchedulePages"
import Seo from "../../molecules/Seo"
import { HowItWorks } from "../PenguinPerksPage/metadata"
import { HowItWorksPanel } from "../PenguinPerksPage"
import { ReferralLandingMeta, ReferralHowItWorks } from "./metadata"
import { LOGIN } from "../../../constants/routes"


const ReferalLandingPage = () => {

  const history = useHistory()

  const goToLogin = () => {
      history.push(LOGIN)
    }

  return (
    <SchedulePage>
      <Seo title={ReferralLandingMeta.seo.title} description={ReferralLandingMeta.seo.description} />
      <div className="schedule-page referral-page">

        <img onClick={goToLogin} className="show-desktop referral-banner" src="https://content.landline.com/wp-content/uploads/2025/04/REFERAFRIEND.jpg" alt="" srcset="" />
        <img onClick={goToLogin} className="show-mobile referral-banner" src="https://content.landline.com/wp-content/uploads/2025/04/ReferaFriendMobile.jpg" alt="" srcset="" />

        <ModuleTitle title={ReferralHowItWorks.headline} />
        < ReferralHowItWorksPanel metadata={ReferralHowItWorks} />

        <ModuleTitle title="What Do I Get As A Loyalty Member?" />
        <HowItWorksPanel metadata={HowItWorks} />

      </div>
    </SchedulePage>
  )
}

const ReferralHowItWorksPanel = ({ metadata }) => {
  return (
    <div className="penguin-perks-panel">
      {metadata.parties.map((element) => {
        return (
          <div className="referral-expl-element">
            <h4>{element.title}</h4>
            <ol>
              {element.steps.map((step) => {
                return (
                  <li>{step}</li>
                )
              })}
            </ol>
          </div>
        )
      })}
    </div>
  )
}

export default ReferalLandingPage

ReferalLandingPage.propTypes = {
  history: PropTypes.instanceOf(Object),
}
ReferalLandingPage.defaultProps = {
  history: {},
}
