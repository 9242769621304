import * as React from "react"
import { useState } from "react"
import * as styles from "./B2BHeader.module.scss"

const AmericanHeader = ({ phoneNumber }) => {
  const [showPhoneNumber, setShowPhonenumber] = useState(false)

  const togglePhoneNumber = () => {
    setShowPhonenumber(!showPhoneNumber)
  }
  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <div className={styles.logoWrapper}>
          <img src="https://content.landline.com/wp-content/uploads/2024/10/landline-logo-blk-small.jpg" className={styles.AirCanadaLogo} />
        </div>
        <div className={styles.rightContent}>
          <h2 className={styles.callUs} onClick={togglePhoneNumber}>
            Call us
          </h2>
          {showPhoneNumber && (
            <div className={styles.phoneNumber}>
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AmericanHeader
