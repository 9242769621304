import * as React from "react"
import TopMenu from "../../organisms/TopMenu"
import SpecialFooter from "../../organisms/SpecialFooter"
import "./AirCanadaTerms.scss"



const AirCanadaTerms = () => {

    const terms = <><div className="aircanada-terms-container wpWrapper"><div className="wpContent">
        <h2><strong>Terms and Conditions of Purchase and Carriage (Air Canada Landline Operations)</strong></h2>
        <p><strong>Last updated: February 2<sup>nd</sup>, 2024</strong>&nbsp;</p>
        <p>The Landline Company, Inc., (“Landline”) is an Ontario corporation, located at 22 Adelaide Street West, 3400, Toronto, Ontario, Canada, M5H. Landline’s CVOR number: 217-435-356. &nbsp;<br /></p>
        <h4><strong>1. Landline Reservations</strong>&nbsp;</h4>
        <p><strong>Pricing/Refunds/Changes.</strong> All tickets are purchased through Air Canada. Air Canada terms and conditions and fare rules apply for all segments. Please visit <a href="https://www.aircanada.com/us/en/aco/home/legal/conditions-carriage-tariffs.html#/" target="_blank" rel="noreferrer noopener">Air Canada General Terms and Conditions of Carriage</a> for more information. &nbsp;</p>
        <p><strong>Schedule Changes.</strong> Landline reserves the right to amend the published travel times, dates and routes from time to time. Passengers will be notified in advance if their travel itinerary is affected by any change in schedule and will be assisted to reaccommodate if necessary. See Air Canada terms and conditions regarding compensation claim eligibility.&nbsp;&nbsp;</p>
        <p><strong>Passenger Age.</strong> Customers must be 18 years of age or older to make a reservation. All children under 14 years of age must be accompanied by an adult 18 years of age or over. By purchasing travel on Landline, you affirm that you are 18 years of age or older and the passenger traveling is 14 years of age or older (or is traveling with an accompanying adult). Landline reserves the right to validate the age of customers to enforce this policy.&nbsp;</p>
        <p><strong>Booking Confirmation.</strong> It is your responsibility to check that your booking confirmation is accurate.&nbsp;</p>
        <h4><strong>2. Traveling on Landline</strong>&nbsp;</h4>
        <p><strong>Time of Departure.</strong> Landline requires that passengers arrive at least 30 minutes prior to the scheduled departure time of all Landline segments in order to facilitate an on-time departure. In the event that you are not at the point of departure at least 30 minutes prior to the scheduled departure time of your Landline segment, your reservation may be forfeited.&nbsp;</p>
        <p><strong>Check-In and Boarding.</strong> All passengers are required to provide proof of reservation (by providing a boarding pass) and a valid photo identification prior to boarding the Landline vehicle. Passengers must have all necessary documentation required for airline check-in, including, for passengers connecting to an international flight, passport and visa (if the destination requires a visa). If Landline has reason to suspect fraudulent activity as it relates to a customer booking, Landline reserves the right to refuse travel.&nbsp;</p>
        <p><strong>Connecting Travel. </strong>In the event of a missed connection due to a late arrival, please contact Air Canada reservations to be reaccommodated. Landline cannot guarantee a successful connection to your Air Canada flight and is not liable for any damages resulting from a missed connection.&nbsp;&nbsp;</p>
        <p><strong>Passenger Obligations Onboard.</strong> Passengers must obey all instructions issued by the driver or other Air Canada/Landline staff. Landline’s driver is authorized to remove or deny boarding to passengers that are intoxicated or under the influence of drugs, or who compromise the safety or well-being of the other passengers. Smoking (including e-cigarettes) is not permitted onboard. Passengers shall be liable for damages caused on, in, or to the vehicle in a culpable manner.&nbsp;&nbsp;&nbsp;</p>
        <p><strong>Food and Beverages.</strong> Landline permits passengers to bring food and non-alcoholic beverages onboard. Alcoholic beverages cannot be consumed onboard.&nbsp;</p>
        <p><strong>Recording Policy.</strong> Landline vehicles have an onboard camera to monitor the driver and vehicle operations to ensure passenger safety. While unlikely, passengers may occasionally be visible in these recordings. &nbsp;These recordings are solely for the use of Landline and can be viewed anytime. In addition, these recordings can be made available to law enforcement at their request. Your acceptance of these Terms and Conditions and/or boarding of a Landline segment constitutes the acceptance of this recording policy and implies consent to any such recordings and their use.&nbsp;</p>
        <h4><strong>3. Acceptance of Baggage</strong>&nbsp;&nbsp;</h4>
        <p>Please see the Air Canada baggage policy here for more information on baggage allotment and fees.&nbsp; Luggage must not have exterior dimensions in excess of 177 centimeters. Personal items must be able to comfortably fit in the overhead bins, or safely in your lap. Depending on the vehicle type and size, carry-on baggage must be able to comfortably fit in the overhead bins, or it may be stored in the baggage compartment below the vehicle. Landline personnel have the exclusive right to determine the suitability of personal and carry-on baggage.&nbsp;&nbsp;</p>
        <p>In addition, Landline recommends that customers ensure their luggage is tagged and identified with both the customer’s full legal name and contact information. Strollers must be stowed in the luggage compartment and have the ability to be folded.&nbsp;</p>
        <p>Passengers traveling with sports or musical equipment, or other over-sized baggage must notify Air Canada reservations in advance to make sure it can be accommodated.&nbsp;</p>
        <p>While specific restrictions apply to some items, other items may not be transported at all. Please refer to Air Canada’s Restricted and Prohibited Items page for more information on what can and cannot be transported in checked and carry on luggage. Customers may not transport any luggage that could be inherently dangerous or hazardous.&nbsp;&nbsp;This also includes any item that is prohibited by law.&nbsp;</p>
        <h4><strong>4. Passengers with Disabilities or Limited Mobility</strong>&nbsp;</h4>
        <p>Landline vehicles are fully compliant with all applicable federal and provincial accessibility requirements. Passengers requiring special assistance are urged to contact Air Canada for any necessary arrangements. Please view the Air Canada Accessibility page <a href="https://www.aircanada.com/us/en/aco/home/plan/accessibility.html#/home:" target="_blank" rel="noreferrer noopener">here</a> for additional information&nbsp;</p>
        <p><strong>Traveling in a Wheelchair or Mobility Scooter. </strong>Any traveler who intends to travel in a wheeled mobility device is encouraged to book as far in advance as possible. Landline may not be able to accommodate all customers traveling in wheeled mobility devices in one single vehicle. In that case, you will be rebooked on the next available Landline departure or Air Canada will advise on alternate routes. &nbsp;</p>
        <ul>
            <li>When you book your trip, please contact the <a href="https://www.aircanada.com/us/en/aco/home/plan/accessibility.html#questions-tab" target="_blank" rel="noreferrer noopener"><strong>Air Canada Medical Assistance Desk</strong></a> at least 48 hours before your departure, to provide the height, width, weight and battery type of your mobility aid, so we can ensure it fits on both the Landline motorcoach and through the cargo door of the aircraft you are flying on. Air Canada will, however, make a reasonable effort to accommodate requests made within 48 hours.&nbsp;</li>
            <li>Note: In the event that your mobility aid will not fit through the cargo door of the aircraft you are flying on or on the Landline vehicle,&nbsp; Air Canada will advise you of the available options to travel on an alternate flight/date, or different routing that can accommodate your mobility aid. If you accept this alternative itinerary, you will be charged the lower fare of the two trips.&nbsp;</li>
        </ul>
        <h4><strong>5. Service Animals</strong>&nbsp;</h4>
        <p>A Service Animal is any animal that is individually trained or able to provide assistance to a qualified person with a disability. Please see Air Canada’s policy on service animals <a href="https://www.aircanada.com/us/en/aco/home/plan/accessibility.html#dogs-tab" target="_blank" rel="noreferrer noopener">here</a> for additional information on documentation and carriage requirements. All appropriate documentation must be sent to Air Canada at least 48 hours prior to departure.&nbsp;&nbsp;</p>
        <p>Landline will accept a Service Animal for transportation without charge when the Service Animal accompanies the passenger with a disability in the vehicle cabin; however, the Service Animal will not be permitted to occupy a seat and must accompany the passenger in the passenger’s lap or in the passenger’s foot space (unless this location and placement would be inconsistent with any applicable regulations or encroach into another passenger’s space).&nbsp;&nbsp;</p>
        <p>Service Animals must be harnessed, leashed or otherwise tethered at all times by the passenger or Service Animal handler while at the waiting area and onboard the vehicle.&nbsp;</p>
        <p>Landline reserves the right to deny transport to a Service Animal if the passenger with a disability fails to provide completed current forms as required by Air Canada&nbsp;</p>
        <p>Landline will accept without charge a properly harnessed animal trained in explosive detection, drug detection or search and rescue when it is accompanied by its handler. Such animal will be permitted to accompany its handler in the cabin but will not be permitted to occupy a seat. The animal and its handler must be on official duty status and such status must be documented in writing to the satisfaction of Landline.&nbsp;</p>
        <p>Landline does not accept the following animals on board: snakes or other reptiles; ferrets; rodents; spiders; animals that may be too large or heavy to accommodate in the vehicle cabin; or animals that pose a threat to the health or safety of others.</p>
        <h4><strong>6. Acceptance of Pets</strong>&nbsp;</h4>
        <p><em>The following requirements do not apply to Service Animals. Please refer to the Service Animals section.</em>&nbsp;</p>
        <p>Please see <a href="https://www.aircanada.com/us/en/aco/home/plan/special-assistance/pets.html" target="_blank" rel="noreferrer noopener">Air Canada’s pet policy</a> for more information.&nbsp;&nbsp;</p>
        <ul>
            <li>Advance arrangements are required and availability is limited. You must contact Air Canada Reservations to book pet travel.&nbsp;</li>
            <li>Pets are accepted conditionally and must not pose a hazard to the safety of any passenger or Landline/ Air Canada staff.&nbsp;</li>
            <li>The number of pets per trip may be limited.&nbsp;</li>
            <li>Pet must remain in its carrier at all times while aboard the Landline vehicle.&nbsp;</li>
            <li>Owner is responsible for the pet and may be denied future travel on Landline/Air Canada if the pet is taken out of the carrier while aboard the vehicle or disturbs other passengers.&nbsp;</li>
            <li>Passengers are responsible for complying with applicable federal, provincial, and local regulations or ordinances related to animals.&nbsp;</li>
            <li>Unaccompanied animals of any kind are not accepted.&nbsp;</li>
        </ul>
        <h4><strong>7. Limitation of Liability/Claims</strong>&nbsp;</h4>
        <p><strong>General.</strong>&nbsp; Landline shall not be liable for loss, damage or delay caused by:&nbsp;</p>
        <ul>
            <li>The act or default by the passenger;&nbsp;</li>
            <li>The nature of the property or defect therein;&nbsp;</li>
            <li>An act of God, public enemies, authority of the law, quarantine, perils of navigation, riots, strikes, or the hazards or dangers incidents to a state of war;&nbsp;</li>
            <li>Accidents, breakdowns, bad conditions of roads or other causes beyond Landline’s control; or&nbsp;&nbsp;</li>
            <li>Acts that occur before the passenger boards the Landline vehicle or after the passenger disembarks.&nbsp;</li>
        </ul>
        <p>Landline will not be responsible for any incidental or consequential damages caused by any of the above.&nbsp;</p>
        <p>Landline assumes no liability for the loss of or damage to baggage caused by Landline in excess of 1,288 Special Drawing Rights per passenger, and then only to the extent of the actual loss or damage sustained caused by Landline, computed on the basis of the actual value of the baggage. Except as provided below, Landline will assume no liability for damage, breakage, deterioration, delay, and/or loss of any article prohibited to be transported on its vehicles. Such prohibited articles include any hazardous materials as that term is defined by the Canadian Air Transport Security Authority or any weapons of any kind.&nbsp;</p>
        <p>Any claim against Landline for loss, damage or delay must be filed in accordance with Air Canada’s baggage policies and contract of carriage.&nbsp;</p>
        <p>If a passenger’s journey is delayed or canceled a passenger must request accommodation through Air Canada’s reservations.&nbsp;&nbsp;</p>
        <p><strong>Disease-related Harm or Injury.</strong> By purchasing this ticket the passenger acknowledges the risk associated with vehicle transportation, including potential transmission and/or infection by COVID-19 or other diseases. Passengers are responsible for taking the necessary precautions and safety measures as they deem appropriate. To the fullest extent permitted by applicable law, liability for health in case of injury or harm from a transmission and/or infection of COVID-19 or other communicable diseases shall be excluded, except in cases of intentional or negligent injury. As such, the passenger waives the right to sue Landline or the airline operator or partner operator for any disease-related harm or injuries arising from the purchased travel.&nbsp;</p>
        <h4><strong>8. Miscellaneous</strong>&nbsp;</h4>
        <p><strong>General.</strong> Failure or delay by us to enforce an obligation or exercise a right under these terms and conditions does not constitute a waiver of that obligation or right.&nbsp;</p>
        <p>If any provision or term of these terms and conditions shall become or be declared illegal, invalid, void or unenforceable for any reason whatsoever, such term or provision shall be divisible and severed from the other terms and conditions and shall be deemed to be deleted from them. Severance of such terms shall not affect the validity or enforceability of the remaining terms.&nbsp;</p>
        <p>We may alter these terms and conditions from time to time and post the new version on our website, following which all use of our website will be governed by that version. It is your responsibility to check the terms and conditions on the website regularly and prior to making a reservation.&nbsp;</p>
        <p>Unless specifically stated otherwise herein or required by law, Landline shall not be liable for any consequential, compensatory, indirect, incidental or punitive damages arising out of or in connection with the performance of its obligations under these terms and conditions.&nbsp;</p>
        <p><strong>No Discrimination.</strong> Reservations and seating aboard Landline vehicles operated in interprovincial, intraprovince, or foreign commerce is without regard to race, color, creed, or national origin.&nbsp;</p>
    </div></div></>

    return (
        <>
        <TopMenu />
        {terms}
        <SpecialFooter />
        </>
    )

}

export default AirCanadaTerms