import React from "react"
import SchedulePage from ".."
import ScheduleHeader from "../Header"
import OurService from "../OurService"
import ModuleTitle from "../../../atoms/SchedulePages/ModuleTitle"
import { ScheduleLocation } from "../Locations"
import { Schedule, ScheduleTable } from "../Schedule.jsx"

import { FortCollinsLocation } from "./locations"
import FortCollinsData from "../FortCollins/schedule"
import { DenverLocationService, DenverMeta, DenverService } from "./metadata"
import LocationService from "../Service"
import Seo from "../../../molecules/Seo"

const DenverSchedule = ({ history }) => {
  const goToFortCollins = () => {
    history.push("/fort-collins")
  }

  return (
    <SchedulePage>
      <Seo
        title={DenverMeta.seo.title}
        description={DenverMeta.seo.description}
        canonicalUrl={"https://landline.com/denver"}
      />
      {/* <div className="schedule-search-form">
        <Search history={history} remoteCallSearchComponent={true} />
      </div> */}
      <div className="schedule-page">
        <ScheduleHeader metadata={DenverMeta.header} />

        <ModuleTitle title="Fort Collins / Loveland" />
        <ScheduleLocation data={FortCollinsLocation} onButtonClick={goToFortCollins} />
        <Schedule>
          <ScheduleTable schedule={FortCollinsData.from} />
          <ScheduleTable schedule={FortCollinsData.to} />
        </Schedule>
        <LocationService service={DenverLocationService} />
        <OurService service={DenverService} />
      </div>
    </SchedulePage>
  )
}

export default DenverSchedule
