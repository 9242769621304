import React from "react"

const CSUMeta = {
    header: {
        title: "CSU Airport Shuttle",
        subtitle: "CSU Transit Center to Denver International Airport (DIA)",
        description: "Enjoy frequent departures from CSU's Campus to Denver International Airport (DIA), free WiFi, spacious seats, tray tables, and outlets on our stress-free shuttles.\n \n Please note check-in closes 15 minutes before the time of departure and Landlines depart promptly at your scheduled time.\nPlease arrive early and have your ID ready to ensure an on-time departure. ",
        book: true
    },
    seo: {
        title: "CSU to Denver DIA Airport Shuttle | Landline",
        description: "Quick, affordable service from CSU's campus to Denver International Airport. Enjoy Wi-Fi, outlets, and tray tables on your next trip."
    }
}

const CSUService = <>
    <p>Let Landline's premium airport shuttle service between Colorado State University/Old Town Fort Collins and Denver International Airport (DEN/DIA) be the stress-free travel solution students deserve.</p>
    <p>Say goodbye to begging friends for rides, paying high parking fees at DIA, or coordinating expensive rideshares. Landline's affordable shuttle service fits perfectly with your student budget.</p>
    <p>Study on the go with our free Wi-Fi, charge your devices with in-seat power, and relax in our reclining leather seats. Perfect for cramming during your ride or streaming shows after exams.</p>
    <p>No more lugging heavy bags across campus. Our staff helps with loading and unloading your luggage, making travel home for breaks or weekend getaways seamless.</p>
    <p>Ideal for CSU students heading home for holidays, visiting family, or starting adventures - Landline offers reliable, affordable transportation that fits your student lifestyle.</p>
</>

const DropOffData = {
    title: "Drop Off Location",
    subtitle: "Denver International Airport (DEN/DIA)",
    image: "https://content.landline.com/wp-content/uploads/2024/10/DENPickUpMap.jpeg",
    content: <>
        <p>The Landline pick-up/drop off is located on Level 5 of Terminal West at DEN. Follow the signs for United baggage claim. Landline picks up outside door 504 immediately curbside (not on an island).</p>
        <p>For passengers who booked Landline service through United Airlines, we pick up at Gate B83. Do not proceed to baggage claim, your luggage will be transferred on your behalf and can be collected upon arrival at the Northern Colorado Regional Airport.</p>
    </>
}

export {
    CSUMeta, CSUService, DropOffData
}