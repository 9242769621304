import React from "react"
import PropTypes from "prop-types"
import LoginForm from "./LoginForm"

import { NotificationConsumer } from "ibe-components"

import TabNavbarLayout from "../../layouts/TabNavbarLayout"

import "./LoginPage.scss"

const Login = ({ history }) => (
  <NotificationConsumer>
    {({ actions }) => {
      return <TabNavbarLayout render={() => <LoginForm history={history} actions={actions} />} />
    }}
  </NotificationConsumer>
)

export default Login

Login.propTypes = {
  history: PropTypes.instanceOf(Object),
}

Login.defaultProps = {
  history: {},
}
