import * as React from "react"
import { MdWifi, MdBatteryChargingFull, MdAirlineSeatReclineExtra, MdCheck } from "react-icons/md"
import * as styles from "./MotorcoachTable.module.scss"

function MotorcoachTable() {
  return (
    <div className={styles.motorcoachPage__content__table}>
      <div className={styles.motorcoachPage__table__label}>
        <p>
          <span>LANDLINE</span>
        </p>
        <p>
          <span>Range:</span> 1-4 Hours
        </p>
        <p>
          <span>Capacity:</span> 35-50 Passengers
        </p>
      </div>

      <div className={styles.motorcoachPage__table__row}>
        <div className={styles.motorcoachPage__table__cell}>
          <div className={styles.motorcoachPage__table__cell__inner}>
            <MdWifi className={styles.tableIcon} />
            <p>Free Wi-Fi</p>
          </div>
        </div>
        <div className={styles.motorcoachPage__table__cell}>
          <div className={styles.motorcoachPage__table__cell__inner}>
            <svg
              className={styles.tableIcon}
              width="22"
              height="22"
              viewBox="0 3 22 22"
              fill="none"
            >
              <path
                d="M20 8.10821H11.6384C11.6384 7.95701 11.612 7.81301 11.564 7.67621L15.3032 3.83621C15.5192 3.61301 15.5144 3.25781 15.2936 3.04181C15.0704 2.82581 14.7152 2.83061 14.4992 3.05141L10.7768 6.87461C10.6352 6.82421 10.484 6.79301 10.3256 6.79301C10.1504 6.79301 9.98481 6.82661 9.83361 6.88901C9.83121 6.88661 9.83121 6.88421 9.83121 6.88181L6.05841 1.04741C5.92401 0.838606 5.64561 0.778606 5.43681 0.913006C5.22801 1.04741 5.16801 1.32581 5.30241 1.53461L9.07281 7.37141C9.09921 7.41221 9.13521 7.44581 9.17121 7.47701C9.06801 7.66421 9.01041 7.88021 9.01041 8.10821H2.00001C1.00641 8.10821 0.200012 8.91461 0.200012 9.90821V19.357C0.200012 20.3506 1.00641 21.157 2.00001 21.157H20C20.9936 21.157 21.8 20.3506 21.8 19.357V9.90821C21.8 8.91461 20.9936 8.10821 20 8.10821ZM16.4 18.2338C16.4 18.9802 15.7952 19.585 15.0488 19.585H3.35121C2.60481 19.585 2.00001 18.9802 2.00001 18.2338V11.0338C2.00001 10.2874 2.60481 9.68261 3.35121 9.68261H15.0512C15.7976 9.68261 16.4024 10.2874 16.4024 11.0338V18.2338H16.4ZM19.0928 15.7786C18.5336 15.7786 18.08 15.325 18.08 14.7658C18.08 14.2066 18.5336 13.753 19.0928 13.753C19.652 13.753 20.1056 14.2066 20.1056 14.7658C20.1056 15.325 19.652 15.7786 19.0928 15.7786ZM19.0928 12.4018C18.5336 12.4018 18.08 11.9482 18.08 11.389C18.08 10.8298 18.5336 10.3762 19.0928 10.3762C19.652 10.3762 20.1056 10.8298 20.1056 11.389C20.1056 11.9482 19.652 12.4018 19.0928 12.4018Z"
                fill="#3C3835"
              />
            </svg>
            <p>Onboard entertainment</p>
          </div>
        </div>
        <div className={styles.motorcoachPage__table__cell}>
          <div className={styles.motorcoachPage__table__cell__inner}>
            <MdAirlineSeatReclineExtra className={styles.tableIcon} />
            <p>Solo seating available</p>
          </div>
        </div>
      </div>
      <div className={styles.motorcoachPage__table__row}>
        <div className={styles.motorcoachPage__table__cell}>
          <div className={styles.motorcoachPage__table__cell__inner}>
            <MdBatteryChargingFull className={styles.tableIcon} />
            <p>In-seat power</p>
          </div>
        </div>
        <div className={styles.motorcoachPage__table__cell}>
          <div className={styles.motorcoachPage__table__cell__inner}>
            <MdCheck className={styles.tableIcon} />
            <p>Seat reservations</p>
          </div>
        </div>
        <div className={styles.motorcoachPage__table__cell}>
          <div className={styles.motorcoachPage__table__cell__inner}>
            <svg
              className={styles.tableIcon}
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
            >
              <path
                d="M16.0424 0.826904H5.9576C2.9168 0.826904 0.440002 3.23651 0.440002 6.22931V11.4085V16.1365V18.7261C0.440002 19.1077 0.912802 19.3117 1.2056 19.0645L3.884 16.3405C4.0424 16.2061 4.2656 16.1149 4.4912 16.1149H16.0424C19.0832 16.1149 21.56 13.7053 21.56 10.7101V6.20771C21.56 3.23651 19.0832 0.826904 16.0424 0.826904ZM6.4064 9.99011C5.6408 9.99011 5.0336 9.38291 5.0336 8.61731C5.0336 7.85171 5.6408 7.24451 6.4064 7.24451C7.172 7.24451 7.7792 7.85171 7.7792 8.61731C7.7792 9.38291 7.172 9.99011 6.4064 9.99011ZM11 9.99011C10.2344 9.99011 9.6272 9.38291 9.6272 8.61731C9.6272 7.85171 10.2344 7.24451 11 7.24451C11.7656 7.24451 12.3728 7.85171 12.3728 8.61731C12.3728 9.38291 11.7656 9.99011 11 9.99011ZM15.5936 9.99011C14.828 9.99011 14.2208 9.38291 14.2208 8.61731C14.2208 7.85171 14.828 7.24451 15.5936 7.24451C16.3592 7.24451 16.9664 7.85171 16.9664 8.61731C16.9664 9.38291 16.3592 9.99011 15.5936 9.99011Z"
                fill="#3C3835"
              />
            </svg>
            <p>Customer service</p>
          </div>
        </div>
      </div>
      <div className={styles.motorcoachPage__table__row}>
        <div className={styles.motorcoachPage__table__cell}>
          <div className={styles.motorcoachPage__table__cell__inner}>
            <svg
              width="24"
              height="27"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.1855 20.6739C16.1855 20.6739 16.5935 21.5474 16.7855 21.9445C16.7855 21.9445 16.7855 21.971 16.8095 21.971C17.0015 22.3416 17.2175 22.7122 17.4095 23.1092C17.6255 23.5327 17.4335 23.8769 17.0015 23.8769C14.5775 23.8769 12.1775 23.8769 9.75348 23.8769C9.27348 23.8769 9.12948 23.5592 9.36948 23.1092C9.77748 22.3416 10.5935 20.7269 10.5935 20.7269L9.44148 20.7004C8.50548 20.7004 7.68948 19.9327 7.52148 18.9269C7.30548 17.6298 5.67348 7.49157 5.02548 3.46804C4.92948 2.80627 5.57748 2.35627 6.08148 2.72686C6.46548 3.01804 6.82548 3.30921 6.99348 3.52098C7.35348 3.99745 7.59348 4.55333 7.73748 5.16215C8.09748 6.56509 8.88948 9.31804 9.22548 10.4827C9.32148 10.8269 9.60948 11.0651 9.94548 11.0651C10.6655 11.0651 11.9135 11.0651 12.5135 11.0651C13.2815 11.0651 13.9775 11.5945 14.2415 12.4151L14.4575 13.1033C14.5535 13.421 14.3375 13.7916 14.0255 13.7916H10.3295C10.0175 13.7916 9.80148 14.1092 9.87348 14.4269C9.99348 14.9298 10.1375 15.5651 10.2575 15.9622C10.3775 16.3592 10.7615 16.4916 11.1455 16.4916C12.7775 16.4916 15.2975 16.4916 16.9295 16.4916C18.3215 16.4916 18.8255 17.868 18.9695 19.0327C19.0895 19.9327 18.4415 20.7269 17.6255 20.7269C17.0255 20.6474 16.1855 20.6739 16.1855 20.6739Z"
                fill="#3C3835"
              />
            </svg>
            <p>Reclining leather seats</p>
          </div>
        </div>
        <div className={styles.motorcoachPage__table__cell}>
          <div className={styles.motorcoachPage__table__cell__inner}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0)">
                <path
                  d="M5.5 22V14.5H4V9C4 7.9 4.9 7 6 7H9C10.1 7 11 7.9 11 9V14.5H9.5V22H5.5ZM18 22V16H21L18.46 8.37C18.18 7.55 17.42 7 16.56 7H16.44C15.58 7 14.81 7.55 14.54 8.37L12 16H15V22H18ZM7.5 6C8.61 6 9.5 5.11 9.5 4C9.5 2.89 8.61 2 7.5 2C6.39 2 5.5 2.89 5.5 4C5.5 5.11 6.39 6 7.5 6ZM16.5 6C17.61 6 18.5 5.11 18.5 4C18.5 2.89 17.61 2 16.5 2C15.39 2 14.5 2.89 14.5 4C14.5 5.11 15.39 6 16.5 6Z"
                  fill="#3C3835"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p>Facilities</p>
          </div>
        </div>
        <div>
          <div>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MotorcoachTable
