import * as React from "react"
import B2BHowItWorks from "./B2BHowItWorks"
import B2BWhereToFindUs from "./B2BWhereToFindUs"
import B2BNavigation from "./B2BNavigation"
import QuestionAndAnswer from "../AirCanada/questionAndAnswer"
import unitedBus from "../../../media/unitedBusHero.png"
import luggageIcon from "../../../media/luggageIcon.svg"
import ticketsIcon from "../../../media/ticketIcon.svg"
import parkingIcon from "../../../media/parkingIcon.svg"
import monetizationIcon from "../../../media/monetizationIcon.svg"
import * as styles from "./BookFNL.module.scss"
import B2BLayout from "./Layout"

const BookFNLPage = () => {
  const faqData = [
    {
      q: "1. How much does a bus segment cost?",
      a: <p>The bus segment starts at $40 one way. It is included in the overall price of your ticket between FNL and any city in United's route network.</p>
    },
    {
      q: "2. How far in advance should I arrive before my departure in Fort Collins/Loveland Airport (FNL)?",
      a: <p>We recommend that you arrive 15-20 minutes before your departure from Fort Collins. Please allow extra time if you are checking baggage.</p>
    },
    {
      q: "3. Where do I catch the bus in Denver/on my way back to Fort Collins?",
      a: <p>The bus departs from inside <strong>Terminal B</strong> at Denver International Airport. Disembark from your flight and walk over to <strong>Gate B83</strong> to find the United bus to Fort Collins.</p>
    },
    {
      q: "4. I already have a trip booked with United. Can I still take the bus?",
      a: <div><p>Yes, definitely! If you already have a trip booked with United, simply visit “Manage My Trips” on United.com or the United app and change your departing/arriving destination to Fort Collins (FNL). For more help, watch the video below.</p><video controls="" autoplay=""><source src="https://landline-web.s3.us-east-2.amazonaws.com/fnlvid.mp4" type="video/mp4" /><track kind="captions" srclang="en" /></video></div>
    },
    {
      q: "5. Can I use this service for international travel?",
      a: <p>Of course! You can use this service to anywhere United flies - including International destinations.</p>
    },
    {
      q: "6. Does this service work with Star Alliance Partners?",
      a: <p>Yes! This United service is included in the Star Alliance Partnership. You can learn more about that <a href="https://www.united.com/ual/en/us/fly/mileageplus/earn-miles/airline-partners.html">here</a>.</p>
    },
    {
      q: "7. Can I bring my pet and or service animal on the bus?",
      a: <p>The same rules on flights apply to our bus. Please read more about United's animal policies <a href="https://www.united.com/ual/en/us/fly/travel/animals.html">here</a>.</p>
    },
  ]

  const howItWorksItems = [
    {
      id: 1,
      icon: ticketsIcon,
      description: "Book from FNL on",
      link: {
        url: "https://www.united.com/",
        text: "United.com",
      },
    },
    {
      id: 2,
      icon: parkingIcon,
      description: "Park free at Fort Collins/Loveland (FNL)",
    },
    {
      id: 3,
      icon: luggageIcon,
      description: "Check baggage to final destination",
    },
    { id: 4, icon: monetizationIcon, description: "Earn MileagePlus miles" },
  ]

  const handleBookingNavigation = React.useCallback(async () => {
    try {
      await fetch(
        `https://i.ctnsnet.com/int/integration?pixel=69567451&nid=66354764&cont=i`,
        { mode: "no-cors" }
      )
    } catch (e) {
      // Ignoring error, just going to redirect anyway
    } finally {
      window.location = "https://www.united.com/"
    }
  }, [])

  return (
    <>
      <B2BLayout headerPhoneNumber="855-480-1160">
        <div className={styles.hero}>
          <div className={styles.heroContent}>
            <h2 className={styles.heroTitle}>Like any other flight...</h2>
            <p className={styles.heroText}>
              This service offers full check-in at Fort Collins/ Loveland
              Airport (FNL), bag check to your final destination, onboard wi-fi
              and entertainment & guaranteed connection to your next flight in
              Denver.
            </p>
            <p className={`${styles.heroText} ${styles.emphasizedText}`}>
              Book your wingless flight today!
            </p>
            <div className={styles.bookNowBtn}>
              <a
                href="https://www.united.com/"
                className={styles.bookNowLink}
                onClick={handleBookingNavigation}
              >
                Book Now
              </a>
            </div>
          </div>
          <div className={styles.heroImageWrapper}>
            <img src={unitedBus} className={styles.heroImage}></img>
          </div>
        </div>
        <B2BNavigation />
        <B2BHowItWorks items={howItWorksItems} />
        <B2BWhereToFindUs
          airports={[
            "Northern Colorado Regional Airport",
            "4900 Earhart Rd, Loveland Colorado, 80538",
          ]}
          freeParking
        />
        <div className={styles.faqSection} id="B2BFAQs">
          <div className={styles.faqContent}>
            <h3 className={styles.faqTitle}>FAQs</h3>
            {faqData.map(item => {
              return (
                <QuestionAndAnswer
                  item={item}
                  questionBgColor="darkBlue"
                ></QuestionAndAnswer>
              )
            })}
          </div>
        </div>
      </B2BLayout>
    </>
  )
}

export default BookFNLPage
