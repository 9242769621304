import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import TagManager from "react-gtm-module"
import { Route } from "react-router-dom"

import config from "../../../utils/config"

const isEnabled = !!config.GOOGLE_TAG_MANAGER_ID

const init = () => {
  if (isEnabled) {
    const tagManagerArgs = {
      gtmId: config.GOOGLE_TAG_MANAGER_ID,
    }
    TagManager.initialize(tagManagerArgs)
  }
}

const GoogleTag = ({ location }) => {
  const prevousLocationRef = useRef(location) 

  const logPageChange = (pathname, search = "") => {
    if (!isEnabled) {
      return
    }
    const page = pathname + search
    const { windowLocation } = window
    TagManager.dataLayer({
      dataLayer: {
        page: `${windowLocation.origin}${page}`,
      },
      dataLayerName: "Page",
    })
  }

  useEffect(() => {
    logPageChange(location.pathname, location.search)
  }, [])

  useEffect(() => {
    const { pathname, search } = prevousLocationRef.current
    const isDifferentPathname = pathname !== location.pathname
    const isDifferentSearch = search !== location.search
    if (isDifferentPathname || isDifferentSearch) {
      logPageChange(location.pathname, location.search)
    }
  }, [location])

  return null
}

GoogleTag.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
}

const Manager = () => <Route component={GoogleTag} />

export default {
  init,
  Manager,
}
