import denver from "../../../media/pages/locations/denver.webp"
import denverMobile from "../../../media/pages/locations/denver-mobile.webp"
import denverTablet from "../../../media/pages/locations/denver-tablet.webp"
import denverDesktop from "../../../media/pages/locations/denver-desktop.webp"
import duluth from "../../../media/pages/locations/duluth.webp"
import duluthMobile from "../../../media/pages/locations/duluth-mobile.webp"
import duluthTablet from "../../../media/pages/locations/duluth-tablet.webp"
import duluthDesktop from "../../../media/pages/locations/duluth-desktop.webp"
import fortCollins from "../../../media/pages/locations/fort-collins.webp"
import fortCollinsMobile from "../../../media/pages/locations/fort-collins-mobile.webp"
import fortCollinsTablet from "../../../media/pages/locations/fort-collins-tablet.webp"
import fortCollinsDesktop from "../../../media/pages/locations/fort-collins-desktop.webp"
import lacrosse from "../../../media/pages/locations/lacrosse.webp"
import lacrosseMobile from "../../../media/pages/locations/lacrosse-mobile.webp"
import lacrosseTablet from "../../../media/pages/locations/lacrosse-tablet.webp"
import lacrosseDesktop from "../../../media/pages/locations/lacrosse-desktop.webp"
import minneapolis from "../../../media/pages/locations/minneapolis.webp"
import minneapolisMobile from "../../../media/pages/locations/minneapolis-mobile.webp"
import minneapolisTablet from "../../../media/pages/locations/minneapolis-tablet.webp"
import minneapolisDesktop from "../../../media/pages/locations/minneapolis-desktop.webp"

const ColoradoLocations = [
  {
    image: denver,
    title: "Denver International Airport",
    prices: "",
    content:
      "Please present your ID to the driver to check in. Pickup is located on the West Side of the Terminal on Baggage Claim Level 5 directly outside doors 500-504, immediately curbside. For passengers who booked Landline service with United Airlines, we pick up at Gate B83.",
    button: "Denver Service and Schedule",
    url: "/denver",
    srcset: {
      mobile: denverMobile,
      tablet: denverTablet,
      desktop: denverDesktop,
    },
  },
  {
    image: fortCollins,
    title: "Fort Collins/Loveland, CO",
    prices: "",
    content:
      "Landline offers service to Fort Collins/Loveland from four convenient locations: CSU Transit Center (CSU), Harmony Transfer Center (HTC), and the Northern Colorado Regional Airport - FREE Parking (FNL).",
    button: "Fort Collins / Loveland Service and Schedule",
    url: "/fort-collins",
    srcset: {
      mobile: fortCollinsMobile,
      tablet: fortCollinsTablet,
      desktop: fortCollinsDesktop,
    },
  },
]

const MNWLocations = [
  {
    image: minneapolis,
    title: "Minneapolis-St. Paul International Airport",
    prices: "",
    content:
      "Terminal 1 pick up is in on level 1 of the Silver Ramp (follow signs for car rental and buses) of the Minneapolis-St. Paul International Airport. Terminal 2 pick up is located on level 1 of the Purple Parking Ramp building (ground transportation center), located across the street from Sun Country baggage claim.",
    button: "Minneapolis Service and Schedule",
    url: "/msp",
    srcset: {
      mobile: minneapolisMobile,
      tablet: minneapolisTablet,
      desktop: minneapolisDesktop,
    },
  },
  {
    image: duluth,
    title: "Duluth, MN",
    prices: "",
    content:
      "Landline offers service to the Duluth International Airport (DLH) in Duluth, MN. The pick-up and drop off point is in front of the main lobby entrance. For more information about our shuttle service to DLH including parking information, please view our Duluth page.",
    button: "Duluth Service and Schedule",
    url: "/duluth",
    srcset: {
      mobile: duluthMobile,
      tablet: duluthTablet,
      desktop: duluthDesktop,
    },
  },
]

export { ColoradoLocations, MNWLocations }
